import { Program } from 'models';
import { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import { useProgramStore } from 'store';
import { Column, Table, Input, RadioGroup, Button, Radio } from 'views/components/elements';
import { Active, Head, Inactive, FilterForm } from 'views/components/styled';
import { ProgramDialog } from 'views/dialogs';

export default function ProgramList() {
  const { programs, fetchPrograms } = useProgramStore();
  const [editorOpen, setEditorOpen] = useState(false);
  const [filter, setFilter] = useState({ query: '', status: 'All' });
  const [program, setProgram] = useState<Program>(null);

  const columns = [
    { field: 'code', label: 'code', width: 150 },
    { field: 'name', label: 'name' },
    { field: 'duration_name', label: 'duration', width: 150 },
    {
      field: 'is_active',
      label: 'status',
      valueGetter: row =>
        row.is_active ? <Active>Active</Active> : <Inactive>Inactive</Inactive>,
      width: 120,
    },
    {
      field: 'action',
      label: '',
      valueGetter: row => (
        <IconButton onClick={editHandler(row)}>
          <EditIcon />
        </IconButton>
      ),
      width: 80,
    },
  ] as Column<Program>[];

  useEffect(() => {
    if (!programs.length) {
      fetchPrograms();
    }
  }, []);

  const handleSubmit = (values: typeof filter) => {
    setFilter(values);
  };

  const editHandler = (program: Program) => async () => {
    setProgram(program);
    setEditorOpen(true);
  };

  const handleAdd = () => {
    setProgram(null);
    setEditorOpen(true);
  };

  const handleClose = () => {
    setEditorOpen(false);
  };

  const rows = programs.filter(x => {
    return (
      (!filter.query ||
        x.name.toLowerCase().includes(filter.query.toLowerCase())) &&
      (filter.status === 'All' || x.is_active === (filter.status === 'Active'))
    );
  });

  return (
    <>
      <Head>Programs</Head>
      <div className='row head'>
        <FilterForm initialValues={filter} onSubmit={handleSubmit}>
          <Input
            name='query'
            placeholder='Search Programs'
            startAdornment={<SearchIcon />}
          />
          <RadioGroup name='status' row>
            <Radio value='Active' label='Active' />
            <Radio value='Inactive' label='Inactive' />
            <Radio value='All' label='All' />
          </RadioGroup>
          <Button type='submit' variant='text'>
            Search
          </Button>
        </FilterForm>
        <Button startIcon={<AddCircleOutlineIcon />} onClick={handleAdd}>
          Add Program
        </Button>
      </div>
      <Table rows={rows} columns={columns} />
      <ProgramDialog open={editorOpen} onClose={handleClose} program={program} />
    </>
  );
}
