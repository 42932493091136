import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import { Student, StudentCreateOptions, StudentUpdateOptions } from 'models';
import {
  Button,
  Form,
  Input,
  CustomAttributes,
  buildFormMetadata,
  Select
} from 'views/components/elements';
import { FlexRow, FormField } from 'views/components/styled';
import {
  useAccountStore,
  useAttributeStore,
  useGradeLevelStore,
  useInstituteStore,
  useProgramStore,
  useTermStore,
  useStudentStore
} from 'store';
import { Head, Content, Actions, ToggleButton } from './Elements';
import { useEffect, useState } from 'react';
import { Alert } from '@mui/material';
import { BEHAVIOR_ACTIONS, validateMetadata } from 'views/shared/utils/validation-utils';

type StudentProps = {
  student?: Student;
  open: boolean;
  onClose: () => void;
};

interface StudentForm {
  first_name: string
  last_name: string
  roll_number: string
  program_id?: string
  grade_level_id?: number
  term_id?: number
  metadata: any[]
}

export default function StudentEditorDialog(props: StudentProps) {
  const { createStudent, updateStudent, enableStudent, disableStudent } = useStudentStore();
  const { institute } = useInstituteStore();
  const { gradeLevels, fetchGradeLevels } = useGradeLevelStore();
  const { programs, fetchPrograms } = useProgramStore();
  const { terms, fetchTerms } = useTermStore();
  const attributes = useAttributeStore(state =>
    state.attributes.filter(a => a.is_active && a.usages.some(u => u.category === 'Student'))
  );
  const [errors, setErrors] = useState([]);
  const { hasAccess } = useAccountStore();
  const hasStudentEnableAccess = hasAccess(BEHAVIOR_ACTIONS.StudentEnable);
  const hasStudentDisableAccess = hasAccess(BEHAVIOR_ACTIONS.StudentDisable);

  const isSchool = institute?.institute_type === 'School';

  useEffect(() => {
    if (isSchool) {
      if (!gradeLevels.length) {
        fetchGradeLevels();
      }
    } else {
      if (!programs.length) {
        fetchPrograms();
      }
    }

    if (!terms.length) {
      fetchTerms();
    }
  }, [isSchool]);

  const isEdit = Boolean(props.student?.student_id);
  const student = {
    first_name: props.student?.first_name || '',
    last_name: props.student?.last_name || '',
    roll_number: props.student?.roll_number || '',
    program_id: props.student?.program_id || '',
    grade_level_id: props.student?.grade_level_id || '',
    term_id: props.student?.term_id || '',
    metadata: buildFormMetadata(props.student?.metadata, attributes),
  } as StudentForm;

  const handleSubmit = async (values: StudentForm) => {
    setErrors([]);
    const errors = [];

    if (!values.first_name) {
      errors.push('First Name is required.');
    }

    if (!values.last_name) {
      errors.push('Last Name is required.');
    }

    if (!values.roll_number) {
      errors.push('Roll Number is required.');
    }

    const result = validateMetadata(values.metadata, attributes);
    errors.push(...result.errors);

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    const options = {
      first_name: values.first_name,
      last_name: values.last_name,
      term_id: +values.term_id,
      metadata: result.validatedMetadata,
    } as StudentUpdateOptions;

    if (isSchool) {
      options.grade_level_id = +values.grade_level_id;
    } else {
      options.program_id = values.program_id;
    }

    if (isEdit) {
      await updateStudent(options, props.student?.student_id);
    } else {
      const createOptions = {
        roll_number: values.roll_number,
        ...options
      } as StudentCreateOptions;
      await createStudent(createOptions);
    }

    props.onClose();
  };

  const handleCancel = () => {
    setErrors([]);
    props.onClose();
  };

  const handleToggle = async () => {
    if (props.student?.is_active) {
      await disableStudent(props?.student?.student_id);
    } else {
      await enableStudent(props?.student?.student_id);
    }
    props.onClose();
  };

  const getTerms = (values: StudentForm) => {
    if (isSchool) {
      const gradeLevel = gradeLevels.find(gl => gl.grade_level_id == values.grade_level_id);

      return terms.filter(t => gradeLevel?.terms?.includes(t.term_id));
    } else {
      const program = programs.find(p => p.program_id == values.program_id);

      return terms.filter(t => program?.terms?.includes(t.term_id));
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth='sm' fullWidth>
      <Head>
        <DialogTitle>Add Student</DialogTitle>
        <IconButton onClick={handleCancel}>
          <CloseIcon />
        </IconButton>
      </Head>
      <Form
        initialValues={student}
        onSubmit={handleSubmit}
        render={({ values }) => (
          <>
            <Content>
              <FormField>
                <label>
                  Roll Number*
                </label>
                <Input name='roll_number' placeholder='Roll Number' disabled={isEdit} />
              </FormField>
              <FormField>
                <label>
                  First Name*
                </label>
                <Input name='first_name' placeholder='First Name' />
              </FormField>
              <FormField>
                <label>
                  Last Name*
                </label>
                <Input name='last_name' placeholder='Last Name' />
              </FormField>
              {isSchool && (
                <FormField>
                  <label>Grade Level*</label>
                  <Select name='grade_level_id'>
                    {gradeLevels.filter(gl => gl.is_active).map(gradeLevel => (
                      <MenuItem key={gradeLevel.grade_level_id} value={gradeLevel.grade_level_id}>
                        {gradeLevel.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormField>
              )}
              {!isSchool && (
                <FormField>
                  <label>Program*</label>
                  <Select name='program_id'>
                    {programs.filter(p => p.is_active).map(program => (
                      <MenuItem key={program.program_id} value={program.program_id}>
                        {program.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormField>
              )}
              <FormField>
                <label>Term*</label>
                <Select name='term_id'>
                  {getTerms(values).map(term => (
                    <MenuItem key={term.term_id} value={term.term_id}>
                      {term.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormField>
              <CustomAttributes
                attributes={attributes}
                metadata={values.metadata}
                category='Student'
              />
            </Content>
            {errors.map((error, index) => (
              <Alert severity='error' key={index}>
                {error}
              </Alert>
            ))}
            <Actions style={{ justifyContent: 'end' }}>
              <div>
                <ToggleButton
                  isEdit={isEdit}
                  isActive={props.student?.is_active}
                  onClick={handleToggle}
                  disabled={!(props?.student?.is_active ? hasStudentDisableAccess : hasStudentEnableAccess)}
                />
              </div>
              <FlexRow size='xs'>
                <Button type='submit'>Save Student</Button>
                <Button
                  variant='text'
                  color='secondary'
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </FlexRow>
            </Actions>
          </>
        )}
      />
    </Dialog>
  );
}
