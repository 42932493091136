import { ConcessionListView, ConcessionListParams, Paged, ConcessionCreateOptions, Concession, ConcessionUpdateOptions, ConcessionCancelOptions, ApiResult } from 'models';
import { deleteRequest, getRequest, postRequest, putRequest, sendRequest } from './gateway';
import { EntityResult } from 'models';

function getConcession(concessionId: string) {
  const getPromise = getRequest<Concession>(`/api/v1/concession/${concessionId}`);

  return sendRequest(getPromise);
}

function createConcession(options: ConcessionCreateOptions) {
  const createPromise = postRequest<EntityResult<Concession>>('/api/v1/concession', options);

  return sendRequest(createPromise);
}

function updateConcession(options: ConcessionUpdateOptions, concessionId: string) {
  const updatePromise = putRequest<EntityResult<Concession>>(`/api/v1/concession/${concessionId}`, options);

  return sendRequest(updatePromise);
}

function approveConcession(options: ConcessionUpdateOptions, concessionId: string) {
  const updatePromise = putRequest<EntityResult<Concession>>(`/api/v1/concession/${concessionId}/approve`, options);

  return sendRequest(updatePromise);
}

function deleteConcession(concessionId: string) {
  const deletePromise = deleteRequest(`/api/v1/concession/${concessionId}`);

  return sendRequest(deletePromise);
}

function cancelConcession(options: ConcessionCancelOptions, concessionId: string) {
  const cancelPromise = putRequest<ApiResult>(`/api/v1/concession/${concessionId}/cancel`, options);

  return sendRequest(cancelPromise);
}

function listConcessions(params: ConcessionListParams) {
  params.sortColumn ??= 'created_date_time';
  params.sortDirection ??= 'desc';
  params.limit ??= 100;
  params.page ??= 1;

  const fetchPromise = getRequest<Paged<ConcessionListView>>('/api/v1/concession/list', { params });

  return sendRequest(fetchPromise);
}

export {
  listConcessions,
  createConcession,
  getConcession,
  updateConcession,
  deleteConcession,
  approveConcession,
  cancelConcession
};
