import { Dialog, DialogTitle, IconButton, InputAdornment, styled } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import { ErrorMessage, Field, FieldArray, FieldArrayRenderProps, Formik, Form as FormikForm } from 'formik';
import { LateChargeDialogProps, RateFrequency } from 'models';
import { Button, Input, Radio, RadioGroup, ExpandMore } from 'views/components/elements';
import { FlexColumn, FlexRow } from 'views/components/styled';
import { Actions, Content, FormField, ToggleButton } from './Elements';
import CloseIcon from '@mui/icons-material/Close';
import { LateChargeValidationSchema } from './LateChargeValidationSchema';
import { useAccountStore, useLateChargeStore } from 'store';
import { BEHAVIOR_ACTIONS } from 'views/shared/utils/validation-utils';

const LineCard = styled(Card)({
  overflow: 'unset'
});

const LineContent = styled(CardContent)({
  paddingTop: 0
});

const StyledErrorMessage = styled('div')({
  color: 'red',
  marginBottom: '0.25rem',
});

const Head = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingRight: 24,
});

const StyledItemError = styled('div')({
  marginTop: '8px'
});

const StyledFlexRow = styled(FlexRow)({
  flexDirection: 'column',
  alignItems: 'start'
});

interface ChargeLineForm {
  late_charge_line_id: number
  from_days: number
  to_days?: number
  rate_frequency: RateFrequency
  rate_percent?: number
  rate_amount?: number
  rate_category: number
  expanded: boolean
}

interface LateChargeForm {
  name: string
  min_charge?: number
  max_charge?: number
  grace_days?: number
  items: ChargeLineForm[]
}
let isDisabled = true;

function LateChargeDialog(props: LateChargeDialogProps) {
  const isEdit = Boolean(props.lateCharge?.late_charge_id);
  const { createLateCharge, updateLateCharge, enableLateCharge, disableLateCharge, fetchLateCharges } = useLateChargeStore();
  const { hasAccess } = useAccountStore();
  const hasLateChargeEnableAccess = hasAccess(BEHAVIOR_ACTIONS.LateChargeEnable);
  const hasLateChargeDisableAccess = hasAccess(BEHAVIOR_ACTIONS.LateChargeDisable);

  const lateCharge = {
    name: props.lateCharge?.name || null,
    min_charge: props.lateCharge?.min_charge || null,
    max_charge: props.lateCharge?.max_charge || null,
    grace_days: props.lateCharge?.grace_days || null,
    items: props.lateCharge?.items?.map(item => ({
      late_charge_line_id: item.late_charge_line_id,
      from_days: item.from_days,
      to_days: item.to_days,
      rate_frequency: item.rate_frequency,
      rate_percent: item.rate_percent,
      rate_amount: item.rate_amount,
      expanded: true,
      rate_category: item.rate_amount ? 1 : 2
    })) || [{
      late_charge_line_id: 1,
      from_days: 1,
      to_days: null,
      rate_frequency: 'PerDay',
      rate_category: 1,
      rate_amount: null,
      rate_percent: null,
      expanded: true
    }]
  } as LateChargeForm;

  const handleToggle = async ()=>{
    if(props.lateCharge?.is_active){
      await disableLateCharge(props.lateCharge?.late_charge_id);
    }else{
      await enableLateCharge(props.lateCharge?.late_charge_id);
    }
    props.onClose();
    fetchLateCharges();
  };

  const handleSubmit = async values => {
    if (!values) {
      return;
    }
    if (isEdit) {
      //edit  code
      await updateLateCharge(values, props.lateCharge.late_charge_id);
      props.onClose();

    } else {//create Charge
      await createLateCharge(values);
      props.onClose();
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth='md' fullWidth>
      <Head>
        <DialogTitle style={{ paddingBottom: 0 }}>{isEdit ? 'Edit' : 'Add'} Fine Rule</DialogTitle>
        <IconButton onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </Head>
      <Formik
        initialValues={lateCharge}
        validateOnChange={true}
        validateOnBlur={false}
        validationSchema={LateChargeValidationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          handleChange,
          errors,
          touched,
          handleSubmit,
        }) => {
          return (
            <FormikForm onSubmit={handleSubmit}>
              <Content>
                <FormField>
                  <label>Rule Name</label>
                  <Field
                    name='name'
                    placeholder='Enter rule name'
                    as={Input}
                    error={touched.name && errors.name}
                  />
                  <StyledErrorMessage>{errors.name && touched.name && <ErrorMessage name='name' />}</StyledErrorMessage>
                </FormField>
                <FlexRow justify="start" size='sm'>
                  <StyledFlexRow>
                    <FormField>
                      <label>Charge Range</label>
                      <Field
                        name='min_charge'
                        placeholder='Enter minimum charge'
                        as={Input}
                        error={touched.min_charge && errors.min_charge}
                        startAdornment={<InputAdornment position="start">Min ₹</InputAdornment>}
                      />
                    </FormField>
                    <StyledItemError>
                      <StyledErrorMessage>{errors.min_charge && touched.min_charge && <ErrorMessage name='min_charge' />}</StyledErrorMessage>
                    </StyledItemError>
                  </StyledFlexRow>
                  <StyledFlexRow>
                    <FormField>
                      <label>&nbsp;</label>
                      <Field
                        name='max_charge'
                        placeholder='Enter maximum charge'
                        as={Input}
                        error={touched.max_charge && errors.max_charge}
                        startAdornment={<InputAdornment position="start">Max ₹</InputAdornment>}
                      />
                    </FormField>
                    <StyledItemError>
                      <StyledErrorMessage>{errors.max_charge && touched.max_charge && <ErrorMessage name='max_charge' />}</StyledErrorMessage>
                    </StyledItemError>
                  </StyledFlexRow>
                  <StyledFlexRow>
                    <FormField>
                      <label>Grace Days</label>
                      <Field
                        name='grace_days'
                        placeholder='Enter grace days'
                        as={Input}
                        error={touched.grace_days && errors.grace_days}
                      />
                    </FormField>
                    <StyledItemError>
                      <StyledErrorMessage>{errors.grace_days && touched.grace_days && <ErrorMessage name='grace_days' />}</StyledErrorMessage>
                    </StyledItemError>
                  </StyledFlexRow>
                </FlexRow>
                {/* <FlexRow justify="start" size='sm'>
                            <StyledFlexRow>
                              <StyledItemError>
                                <ItemErrorMessage name={`items[${index}].from_days`} />
                              </StyledItemError>
                            </StyledFlexRow>
                          </FlexRow>
                          <ErrorContainer>
                            {typeof errors.items === 'string' && <StyledErrorMessage>{errors.items}</StyledErrorMessage>}
                          </ErrorContainer> */}
                <FieldArray
                  name="items"
                  render={arrayProps => (
                    <LateChargeLineList values={values} arrayProps={arrayProps} />
                  )}
                />
              </Content>
              <Actions>
                <div>
                  <ToggleButton
                    isEdit={isEdit}
                    isActive={props.lateCharge?.is_active}
                    onClick={handleToggle}  
                    disabled={!(props?.lateCharge?.is_active ? hasLateChargeDisableAccess: hasLateChargeEnableAccess)}
                  />
                </div>
                <FlexRow size='xs'>
                  <Button type='submit'>Save</Button>
                  <Button
                    variant='text'
                    color='secondary'
                    onClick={props.onClose}
                  >
                    Cancel
                  </Button>
                </FlexRow>
              </Actions>
            </FormikForm>
          );
        }}
      </Formik>
    </Dialog>
  );
}

interface LateChargeLineListProps {
  arrayProps: FieldArrayRenderProps
  values: LateChargeForm

}

function LateChargeLineList(props: LateChargeLineListProps) {
  const errors = props.arrayProps.form.errors;

  const addItemDisable = props.values.items[props.values.items.length - 1].to_days;
  if (addItemDisable) {
    isDisabled = false;
  }
  const handleAddItem = () => {
    const lastIndex = props.values.items.length - 1;

    let toDays = props.values.items[lastIndex].to_days;

    // Default 'to_days' to 0 if it's null
    if (toDays === null) {
      toDays = 0;
    }

    // Collapse the last item before adding a new one
    props.arrayProps.form.setFieldValue(`items[${lastIndex}].expanded`, false);

    // Push a new item with the 'expanded' property set to true
    props.arrayProps.push({
      late_charge_line_id: lastIndex + 2,
      from_days: toDays + 1,
      to_days: null,
      rate_frequency: 'PerDay',
      rate_category: 1,
      rate_amount: null,
      rate_percent: null,
      expanded: true
    } as ChargeLineForm);

    isDisabled = true;
  };

  const handleDelete = (chargeLine: ChargeLineForm) => {
    const index = props.values.items.indexOf(chargeLine);

    if (index > -1) {
      // Create a new array excluding the item at the found index
      const updatedItems = [
        ...props.values.items.slice(0, index),
        ...props.values.items.slice(index + 1),
      ];

      // Recalculate the from_days for all items after the deleted one
      for (let i = index; i < updatedItems.length; i++) {
        const previousToDays = i === 0 ? 0 : updatedItems[i - 1].to_days;
        updatedItems[i].from_days = previousToDays + 1;
      }

      // Use Formik's setFieldValue to update the form
      props.arrayProps.form.setFieldValue('items', updatedItems);
    }
  };

  const handleExpandClick = (chargeLine: ChargeLineForm) => {
    const index = props.values.items.indexOf(chargeLine);

    if (index > -1) {
      // Toggle the expanded state for the specific item
      const currentExpanded = props.values.items[index].expanded;

      // Use Formik's setFieldValue to update the specific expanded value for the item
      props.arrayProps.form.setFieldValue(`items[${index}].expanded`, !currentExpanded);
    }
  };

  const disableDelete = Boolean(props.values?.items?.length === 1);

  return (
    <>
      <FlexRow style={{ marginTop: 10 }}>
        <h4>Charge details</h4>
        <Button onClick={handleAddItem} disabled={isDisabled}>Add Item</Button>
      </FlexRow>
      {props.values?.items?.map((chargeLine: ChargeLineForm, index: number) => (
        <LateChargeLine
          chargeLine={chargeLine}
          index={index}
          disableDelete={disableDelete}
          key={index}
          errors={errors}
          onDelete={() => handleDelete(chargeLine)}
          onExpand={() => handleExpandClick(chargeLine)}
        />
      ))}
    </>
  );
}

interface LateChargeLineProps {
  index: number
  chargeLine: ChargeLineForm
  disableDelete: boolean
  errors: any
  onDelete: () => void
  onExpand: () => void
}

function LateChargeLine({ index, chargeLine, disableDelete, onDelete, errors, onExpand }: LateChargeLineProps) {
  const isFixedRate = Boolean(chargeLine.rate_category == 1);
  const handleOnChange = (value) => {
    if (!value) {
      isDisabled = true;
    }
  };
  const getTitle = () => {
    let title = 'Set Fine Rate';

    if (!chargeLine.rate_amount && !chargeLine.rate_percent) {
      return title;
    }

    if (chargeLine.rate_frequency === 'PerDay') {
      if (chargeLine.rate_category == 1) {
        title = 'Daily Amount from ';
      } else {
        title = 'Daily Rate from ';
      }
    } else {
      if (chargeLine.rate_category == 1) {
        title = 'Total Amount for ';
      } else {
        title = 'Total Rate for the ';
      }
    }

    if (chargeLine.from_days && chargeLine.to_days) {
      if (chargeLine.rate_frequency === 'WholePeriod' && chargeLine.rate_category == 2) {
        title += `Period from Day ${chargeLine.from_days} to Day ${chargeLine.to_days}: ${chargeLine.rate_percent}%`;
      } else if (chargeLine.rate_frequency === 'WholePeriod') {
        console.log(chargeLine.rate_amount, '<<<<<<<<<<<<<<<<<<<<<<<');
        title += `Day ${chargeLine.from_days} to Day ${chargeLine.to_days}: ₹${chargeLine.rate_amount}`;
      } else if (chargeLine.rate_category == 1) {
        title += `Day ${chargeLine.from_days} to Day ${chargeLine.to_days}: ₹${chargeLine.rate_amount} per Day`;
      } else {
        title += `Day ${chargeLine.from_days} to Day ${chargeLine.to_days}: ${chargeLine.rate_percent}% per Day`;
      }
    } else {
      if (chargeLine.rate_frequency === 'WholePeriod') {
        if (chargeLine.rate_category == 2) {
          title += `Period Starting Day ${chargeLine.from_days}: ${chargeLine.rate_percent}%`;
        } else {
          title += `Period Starting Day ${chargeLine.from_days}: ₹${chargeLine.rate_percent}`;
        }
      } else {
        if (chargeLine.rate_category == 1) {
          title += `Day ${chargeLine.from_days} Onward: ₹${chargeLine.rate_amount} per Day`;
        } else {
          title += `Day ${chargeLine.from_days} Onward: ${chargeLine.rate_percent}% per Day`;
        }
      }
    }

    return title;
  };
  return (
    <LineCard>
      <CardHeader
        title={getTitle()}
        action={
          <ExpandMore
            expand={chargeLine.expanded[index]}
            aria-expanded={chargeLine.expanded[index]}
            aria-label="show more"
            onClick={onExpand}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
      />
      <Collapse in={chargeLine.expanded} timeout="auto" unmountOnExit>
        <LineContent>
          <FlexColumn size='md' style={{ marginTop: '15px' }}>
            <FlexRow>
              <FormField>
                <label>From Day <b>{chargeLine.from_days}</b></label>
              </FormField>
              <FormField style={{ width: '145px' }}>
                <Field name={`items[${index}].to_days`}>
                  {({ field }) => (
                    <Input
                      {...field}
                      placeholder="Infinity"
                      startAdornment={<InputAdornment position="start">To Day</InputAdornment>}
                      type="number"
                      onChange={(e) => {
                        field.onChange(e);
                        handleOnChange(e.target.value);
                      }}
                    />
                  )}
                </Field>

              </FormField>
            </FlexRow>
            <FlexRow size='xl'>
              <FormField style={{ rowGap: '2px' }}>
                <label>Fine Calculation Cycle</label>
                <RadioGroup name={`items[${index}].rate_frequency`}>
                  <FlexRow size='xs'>
                    <Radio value="PerDay" label="Per Day" />
                    <Radio value="WholePeriod" label="Whole Period" />
                  </FlexRow>
                </RadioGroup>
              </FormField>
              <FormField style={{ rowGap: '2px' }}>
                <label>Fine Type</label>
                <RadioGroup name={`items[${index}].rate_category`}>
                  <FlexRow size='xs'>
                    <Radio value={1} label="Fixed rate" title='A fixed amount charged or applied consistently, regardless of the total amount.' />
                    <Radio value={2} label="Percentage based" title='A charge that is proportional to a total value or amount.' />
                  </FlexRow>
                </RadioGroup>
              </FormField>
            </FlexRow>
            <FlexRow>
              <FormField style={{ width: '145px' }}>
                <label>Fine Rate</label>
                {isFixedRate && (
                  <Field
                    name={`items[${index}].rate_amount`}
                    as={Input}
                    placeholder="Rate"
                    type="number"
                    startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                    error={errors.items && errors.items[index] && errors.items[index].rate_amount}
                  />
                )}
                {!isFixedRate && (
                  <Field
                    name={`items[${index}].rate_percent`}
                    as={Input}
                    placeholder="Rate"
                    type="number"
                    endAdornment={<InputAdornment position="start">%</InputAdornment>}
                    error={errors.items && errors.items[index] && errors.items[index].rate_amount}
                  />

                )}
                <StyledErrorMessage>{errors.items && <ErrorMessage name={`items[${index}].rate_amount`} />}</StyledErrorMessage>
              </FormField>
            </FlexRow>
          </FlexColumn>
        </LineContent>
        <CardActions>
          <IconButton disabled={disableDelete} onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </CardActions>
      </Collapse>
    </LineCard>
  );
}

export default LateChargeDialog;

// const ItemErrorMessage = ({ name }) => (
//   <Field name={name}>
//     {({ form }) => {
//       const error = getIn(form.errors, name);
//       const touch = getIn(form.touched, name);
//       return touch && error ? error : null;
//     }}
//   </Field>
// );
