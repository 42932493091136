import { NavLink, Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import './Settings.scss';
import { useAccountStore, useInstituteStore } from 'store';
import { BEHAVIOR_ACTIONS } from 'views/shared/utils/validation-utils';

const ContentWrapper = styled('div')({
  height: '100%',
  overflowY: 'auto',
  marginRight: '-36px',
  paddingRight: 36,
});

function Settings() {
  const { institute } = useInstituteStore();
  const { hasAccess } = useAccountStore();
  const hasFeeTypeReadAccess = hasAccess(BEHAVIOR_ACTIONS.FeeTypeRead);
  const hasFeeRuleReadAccess = hasAccess(BEHAVIOR_ACTIONS.FeeRuleRead);
  const hasBankReadAccess = hasAccess(BEHAVIOR_ACTIONS.BankAccountRead);
  const hasAttributeReadAccess = hasAccess(BEHAVIOR_ACTIONS.AttributeRead);
  const hasReceiptTemplateReadAccess = hasAccess(BEHAVIOR_ACTIONS.ReceiptTemplateRead);
  const hasSettingReadAccess = hasAccess(BEHAVIOR_ACTIONS.SettingsRead);
  const hasLateChargeReadAccess = hasAccess(BEHAVIOR_ACTIONS.LateChargeRead);

  const isSchool = institute?.institute_type === 'School';

  return (
    <ContentWrapper className='custom-table-wrapper'>
      <div className='nav-header'>
        {hasSettingReadAccess && <NavLink to='institute'>Settings</NavLink>}
        {isSchool && <NavLink to='grade-levels'>Grade Levels</NavLink>}
        {!isSchool && <NavLink to='programs'>Programs</NavLink>}
        {hasAttributeReadAccess && <NavLink to='attributes'>Attributes</NavLink>}
        {hasLateChargeReadAccess && <NavLink to='fine-rules'>Fine Rules</NavLink>}
        {hasReceiptTemplateReadAccess && <NavLink to='templates'>Receipt Templates</NavLink>}
        {hasBankReadAccess && <NavLink to='accounts'>Bank Accounts</NavLink>}
        {hasFeeTypeReadAccess && <NavLink to='fee-types'>Fee Types</NavLink>}
        {hasFeeRuleReadAccess && <NavLink to='fee-rules'>Fee Rules</NavLink>}
      </div>
      <Outlet />
    </ContentWrapper>
  );
}

export default Settings;
