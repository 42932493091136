import { Fragment, useRef, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import SettingsIcon from '@mui/icons-material/Settings';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { FlexColumn, FlexRow } from 'views/components/styled';
import { useInstituteStore, useReceiptBookStore } from 'store';
import { Button, Form, Input } from 'views/components/elements';
import { Action } from 'views/components/styled';
import { ReceiptBookDto, ReceiptDto } from 'models';
import { DailyFeeCollectionReportSetting } from 'views/dialogs';

const formatter = Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
});

const currency = (value: number) => {
  if (!value) return value;

  return formatter.format(value).replace(/₹/, '₹ ');
};

const formatDate = (value: string, format = 'DD/MM/yyyy hh:mm a') => {
  if (!value) return value;

  return moment(value).format(format);
};

interface TypeColumns {
  values: number[];
  type: string;
}

interface ReceiptSummary {
  headers: string[];
  summaries: TypeColumns[];
}

interface TypeSummary {
  type: string;
  amount: number;
}

function aggregateReceipts(receipts: ReceiptDto[]) {
  const validReceipts = receipts.filter(r => r.receipt_status !== 'voided');
  const voidedReceipts = receipts.filter(r => r.receipt_status == 'voided');

  const amount = validReceipts.reduce(
    (acc, receipt) => acc + receipt.amount,
    0
  );

  const receiptTypes = Array.from(
    new Set(validReceipts.map(r => r.receipt_type))
  );

  const templateNames = Array.from(
    new Set(validReceipts.map(r => r.receipt_template))
  );

  const receiptSummary = {
    headers: [...templateNames, 'Total'],
    summaries: [],
  } as ReceiptSummary;

  for (const receiptType of receiptTypes) {
    const typeSummary = {
      type: receiptType,
      values: [],
    } as TypeColumns;

    const typeReceipts = validReceipts.filter(
      r => r.receipt_type === receiptType
    );

    for (const template of templateNames) {
      const templateReceipts = typeReceipts.filter(
        r => r.receipt_template === template
      );
      const amount = templateReceipts.reduce(
        (acc, receipt) => acc + receipt.amount,
        0
      );

      typeSummary.values.push(amount);
    }

    typeSummary.values.push(
      typeReceipts.reduce((acc, receipt) => acc + receipt.amount, 0)
    );

    receiptSummary.summaries.push(typeSummary);
  }

  const typeSummary = {
    type: 'Total',
    values: [],
  } as TypeColumns;

  for (const template of templateNames) {
    const templateReceipts = validReceipts.filter(
      r => r.receipt_template === template
    );
    const amount = templateReceipts.reduce(
      (acc, receipt) => acc + receipt.amount,
      0
    );

    typeSummary.values.push(amount);
  }

  typeSummary.values.push(amount);

  receiptSummary.summaries.push(typeSummary);

  const templateSummary = templateNames.map(template => {
    const templateReceipts = validReceipts.filter(
      r => r.receipt_template === template
    );

    return {
      template,
      receipts: receipts.filter(r => r.receipt_template === template),
      validReceipts: templateReceipts,
    };
  });

  const receiptItems = validReceipts.map(r => r.receipt_items).flat();

  const feeTypes = Array.from(new Set(receiptItems.map(item => item.fee_type)));

  const feeTypeSummaries = feeTypes.map(
    type =>
      ({
        type,
        amount: receiptItems
          .filter(item => item.fee_type === type)
          .reduce((acc, item) => acc + item.amount, 0),
      } as TypeSummary)
  );

  feeTypeSummaries.push({ type: 'Total', amount });

  return { templateSummary, receiptSummary, feeTypeSummaries, voidedReceipts };
}

function OverallSummary({
  receiptSummary,
}: {
  receiptSummary: ReceiptSummary;
}) {
  return (
    <div>
      <h3>Overall Summary</h3>
      <table className='table-print has-footer'>
        <thead>
          <tr>
            <th>Type</th>
            {receiptSummary.headers.map(item => (
              <th key={item} className='currency'>
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {receiptSummary.summaries.map(summary => (
            <tr key={summary.type}>
              <td>{summary.type}</td>
              {summary.values.map((value, index) => (
                <td className='currency' key={index}>
                  {currency(value)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function FeeTypeSummary({
  feeTypeSummaries,
}: {
  feeTypeSummaries: TypeSummary[];
}) {
  return (
    <div>
      <h3>Summary by Fee Heads</h3>
      <table className='table-print has-footer'>
        <thead>
          <tr>
            <th>Fee Heads</th>
            <th className='currency'>Amount</th>
          </tr>
        </thead>
        <tbody>
          {feeTypeSummaries.map(summary => (
            <tr key={summary.type}>
              <td>{summary.type}</td>
              <td className='currency'>{currency(summary.amount)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

type ReceiptsProps = {
  title: string;
  receipts: ReceiptDto[];
};

function Receipts(props: ReceiptsProps) {
  return (
    <div>
      <h3 className='page-break'>{props.title} Receipts</h3>
      <table className='table-print receipts'>
        <thead>
          <tr>
            <th>Receipt No. / Date</th>
            <th>Roll No. / Student</th>
            <th>Type</th>
            <th className='currency'>Amount</th>
          </tr>
        </thead>
        <tbody>
          {props.receipts.map(item => (
            <Fragment key={item.receipt_number}>
              <tr className={item.receipt_status}>
                <td>
                  <div className='column'>
                    <span>{item.receipt_number}</span>
                    <span>{formatDate(item.created_date_time)}</span>
                  </div>
                </td>
                <td>
                  <div className='column'>
                    <span>{item.roll_number}</span>
                    <span>
                      {item.first_name} {item.last_name}
                    </span>
                  </div>
                </td>
                <td>{item.receipt_type}</td>
                <td className='currency main'>{currency(item.amount)}</td>
              </tr>
              <tr className={item.receipt_status}>
                <td colSpan={3}>
                  <div className='table-inner-container'>
                    <table className='table-inner'>
                      <tbody>
                        {item.receipt_items.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <span>{item.fee_type}</span>
                              <span> - term </span>
                              <span>{item.term_number}</span>
                            </td>
                            <td className='currency'>
                              {currency(item.amount)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </td>
                <td></td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function FeeReceiptReport(props: ReceiptBookDto) {
  const { institute } = useInstituteStore();
  const { templateSummary, receiptSummary, feeTypeSummaries, voidedReceipts } =
    aggregateReceipts(props.receipts);

  const voidedDisplay =
    institute?.metadata?.daily_fee_collection_report_voided_display;

  return (
    <>
      <div className='only-print'>
        <FlexColumn align='center'>
          <h1>{institute.name}</h1>
          <FlexRow>
            <h2>Fee Receipt Report</h2>
            <FlexRow size='xs'>
              <span>From</span>
              <strong>
                {formatDate(props.start_date_time, 'DD/MM/yyyy hh:mm a')}
              </strong>
              <span>to</span>
              <strong>
                {formatDate(props.end_date_time, 'DD/MM/yyyy hh:mm a')}
              </strong>
            </FlexRow>
          </FlexRow>
        </FlexColumn>
      </div>
      <OverallSummary receiptSummary={receiptSummary} />
      <FeeTypeSummary feeTypeSummaries={feeTypeSummaries} />
      {templateSummary.map(item => (
        <Receipts
          key={item.template}
          title={item.template}
          receipts={voidedDisplay == 'end' ? item.validReceipts : item.receipts}
        />
      ))}
      {voidedDisplay == 'end' && (
        <Receipts title='Cancelled' receipts={voidedReceipts} />
      )}
    </>
  );
}

function DailyFeeCollection() {
  const [settingOpen, setSettingOpen] = useState(false);
  const { book, fetchBook, reportDate } = useReceiptBookStore();

  const ref = useRef<HTMLDivElement>();
  const filter = {
    report_date: reportDate || '',
  };

  const handleSubmit = (values: typeof filter) => {
    fetchBook(values.report_date);
  };

  const openSettings = () => {
    setSettingOpen(true);
  };

  const closeSettings = () => {
    setSettingOpen(false);
  };

  const hasData = !!book;
  const hasNoData = !book && reportDate;

  return (
    <div ref={ref} style={{ flex: 1 }}>
      <div className='no-print'>
        <h1>Fee Receipt Report</h1>
        <Action>
          <Form
            initialValues={filter}
            onSubmit={handleSubmit}
            validateOnChange={false}
          >
            <Input name='report_date' type='date' />
            <Button type='submit' variant='text'>
              View
            </Button>
          </Form>
          <FlexRow>
            {book && (
              <ReactToPrint
                bodyClass='print-container'
                content={() => ref.current}
                trigger={() => (
                  <IconButton aria-label='delete'>
                    <PrintIcon />
                  </IconButton>
                )}
                documentTitle='Fee Receipt Report'
              />
            )}
            <IconButton onClick={openSettings}>
              <SettingsIcon />
            </IconButton>
          </FlexRow>
        </Action>
      </div>
      {hasData && <FeeReceiptReport {...book} />}
      {hasNoData && (
        <div>
          This could be because there haven&apos;t been any fees collected for{' '}
          {formatDate(reportDate, 'DD/MM/yyyy')}, or there might be a temporary
          issue with data retrieval. Please try again later.
        </div>
      )}
      <DailyFeeCollectionReportSetting
        open={settingOpen}
        onClose={closeSettings}
      />
    </div>
  );
}

export default DailyFeeCollection;
