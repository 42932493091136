import Chip, { ChipProps } from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Field } from 'formik';
import { useRef, useState } from 'react';
import { Button, MenuButton } from 'views/components/elements';

const Input = styled(OutlinedInput)(() => ({
  ['& .MuiInputBase-input']: {
    padding: '9.5px 12px',
    height: 'unset',
  },
  fontSize: '14px',
  lineHeight: 'normal',
}));

const Tokens = styled(Paper)({
  padding: '8px 16px',
  display: 'inline-flex',
  columnGap: 4,
});

const Actions = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 16,
  marginTop: 32,
  alignItems: 'start',
});

const Pattern = /{year}|{full_year}|\[\d\]|\w+/g;

type PatternEditorProps = {
  value?: string;
  onChange?: (value: string) => void;
  name: string;
};

function mapToToken(value: string) {
  if (value === '{year}') {
    return 'Short year';
  }

  if (value === '{full_year}') {
    return 'Full year';
  }

  if (/\[\d\]/.test(value)) {
    const digit = value.match(/\d/g)[0];
    return `${digit} digits`;
  }

  return value;
}

type TokenProps = {
  token: string;
};

function Token({ token, ...props }: ChipProps & TokenProps) {
  return <Chip label={mapToToken(token)} {...props} />;
}

function PatternEditor(props: PatternEditorProps) {
  const matches = (props.value ?? '').match(Pattern);
  const [tokens, setTokens] = useState([...(matches?.length ? matches : [])]);
  const textInput = useRef<HTMLInputElement>(null);

  const handleDelete = (index: number) => () => {
    const newTokens = tokens.slice();
    newTokens.splice(index, 1);
    updateTokens(newTokens);
  };

  const handleAddShortYear = () => {
    updateTokens([...tokens, '{year}']);
  };

  const handleAddFullYear = () => {
    updateTokens([...tokens, '{full_year}']);
  };

  const handleAddDigit = (digit: number) => () => {
    updateTokens([...tokens, `[${digit}]`]);
  };

  const menus = [1, 2, 3, 4, 5, 6, 7, 8, 9].map(x => ({
    label: `${x}`,
    onClick: handleAddDigit(x),
  }));

  const handleAddText = () => {
    const value = textInput.current.value;
    if (value) {
      updateTokens([...tokens, value]);
      textInput.current.value = '';
    }
  };

  const updateTokens = (tokens: string[]) => {
    setTokens(tokens);
    if (props.onChange) {
      props.onChange(tokens.join(''));
    }
  };

  const hasTokens = Boolean(tokens.length);

  return (
    <div>
      <Tokens variant='outlined'>
        {tokens.map((token, index) => (
          <Token key={index} token={token} onDelete={handleDelete(index)} />
        ))}
        {!hasTokens && <Token token='Define your number pattern' />}
      </Tokens>
      <Actions>
        <Button variant='text' onClick={handleAddShortYear}>
          Add short year
        </Button>
        <Button variant='text' onClick={handleAddFullYear}>
          Add full year
        </Button>
        <MenuButton variant='text' menus={menus}>
          Add digits
        </MenuButton>
        <div>
          <Input inputRef={textInput} placeholder='Add Text' />
          <Button variant='text' onClick={handleAddText}>
            Add text
          </Button>
        </div>
      </Actions>
    </div>
  );
}

export default function FormikPatternEditor(props: PatternEditorProps) {
  return (
    <Field name={props.name}>
      {({ field, form }) => (
        <PatternEditor
          {...field}
          onChange={value => form.setFieldValue(field.name, value)}
        />
      )}
    </Field>
  );
}
