import { useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Form, Input, Table } from 'views/components/elements';
import { useStudentFeeStore } from 'store';
import { StudentFeeParams } from 'models';
import { columns } from './Columns';

function FeesListCompleted() {
  const { completed, fetchFees } = useStudentFeeStore();
  const {
    fees: completedList,
    filter: completedFilter,
    hasNextPage: hasNextPageCompleted,
  } = completed;

  useEffect(() => {
    if (!completedList.length) {
      fetchFees('completed', {
        statuses: ['Paid', 'PartlyRefund', 'Refund'],
      } as StudentFeeParams);
    }
  }, []);

  const handleSort = (field: string, order: string) => {
    const filter = Object.assign({}, completedFilter, {
      sortColumn: field,
      sortDirection: order,
      page: 1,
    });

    fetchFees('completed', filter);
  };

  const handleNextPage = () => {
    const filter = Object.assign({}, completedFilter, {
      page: completedFilter.page + 1,
    });

    fetchFees('completed', filter);
  };

  const filter = { query: completedFilter.query || '' };

  const handleSubmit = (values: typeof filter) => {
    const newFilter = Object.assign({}, completedFilter, {
      page: 1,
      query: values.query,
    });

    fetchFees('completed', newFilter);
  };

  return (
    <>
      <div className='row'>
        <Form
          initialValues={filter}
          onSubmit={handleSubmit}
          validateOnChange={false}
        >
          <Input
            name='query'
            placeholder='Search Fees'
            startAdornment={<SearchIcon />}
          />
        </Form>
      </div>
      <Table
        columns={columns}
        rows={completedList}
        stickyHeader
        onSortChange={handleSort}
        sortingField={completedFilter.sortColumn}
        sortingOrder={completedFilter.sortDirection}
        hasMoreRows={hasNextPageCompleted}
        infiniteScroll
        onNextPage={handleNextPage}
      ></Table>
    </>
  );
}

export default FeesListCompleted;
