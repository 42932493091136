import { create } from 'zustand';
import { createBankAccount, deleteBankAccount, disableBankAccount, enableBankAccount, getBankAccount, listBankAccounts, updateBankAccount } from 'gateways/bank-account';
import { BankAccount, BankAccountOptions } from 'models';

type State = {
  bankAccounts: BankAccount[]
  bankAccount?: BankAccount
}

type Action = {
  fetchBankAccounts: () => Promise<void>
  createBankAccount: (options: BankAccountOptions) => Promise<void>
  updateBankAccount: (options: BankAccountOptions, bankAccountId: string) => Promise<void>
  deleteBankAccount: (bankAccountId: string) => Promise<void>
  fetchBankAccount: (bankAccountId: string) => Promise<void>
  enableBankAccount: (bankAccountId: string) => Promise<void>
  disableBankAccount: (bankAccountId: string) => Promise<void>
  resetBankAccount: () => void
}

const useBankAccountStore = create<State & Action>()((set, get) => ({
  bankAccounts: [],
  fetchBankAccounts: async () => {
    const bankAccounts = await listBankAccounts() || [];
    set(() => ({ bankAccounts }));
  },
  createBankAccount: async (options: BankAccountOptions) => {
    const bankAccount = await createBankAccount(options);
    const bankAccounts = get().bankAccounts.slice();
    bankAccounts.push(bankAccount);
    set(() => ({ bankAccount, bankAccounts }));
  },
  updateBankAccount: async (options: BankAccountOptions, bankAccountId: string) => {
    const bankAccount = await updateBankAccount(options, bankAccountId);
    const bankAccounts = get().bankAccounts.slice();
    bankAccounts.splice(bankAccounts.findIndex(x => x.bank_account_id === bankAccountId), 1, bankAccount);
    set(() => ({ bankAccount, bankAccounts }));
  },
  deleteBankAccount: async (bankAccountId: string) => {
    await deleteBankAccount(bankAccountId);
    const bankAccounts = get().bankAccounts.slice();
    bankAccounts.splice(bankAccounts.findIndex(x => x.bank_account_id === bankAccountId), 1);
    set(() => ({ bankAccounts, bankAccount: null }));
  },
  fetchBankAccount: async (bankAccountId: string) => {
    const bankAccount = await getBankAccount(bankAccountId);
    set(() => ({ bankAccount }));
  },
  enableBankAccount: async (bankAccountId: string) => {
    await enableBankAccount(bankAccountId);
  },
  disableBankAccount: async (bankAccountId: string) => {
    await disableBankAccount(bankAccountId);
  },
  resetBankAccount: () => set(() => ({ bankAccount: null }))
}));

export { useBankAccountStore };
