import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PreviewIcon from '@mui/icons-material/Preview';
import { Button, Table } from 'views/components/elements';
import { styled } from '@mui/material/styles';
import { useDropzone, FileRejection } from 'react-dropzone';
import { useCallback, useState } from 'react';
import Stack from '@mui/material/Stack';
import * as PapaParse from 'papaparse';
import { uploadFile } from 'gateways/files';
import { FileDetail } from 'models';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { FlexRow } from 'views/components/styled';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const getColor = props => {
  if (props.isDragActive) {
    return '#1d4ed8';
  }

  if (props.isFileRejected) {
    return '#ef4444';
  }

  if (props.isFileAccepted) {
    return '#009141';
  }

  return '#b8bcc8';
};

const UploadContainer = styled('div')(props => ({
  margin: '16px 0 4px',
  padding: '32px 0',
  border: 'dashed 2px',
  borderColor: `${getColor(props)}`,
  borderRadius: '6px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#f7fafd',
  '& h3': {
    margin: '24px 0',
  },
  '& .row': {
    fontSize: 12,
    color: '#838993',
    marginTop: 64,
  },
}));

const Error = styled('div')({
  color: '#ef4444',
  fontSize: 12,
});

const Actions = styled(DialogActions)({
  padding: '0 0 16px',
  justifyContent: 'center',
});

const ImportActions = styled(DialogActions)({
  justifyContent: 'center',
  padding: '16px 0',
});

const StackTitle = styled(Stack)({
  paddingRight: 16,
});

interface FileUploadDialogProps {
  open: boolean;
  title: string;
  sampleFileUrl: string;
  sampleFileName: string;
  onClose: (fileDetail?: FileDetail) => void;
}

const maxSize = 10485760; // 10MB

type FileRejectionErrorProps = { fileRejection: FileRejection };

function FileRejectionErrors(props: FileRejectionErrorProps) {
  const getError = (code: string) => {
    if (code === 'file-invalid-type')
      return (
        <>
          We&apos;re sorry, but the file type you&apos;ve attempted to upload is
          not supported. Please make sure you are uploading a valid file type
          and try again. If you have any questions or need assistance, please
          contact our support team for further help.
        </>
      );

    if (code === 'file-too-large')
      return (
        <>
          We apologize, but the file you&apos;re trying to upload is too large.
          Our maximum file size limit is 10 MB. Please reduce the file size or
          select a smaller file, and then try again. If you have any questions
          or need assistance, don&apos;t hesitate to contact our support team.
        </>
      );

    return <>{code}</>;
  };
  return (
    <>
      {props.fileRejection.errors.map((error, index) => (
        <Error className='row' key={index}>
          <ErrorOutlineIcon />
          {getError(error.code)}
        </Error>
      ))}
    </>
  );
}

interface PreviewFileProps {
  csvData: any[];
  onClose: () => void;
  title: string;
  onReset: () => void;
  onImport: () => void;
}

function PreviewFile(props: PreviewFileProps) {
  const columns = Object.keys(props.csvData[0]).map(key => ({
    field: key,
    label: key,
  }));

  return (
    <>
      <Dialog open fullWidth maxWidth='md' onClose={props.onClose}>
        <StackTitle
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <DialogTitle>Preview: {props.title}</DialogTitle>
          <IconButton onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </StackTitle>
        <DialogContent>
          <Table columns={columns} rows={props.csvData} />
        </DialogContent>
        <ImportActions>
          <Button startIcon={<CloudUploadIcon />} onClick={props.onImport}>
            Import
          </Button>
          <Button color='secondary' variant='text' onClick={props.onReset}>
            Choose another file
          </Button>
          <Button color='secondary' variant='text' onClick={props.onClose}>
            Cancel
          </Button>
        </ImportActions>
      </Dialog>
    </>
  );
}

const encoding = 'UTF-8';

export default function FileUploadDialog(props: FileUploadDialogProps) {
  const [fileRejections, setFileRejections] = useState<FileRejection[]>([]);
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
  const [parseErrors, setParseErrors] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      setFileRejections(fileRejections);
      setAcceptedFiles(acceptedFiles);
    },
    []
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/comma-separated-values': ['.csv'],
      'text/csv': ['.csv'],
    },
    multiple: false,
    noClick: true,
    maxSize,
  });

  const isFileAccepted = Boolean(acceptedFiles.length);
  const isFileRejected = Boolean(fileRejections.length);
  const isParseError = Boolean(parseErrors.length);
  const isPreview = Boolean(csvData.length);

  const fileInfo = () => {
    if (isFileAccepted) {
      return `File chosen: ${acceptedFiles[0].name}`;
    }

    if (isFileRejected) {
      return `File chosen: ${fileRejections[0].file.name}`;
    }

    return 'Please select a file to upload';
  };

  const handleClose = () => {
    handleReset();
    props.onClose();
  };

  const handlePreview = () => {
    const reader = new FileReader();

    reader.onload = () => {
      const parseResult = PapaParse.parse(reader.result as string, {
        header: true,
        encoding,
        preview: 100,
        skipEmptyLines: true,
      });

      setParseErrors(parseResult?.errors || []);
      setCsvData(parseResult?.data || []);
    };

    reader.readAsText(acceptedFiles[0], encoding);
  };

  const handleReset = () => {
    setAcceptedFiles([]);
    setCsvData([]);
    setFileRejections([]);
    setParseErrors([]);
  };

  const handleImport = async () => {
    const fileDetail = await uploadFile(acceptedFiles[0]);
    handleReset();
    props.onClose(fileDetail);
  };

  if (isPreview) {
    return (
      <PreviewFile
        csvData={csvData}
        onClose={handleClose}
        title={props.title}
        onReset={handleReset}
        onImport={handleImport}
      />
    );
  }

  return (
    <Dialog open={props.open} fullWidth maxWidth='sm' onClose={handleClose}>
      <StackTitle
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <DialogTitle>{props.title}</DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </StackTitle>
      <DialogContent>
        <UploadContainer
          {...getRootProps({
            isDragActive,
            isFileRejected,
            isFileAccepted,
          })}
        >
          <CloudUploadIcon fontSize='large' color='primary' />
          <h3>
            {isDragActive
              ? 'Drop your file here...'
              : 'Drag & drop your file here'}
          </h3>
          <div>
            or{' '}
            <Button component='label' variant='text'>
              browse file
              <VisuallyHiddenInput
                type='file'
                accept='.csv'
                {...getInputProps()}
              />
            </Button>{' '}
            on your computer
          </div>
          <div>{fileInfo()}</div>
          <div className='row'>
            <span>CSV</span>
            <span>|</span>
            <span>Max file size 10MB</span>
            <span>|</span>
            <Button component='a' variant='text' href={props.sampleFileUrl} download={props.sampleFileName}>
              Download sample file
            </Button>
          </div>
        </UploadContainer>
        {props.title == 'Import Fees' && <FlexRow size='xs'>
          <ReportGmailerrorredIcon color='warning'/> <div style={{color:'#e38323',fontSize:'12px'}}>roll_number, fee_type, amount are required field</div>
        </FlexRow>}
        {fileRejections.map((fileRejection, index) => (
          <FileRejectionErrors key={index} fileRejection={fileRejection} />
        ))}
        {isParseError &&
          parseErrors.map((error, index) => (
            <Error className='row' key={index}>
              <ErrorOutlineIcon />
              {error.message}
            </Error>
          ))}
      </DialogContent>
      <Actions>
        <Button
          startIcon={<PreviewIcon />}
          disabled={!isFileAccepted}
          onClick={handlePreview}
        >
          Preview
        </Button>
        <Button color='secondary' variant='text' onClick={handleClose}>
          Cancel
        </Button>
      </Actions>
    </Dialog>
  );
}
