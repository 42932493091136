import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import { useFormikContext, FieldAttributes } from 'formik';
import { useEffect } from 'react';

export default function CustomizedRadioGroup({
  name,
  innerRef,
  validate,
  className,
  ...props
}: RadioGroupProps & FieldAttributes<any>) {
  const formik = useFormikContext();

  // Register field and field-level validation with parent <Formik>
  const { registerField, unregisterField } = formik;
  useEffect(() => {
    registerField(name, {
      validate: validate,
    });
    return () => {
      unregisterField(name);
    };
  }, [registerField, unregisterField, name, validate]);

  const field = formik.getFieldProps({ name });

  return (
    <RadioGroup {...field} {...props} ref={innerRef} className={className} />
  );
}
