import { NavLink } from 'react-router-dom';
import './SideNavigationBar.scss';
import { useAccountStore } from 'store';
import { BEHAVIOR_ACTIONS } from 'views/shared/utils/validation-utils';


function SideNavigationBar() {
  const { hasAccess } = useAccountStore();
  const hasReceiptReadAccess = hasAccess(BEHAVIOR_ACTIONS.ReceiptRead);
  const hasConcessionReadAccess = hasAccess(BEHAVIOR_ACTIONS.ConcessionRead);
  const hasReportReadAccess = hasAccess(BEHAVIOR_ACTIONS.ReportingReportRead);
  const hasStudentReadAccess = hasAccess(BEHAVIOR_ACTIONS.StudentRead);
  const hasFeesReadAccess = hasAccess(BEHAVIOR_ACTIONS.FeesRead);
  
  return (
    <div className="nav-sidebar">
      {hasStudentReadAccess && <NavLink to="/students">
        <span className="material-symbols-outlined">group</span>
        <span>Students</span>
      </NavLink>}
      {hasFeesReadAccess && <NavLink to="/fees">
        <span className="material-symbols-outlined">currency_rupee</span>
        <span>Fees</span>
      </NavLink>}
      {hasConcessionReadAccess && <NavLink to="/concessions">
        <span className="material-symbols-outlined">savings</span>
        <span>Concessions</span>
      </NavLink>}
      {hasReceiptReadAccess && (<NavLink to="/receipts">
        <span className="material-symbols-outlined">receipt</span>
        <span>Receipts</span>
      </NavLink> )}
      {hasReportReadAccess && <NavLink to="/reports">
        <span className="material-symbols-outlined">chart_data</span>
        <span>Reports</span>
      </NavLink>}
      <NavLink to="/settings">
        <span className="material-symbols-outlined">domain</span>
        <span>My Institute</span>
      </NavLink>
    </div>
  );
}

export default SideNavigationBar;
