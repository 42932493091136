import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import { Button, Select, Form, ErrorAlert } from 'views/components/elements';
import { GradeLevel } from 'models';
import { FlexRow, FormField } from 'views/components/styled';
import { Actions, Content, Head } from './Elements';
import { useGradeLevelStore, useTermStore } from 'store';
import { useState } from 'react';

type GradeLevelDialogProps = {
  open: boolean;
  onClose: () => void;
  gradeLevel: GradeLevel
}

export default function GradeLevelDialog(props: GradeLevelDialogProps) {
  const [isFormInvalid, setFormInvalid] = useState(false);
  const terms = useTermStore(state => state.terms.filter(t => t.period === 'term'));
  const { enableGradeLevel } = useGradeLevelStore();

  const gradeLevel = {
    term_count: props.gradeLevel.terms.length || ''
  };

  const handleSubmit = async (values: typeof gradeLevel) => {
    if (!values.term_count) {
      setFormInvalid(true);
      return;
    }

    setFormInvalid(false);

    await enableGradeLevel({
      terms: terms.slice(0, +values.term_count).map(t => t.term_id)
    }, props.gradeLevel.grade_level_id);
    props.onClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
    >
      <Head>
        <DialogTitle>
          Select Number of Terms
        </DialogTitle>
        <IconButton onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </Head>
      <Form
        initialValues={gradeLevel}
        onSubmit={handleSubmit}
        render={() => (
          <>
            <Content>
              <FormField>
                <label>
                  Please select the number of academic terms for {props.gradeLevel.name} to proceed with the setup.
                </label>
                <Select name='term_count'>
                  {terms.map((_, index) => (
                    <MenuItem key={index} value={index + 1}>
                      {index + 1} Term{index > 0 ? 's' : ''}
                    </MenuItem>
                  ))}
                </Select>
                {isFormInvalid && (
                  <ErrorAlert>
                    This field is required.
                  </ErrorAlert>
                )}
              </FormField>
            </Content>
            <Actions>
              <div></div>
              <FlexRow size='xs'>
                <Button type='submit'>Submit</Button>
                <Button
                  variant='text'
                  color='secondary'
                  onClick={props.onClose}
                >
                  Discard
                </Button>
              </FlexRow>
            </Actions>
          </>
        )}
      />
    </Dialog>
  );
}
