import { KeyboardEvent, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Alert from '@mui/material/Alert';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import {
  Attribute,
  AttributeCategory,
  AttributeCreateOptions,
  AttributeUpdateOptions,
  AttributeUsage,
  AttributeValue,
  DataType,
  InputType
} from 'models';
import {
  Button,
  Form,
  Input,
  Select,
  NormalInput,
  MenuButton,
  Checkbox
} from 'views/components/elements';
import { Head, Content, Actions, FormField, ToggleButton } from './Elements';
import { useAccountStore, useAttributeStore } from 'store';
import { FlexColumn, FlexRow } from 'views/components/styled';
import PositionDialog from './Position';
import { BEHAVIOR_ACTIONS } from 'views/shared/utils/validation-utils';

const inputTypes = [
  { code: 'List', name: 'List' },
  { code: 'TextBox', name: 'Text Box' },
  { code: 'TextArea', name: 'Text area' },
  { code: 'YesNo', name: 'Yes No' },
  { code: 'Date', name: 'Date' }
];

const dataTypes = [
  { code: 'Text', name: 'Text' },
  { code: 'Number', name: 'Number' }
];

interface UsageInput {
  is_required: boolean
  is_selected: boolean
}

interface AttributeForm {
  label: string
  name: string
  code: string
  input_type: InputType
  data_type: DataType
  is_allow_filter: boolean
  values: AttributeValue[]
  student: UsageInput
  fee_type: UsageInput
  student_fee: UsageInput
  fee_rule: UsageInput
}

function DataTypeInput(props: AttributeForm) {
  if (props.input_type === 'List' || props.input_type === 'TextBox') {
    return (
      <FormField>
        <label>Data Type</label>
        <Select name='data_type' placeholder='Data Type' required>
          {dataTypes.map((dataType, index) => (
            <MenuItem key={index} value={dataType.code}>
              {dataType.name}
            </MenuItem>
          ))}
        </Select>
      </FormField>
    );
  }
}

function ValuesInput(props: AttributeForm & { isEdit: boolean }) {
  if (props.input_type === 'List' && (props.data_type === 'Text' || props.data_type === 'Number')) {
    const placeholder = props.data_type === 'Text' ? 'Add Value or Label:Value' : 'Add another';
    const [error, setError] = useState('');
    const [positionOpen, setPositionOpen] = useState(false);
    const [oldPosition, setOldPosition] = useState(0);

    const getMenus = (arrayProps: FieldArrayRenderProps, value: AttributeValue) => {
      const menus = [
        {
          label: 'Move to Top',
          onClick: () => arrayProps.move(props.values.indexOf(value), 0)
        },
        {
          label: 'Move to Position',
          onClick: () => {
            setOldPosition(props.values.indexOf(value));
            setPositionOpen(true);
          }
        },
        {
          label: 'Move to Bottom',
          onClick: () => arrayProps.move(props.values.indexOf(value), props.values.length - 1)
        },
        {
          label: 'Remove',
          onClick: () => arrayProps.remove(props.values.indexOf(value))
        }
      ];

      if (props.isEdit) {
        if (value.is_active) {
          menus.push({
            label: 'Disable',
            onClick: () => arrayProps.replace(props.values.indexOf(value), { ...value, is_active: false })
          });
        } else {
          menus.push({
            label: 'Enable',
            onClick: () => arrayProps.replace(props.values.indexOf(value), { ...value, is_active: true })
          });
        }
      }

      return menus;
    };

    const positionChangeHandler = (arrayProps: FieldArrayRenderProps) => (value?: number) => {
      if (value) {
        arrayProps.move(oldPosition, value - 1);
      }
      setPositionOpen(false);
    };

    const valueSubmitHandler = (arrayProps: FieldArrayRenderProps) => (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.stopPropagation();
        event.preventDefault();
        setError('');
        let value = event.currentTarget.value?.trim();
        let label = value;

        if (props.data_type === 'Text' && value?.includes(':')) {
          const options = value.split(':');
          label = options[0].trim();
          value = options[1].trim();
        }

        if (value) {
          if (props.values.some(v => v.value.toLowerCase() === value.toLowerCase())) {
            setError(`Value ${value} already exists`);
          } else {
            arrayProps.push({
              value,
              label,
              is_active: true
            });
            event.currentTarget.value = '';
          }
        }
      }
    };

    return (
      <>
        <FieldArray
          name='values'
          render={arrayProps => (
            <>
              <FlexColumn size='xs'>
                <label>Values</label>
                <NormalInput placeholder={placeholder} onKeyDown={valueSubmitHandler(arrayProps)} />
                {!!error && (
                  <Alert severity='error'>
                    {error}
                  </Alert>
                )}
              </FlexColumn>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Pos
                    </TableCell>
                    <TableCell>
                      Label
                    </TableCell>
                    <TableCell>
                      Value
                    </TableCell>
                    {props.isEdit && (
                      <TableCell>
                        Enabled
                      </TableCell>
                    )}
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.values?.map((value, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{value.label}</TableCell>
                      <TableCell>{value.value}</TableCell>
                      {props.isEdit && (
                        <TableCell>
                          {value.is_active ? 'Yes' : 'No'}
                        </TableCell>
                      )}
                      <TableCell>
                        <MenuButton
                          menus={getMenus(arrayProps, value)}
                          variant='text'
                          size='small'
                          color='secondary'
                        >
                          <MoreVertIcon />
                        </MenuButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {!props.values?.length && (
                <div>
                  None Available
                </div>
              )}
              <PositionDialog
                max={props.values.length}
                open={positionOpen}
                onClose={positionChangeHandler(arrayProps)}
              />
            </>
          )}
        />
      </>
    );
  }
}

type AttributeDialogProps = {
  attribute?: Attribute;
  open: boolean;
  onClose: () => void;
};

export default function AttributeDialog(props: AttributeDialogProps) {
  const { createAttribute, updateAttribute, disableAttribute, fetchAttributes, enableAttribute } = useAttributeStore();
  const [errors, setErrors] = useState<string[]>([]);
  const isEdit = Boolean(props.attribute?.attribute_id);
  const { hasAccess } = useAccountStore();
  const hasAttributeEnableAccess = hasAccess(BEHAVIOR_ACTIONS.AttributeEnable);
  const hasAttributeDisableAccess = hasAccess(BEHAVIOR_ACTIONS.AttributeDisable);

  const mapUsageInput = (category: AttributeCategory) => {
    const usage = props.attribute?.usages?.find(u => u.category === category);
    return {
      is_selected: Boolean(usage),
      is_required: Boolean(usage?.is_required)
    } as UsageInput;
  };

  const attribute = {
    label: props.attribute?.label || '',
    name: props.attribute?.name || '',
    code: props.attribute?.code || '',
    input_type: props.attribute?.input_type || '',
    data_type: props.attribute?.data_type || '',
    is_allow_filter: Boolean(props.attribute?.is_allow_filter),
    values: props.attribute?.values || [],
    student: mapUsageInput('Student'),
    fee_rule: mapUsageInput('FeeRule'),
    fee_type: mapUsageInput('FeeType'),
    student_fee: mapUsageInput('StudentFee')
  } as AttributeForm;

  const handleSubmit = async (model: AttributeForm) => {
    const errors = [];

    if (model.input_type === 'List' && !model.values.length) {
      errors.push('Values table cannot be blank. Please Add Value or Label');
    }

    setErrors(errors);

    if (errors.length) {
      return;
    }

    let data_type = model.data_type;

    if (model.input_type === 'YesNo') {
      data_type = 'Flag';
    } else if (model.input_type === 'Date') {
      data_type = 'Date';
    } else if (model.input_type === 'TextArea') {
      data_type = 'Text';
    }

    const usages = [] as AttributeUsage[];

    const addUsage = (input: UsageInput, category: AttributeCategory) =>
      input.is_selected && usages.push({ category, is_required: Boolean(input.is_required) });

    addUsage(model.student, 'Student');
    addUsage(model.fee_rule, 'FeeRule');
    addUsage(model.fee_type, 'FeeType');
    addUsage(model.student_fee, 'StudentFee');

    const options = {
      name: model.name,
      label: model.label,
      input_type: model.input_type,
      data_type,
      usages,
      is_allow_filter: model.is_allow_filter
    };

    if (isEdit) {
      const updateOptions = {
        ...options,
        is_active: props.attribute.is_active,
        values: model.values.map((v, i) => ({
          label: v.label,
          value: v.value,
          position: i + 1,
          is_active: v.is_active,
          attribute_value_id: v.attribute_value_id
        }))
      } as AttributeUpdateOptions;

      await updateAttribute(updateOptions, props.attribute.attribute_id);
    } else {
      const createOptions = {
        ...options,
        code: model.code,
        values: model.values.map((v, i) => ({
          label: v.label,
          value: v.value,
          position: i + 1
        }))
      } as AttributeCreateOptions;

      await createAttribute(createOptions);
    }

    props.onClose();
  };

  const handleToggle = async () => {

    if (props.attribute.is_active){
      await disableAttribute(props.attribute.attribute_id);
    } else {
      await enableAttribute(props.attribute.attribute_id);
    }

    props.onClose();
    await fetchAttributes();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth='sm' fullWidth>
      <Head>
        <DialogTitle>{isEdit ? 'Edit' : 'Add'} Attribute</DialogTitle>
        <IconButton onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </Head>
      <Form
        initialValues={attribute}
        onSubmit={handleSubmit}
        render={({ values }) => (
          <>
            <Content>
              <FormField>
                <label>Attribute Label</label>
                <Input name='label' placeholder='Enter a label for the attribute' required />
              </FormField>
              <FormField>
                <label>Administration Name</label>
                <Input name='name' placeholder='Enter an attribute name' required />
              </FormField>
              <FormField>
                <label>Attribute Code</label>
                <Input name='code' placeholder='Enter a unique attribute code' required disabled={isEdit} />
              </FormField>
              <FormField>
                <label>Attribute Usages</label>
                <FlexRow>
                  <Checkbox name='student.is_selected' label='Student' />
                  {values.student.is_selected && (
                    <Checkbox name='student.is_required' label='Mandatory Attribute' />
                  )}
                </FlexRow>
                <FlexRow>
                  <Checkbox name='fee_type.is_selected' label='Fee Types' />
                  {values.fee_type.is_selected && (
                    <Checkbox name='fee_type.is_required' label='Mandatory Attribute' />
                  )}
                </FlexRow>
                <FlexRow>
                  <Checkbox name='student_fee.is_selected' label='Fees' />
                  {values.student_fee.is_selected && (
                    <Checkbox name='student_fee.is_required' label='Mandatory Attribute' />
                  )}
                </FlexRow>
                <FlexRow>
                  <Checkbox name='fee_rule.is_selected' label='Fee Rules' />
                  {values.fee_rule.is_selected && (
                    <Checkbox name='fee_rule.is_required' label='Mandatory Attribute' />
                  )}
                </FlexRow>
              </FormField>
              <FormField>
                <label>Input Type</label>
                <Select name='input_type' placeholder='Input Type' required>
                  {inputTypes.map((inputType, index) => (
                    <MenuItem key={index} value={inputType.code}>
                      {inputType.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormField>
              <DataTypeInput {...values} />
              <FormField>
                <Checkbox name='is_allow_filter' label='Available as Filter' />
              </FormField>
              <ValuesInput {...values} isEdit={isEdit} />
              {errors.map((error, index) => (
                <Alert severity='error' key={index}>
                  {error}
                </Alert>
              ))}
            </Content>
            <Actions>
              <div>
                <ToggleButton
                  isEdit={isEdit}
                  isActive={props.attribute?.is_active}
                  onClick={handleToggle}
                  disabled={!(props?.attribute?.is_active ? hasAttributeDisableAccess: hasAttributeEnableAccess)}
                />
              </div>
              <FlexRow size='xs'>
                <Button type='submit'>Save Changes</Button>
                <Button
                  variant='text'
                  color='secondary'
                  onClick={props.onClose}
                >
                  Cancel
                </Button>
              </FlexRow>
            </Actions>
          </>
        )}
      />
    </Dialog>
  );
}
