import { BankAccount, BankAccountOptions } from '../models/BankAccount';
import { sendRequest, getRequest, postRequest, putRequest, deleteRequest } from './gateway';

function getBankAccount(bankAccountId: string) {
  const fetchPromise = getRequest<BankAccount>(`/api/v1/account/${bankAccountId}`);

  return sendRequest(fetchPromise);
}

function createBankAccount(bankAccount: BankAccountOptions) {
  const postPromise = postRequest<BankAccount, BankAccountOptions>('/api/v1/account', bankAccount);

  return sendRequest(postPromise);
}

function updateBankAccount(bankAccount: BankAccountOptions, bankAccountId: string) {
  const putPromise = putRequest<BankAccount, BankAccountOptions>(`/api/v1/account/${bankAccountId}`, bankAccount);

  return sendRequest(putPromise);
}

function listBankAccounts() {
  const fetchPromise = getRequest<BankAccount[]>('/api/v1/account/list');

  return sendRequest(fetchPromise);
}

function deleteBankAccount(bankAccountId: string) {
  const fetchPromise = deleteRequest<void>(`/api/v1/account/${bankAccountId}`);

  return sendRequest(fetchPromise);
}

function enableBankAccount(bankAccountId: string) {
  const putPromise = putRequest(`/api/v1/account/${bankAccountId}/enable`,null);
  return sendRequest(putPromise);
}

function disableBankAccount(bankAccountId: string) {
  const putPromise = putRequest(`/api/v1/account/${bankAccountId}/disable`,null);
  return sendRequest(putPromise);
}

export { getBankAccount, createBankAccount, updateBankAccount, listBankAccounts, deleteBankAccount, enableBankAccount, disableBankAccount };
