import { FieldArray } from 'formik';
import { Attribute, AttributeCategory, Metadata } from 'models';
import { FormField } from 'views/components/styled';
import MenuItem from '@mui/material/MenuItem';
import Input from './Input';
import Textarea from './Textarea';
import Select from './Select';
import RadioGroup from './RadioGroup';
import Radio from './Radio';

export function AttributeInput({ attribute, name }: { attribute: Attribute, name: string }) {
  if (attribute.input_type === 'TextBox' || attribute.input_type === 'Date') {
    const type = attribute.data_type === 'Number' ? 'number' : attribute.data_type === 'Date' ? 'date' : 'text';
    return (
      <Input name={name} placeholder={attribute.name} type={type} onWheel={(e:any) => e.target.blur()}/>
    );
  }

  if (attribute.input_type === 'TextArea') {
    return (
      <Textarea name={name} placeholder={attribute.name} />
    );
  }

  if (attribute.input_type === 'List') {
    return (
      <Select name={name} placeholder={attribute.name}>
        {attribute.values.filter(x => x.is_active).map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    );
  }

  if (attribute.input_type === 'YesNo') {
    return (
      <RadioGroup name={name} row>
        <Radio value='Yes' label='Yes' />
        <Radio value='No' label='No' />
      </RadioGroup>
    );
  }
}

type CustomAttributesProps = {
  metadata: FormMetadata[]
  attributes: Attribute[]
  category: AttributeCategory
}

export default function CustomAttributes(props: CustomAttributesProps) {
  const getName = (attribute: Attribute) => {
    const index = props.metadata.findIndex(x => x.key === attribute.code);
    return `metadata[${index}].value`;
  };

  const requiredLabel = (attribute: Attribute) => {
    const usage = attribute.usages.find(u => u.category === props.category);
    if (usage.is_required) {
      return '*';
    }
  };

  const attributes = props.attributes.filter(a => a.is_active);

  return (
    <FieldArray
      name='metadata'
      render={() => (
        <>
          {attributes.map((attribute, index) => (
            <FormField key={index}>
              <label>
                {attribute.label}{requiredLabel(attribute)}
              </label>
              <AttributeInput name={getName(attribute)} attribute={attribute} />
            </FormField>
          ))}
        </>
      )}
    />
  );
}

interface FormMetadata {
  key: string
  value: any
}

export const buildFormMetadata = (metadata?: Metadata, attributes?: Attribute[], defaultIfEmpty = false) => {
  const formMetadata = [] as FormMetadata[];

  if (metadata) {
    for (const key in metadata) {
      formMetadata.push({ key, value: metadata[key] });
    }
  }

  if (attributes) {
    for (const attribute of attributes) {
      if (!formMetadata.some(x => x.key === attribute.code)) {
        formMetadata.push({ key: attribute.code, value: '' });
      }
    }
  }

  if (defaultIfEmpty && !formMetadata.length) {
    formMetadata.push({ key: '', value: '' });
  }

  return formMetadata;
};

export const metadataToJson = (formMetadata: FormMetadata[]) => {
  const metadata = {};

  for (const item of formMetadata) {
    if (item.key) {
      metadata[item.key] = item.value;
    }
  }

  return metadata;
};
