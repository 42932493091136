import { listReceipts } from 'gateways/receipt';
import { getStudent, importStudents, listStudents, createStudent, updateStudent, enableStudent, disableStudent } from 'gateways/student';
import { listStudentFees } from 'gateways/student-fee';
import {
  StudentListParams,
  FileDetail,
  StudentCreateOptions,
  StudentUpdateOptions,
  StudentFeeView,
  StudentFeeParams,
  Receipt,
  ReceiptListParams,
  Student,
} from 'models';
import { create } from 'zustand';

interface State {
  filter: StudentListParams,
  students: Student[],
  hasNextPage: boolean,
  selected: string[]
  total: number
  student?: Student;
  fees?: StudentFeeView[];
  receipts?: Receipt[];
}

interface Action {
  fetchStudentList: (params?: StudentListParams) => Promise<void>
  importStudents: (file: FileDetail) => Promise<void>
  selectStudents: (student: string[]) => void
  fetchStudent: (studentId: string) => void
  createStudent: (student: StudentCreateOptions) => Promise<void>
  updateStudent: (student: StudentUpdateOptions, studentId: string) => Promise<void>
  fetchFees: (studentId: string) => void
  fetchReceipts: (studentId: string) => void
  enableStudent: (studentId: string) => Promise<void>
  disableStudent: (studentId: string) => Promise<void>
}

export const useStudentStore = create<State & Action>((set, get) => ({
  filter: {},
  students: [],
  hasNextPage: false,
  selected: [],
  total: 0,
  fetchStudentList: async (params?: StudentListParams) => {
    params ??= {} as StudentListParams;
    params.sortColumn ??= 'roll_number';
    params.sortDirection ??= 'desc';
    params.limit ??= 100;
    params.page ??= 1;

    const page = await listStudents(params);

    set(state => ({
      filter: params,
      hasNextPage: params.page < page.metadata.last_page,
      selected: [],
      students: params.page === 1 ? page.collection : state.students.concat(page.collection),
      total: page.metadata.total
    }));
  },
  importStudents: async (file: FileDetail) => {
    await importStudents(file.location);

    const { fetchStudentList, filter } = get();

    fetchStudentList({ ...filter, page: 1 });
  },
  selectStudents: (students: string[]) => set({ selected: students }),
  fetchStudent: async (studentId: string) => {
    if (!studentId || get().student?.student_id === studentId) {
      return;
    }
    const student = await getStudent(studentId);
    set({ student });
  },
  createStudent: async (options: StudentCreateOptions) => {
    const student = await createStudent(options);
    set(state => ({
      student,
      students: state.students.slice().concat(student),
      total: state.total + 1
    }));
  },
  updateStudent: async (options: StudentUpdateOptions, studentId: string) => {
    const students = get().students.slice();
    const student = await updateStudent(options, studentId);
    const index = students.findIndex(s => s.student_id === student.student_id);
    if (index >= 0) {
      students.splice(index, 1, student);
    }
    set({ student, students });
  },
  enableStudent: async (studentId: string) => {
    await enableStudent(studentId);
    const student = await getStudent(studentId);
    set({ student });
  },
  disableStudent: async (studentId: string) => {
    await disableStudent(studentId);
    const student = await getStudent(studentId);
    set({ student });
  },
  fetchFees: async (studentId: string) => {
    const { fees } = get();

    if (fees?.some(x => x.student_id = studentId)) {
      return;
    }

    const page = await listStudentFees({
      studentId,
      page: 1,
      limit: 1000,
      sortColumn: 'created_date_time',
      sortDirection: 'desc',
      statuses: ['Open', 'Overdue', 'Paid', 'PartlyPaid', 'PartlyRefund', 'Refund']
    } as StudentFeeParams);
    set({ fees: page.collection });
  },
  fetchReceipts: async (studentId: string) => {
    const { receipts } = get();
    if (receipts?.some(x => x.student_id === studentId)) {
      return;
    }

    const page = await listReceipts({
      studentId,
      statuses: ['Captured', 'Disputed', 'PartiallyRefunded', 'Refunded'],
      page: 1,
      limit: 1000,
      sortColumn: 'created_date_time',
      sortDirection: 'desc'
    } as ReceiptListParams);

    set({ receipts: page.collection });
  },
}));
