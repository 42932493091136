import { FeeRule, FeeRuleCreateOptions, FeeRuleUpdateOptions } from 'models';
import { getRequest, postRequest, putRequest, sendRequest } from './gateway';

export function getFeeRule(feeRuleId: string) {
  const fetchPromise = getRequest<FeeRule>(`/api/v1/fee-rule/${feeRuleId}`);

  return sendRequest(fetchPromise);
}

export function listFeeRules() {
  const fetchPromise = getRequest<FeeRule[]>('/api/v1/fee-rule/list');

  return sendRequest(fetchPromise);
}

export function createFeeRule(options: FeeRuleCreateOptions) {
  const postPromise = postRequest<FeeRule>('/api/v1/fee-rule', options);

  return sendRequest(postPromise);
}

export function updateFeeRule(options: FeeRuleUpdateOptions, feeRuleId: string) {
  const putPromise = putRequest<FeeRule>(`/api/v1/fee-rule/${feeRuleId}`, options);

  return sendRequest(putPromise);
}

export function enableFeeRule(feeRuleId: string) {
  const putPromise = putRequest(`/api/v1/fee-rule/${feeRuleId}/enable`,null);
  return sendRequest(putPromise);
}

export function disableFeeRule(feeRuleId: string) {
  const putPromise = putRequest(`/api/v1/fee-rule/${feeRuleId}/disable`,null);
  return sendRequest(putPromise);
}
