import { LateCharge, LateChargeCreateOptions, LateChargeUpdateOptions } from 'models';
import { getRequest, postRequest, putRequest, sendRequest } from './gateway';

export function getLateCharge(lateChargeId: string) {
  const fetchPromise = getRequest<LateCharge>(`/api/v1/late-charge/${lateChargeId}`);
  
  return sendRequest(fetchPromise);
}
  
export function createLateCharge(lateCharge: LateChargeCreateOptions) {
  const postPromise = postRequest<LateCharge>('/api/v1/late-charge', lateCharge);
  
  return sendRequest(postPromise);
}
  
export function updateLateCharge(lateCharge: LateChargeUpdateOptions, lateChargeId: string) {
  const putPromise = putRequest<LateCharge>(`/api/v1/late-charge/${lateChargeId}`, lateCharge);
  
  return sendRequest(putPromise);
}
  
export function listLateCharges() {
  const fetchPromise = getRequest<LateCharge[]>('/api/v1/late-charge/list');
  
  return sendRequest(fetchPromise);
}

export function enableLateCharge(lateChargeId: string) {
  const putPromise = putRequest(`/api/v1/late-charge/${lateChargeId}/enable`,null);
  return sendRequest(putPromise);
}

export function disableLateCharge(lateChargeId: string) {
  const putPromise = putRequest(`/api/v1/late-charge/${lateChargeId}/disable`,null);
  return sendRequest(putPromise);
}
