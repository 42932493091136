import MatAlert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';

const Alert = styled(MatAlert)({
  padding: 0,
  backgroundColor: 'transparent',
  '.MuiAlert-message': {
    padding: 0,
  }
});

export default function ErrorAlert(props: AlertProps) {
  return <Alert icon={false} severity='error' {...props} />;
}
