import { styled } from '@mui/material/styles';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import { MouseEventHandler } from 'react';
import { Button } from 'views/components/elements';

export const Head = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingRight: 24,
});

export const Actions = styled(DialogActions)({
  justifyContent: 'space-between',
  padding: '16px 24px',
});

export const Content = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 16,
  maxHeight: '70vh'
});

export const FormField = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 8,
  '& label': {
    fontSize: 14,
  },
});

type ToggleButtonProps = {
  isEdit: boolean
  isActive: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
}

export function ToggleButton(props: ToggleButtonProps) {
  if (props.isEdit) {
    if (props.isActive) {
      return (
        <Button
          startIcon={<ToggleOffIcon />}
          variant='text'
          color='error'
          onClick={props.onClick}
          disabled={props?.disabled}
        >
          Disable
        </Button>
      );
    } else {
      return (
        <Button
          startIcon={<ToggleOnIcon />}
          variant='text'
          color='primary'
          onClick={props.onClick}
          disabled={props?.disabled}
        >
          Enable
        </Button>
      );
    }
  }
}
