import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SearchIcon from '@mui/icons-material/Search';
import { Table, Button, Form, Input } from 'views/components/elements';
import { useAccountStore, useAttributeStore, useStudentFeeStore } from 'store';
import { FileDetail, StudentFeeParams, StudentFeeView } from 'models';
import { FileUploadDialog } from 'views/dialogs';
import { columns } from './Columns';
import { buildSampleFile } from './FeesListPending';
import { BEHAVIOR_ACTIONS } from 'views/shared/utils/validation-utils';

const draftColumns = columns.filter(
  c =>
    ![
      'student_fee_status',
      'captured_offline',
      'captured_online',
      'amount_capturable',
    ].includes(c.field)
);

function FeesListDraft() {
  const [importOpen, setImportOpen] = useState(false);

  const { attributes, fetchAttributes } = useAttributeStore();
  const { draft, fetchFees, selectFees, finalizeFees, deleteFees, importFees } =
    useStudentFeeStore();
  const {hasAccess} = useAccountStore();
  const hasFeesFinalizeAccess = hasAccess(BEHAVIOR_ACTIONS.FeesFinalise);
  const hasFeesDeleteAccess = hasAccess(BEHAVIOR_ACTIONS.FeesDelete);

  const {
    fees: draftList,
    filter: draftFilter,
    hasNextPage: hasNextPageDraft,
    selected: draftListSelected,
  } = draft;

  const selected = draftList.filter(x =>
    draftListSelected.includes(x.student_fee_id)
  );

  useEffect(() => {
    const myParam = new URLSearchParams(location?.search).get('applied');
    if (!draftList.length || myParam) {
      fetchFees('draft', {
        statuses: ['Draft'],
      } as StudentFeeParams);
    }

    if (!attributes.length) {
      fetchAttributes();
    }
  }, []);

  const handleSort = (field: string, order: string) => {
    const filter = Object.assign({}, draftFilter, {
      sortColumn: field,
      sortDirection: order,
      page: 1,
    });

    fetchFees('draft', filter);
  };

  const handleNextPage = () => {
    const filter = Object.assign({}, draftFilter, {
      page: draftFilter.page + 1,
    });

    fetchFees('draft', filter);
  };

  const filter = { query: draftFilter.query || '' };

  const handleSubmit = (values: typeof filter) => {
    const newFilter = Object.assign({}, draftFilter, {
      page: 1,
      query: values.query,
    });

    fetchFees('draft', newFilter);
  };

  const handleSelectionChange = (rows: StudentFeeView[]) => {
    selectFees(
      'draft',
      rows.map(x => x.student_fee_id)
    );
  };

  const handleFinalize = async () => {
    await finalizeFees(draftListSelected);
  };

  const handleDelete = async () => {
    await deleteFees(draftListSelected);
  };

  const handleImport = () => {
    setImportOpen(true);
  };

  const handleFileUpload = async (value?: FileDetail) => {
    setImportOpen(false);
    if (value) {
      await importFees(value);
    }
  };

  const sampleFileUrl = buildSampleFile(attributes);

  return (
    <>
      {(Boolean(selected.length) && (
        <div className='row action'>
          <div>{selected.length} selected</div>
          <div className='row'>
            <Button
              startIcon={<CheckCircleOutlineIcon />}
              onClick={handleFinalize}
              disabled={!hasFeesFinalizeAccess}
            >
              Finalize
            </Button>
            <Button
              startIcon={<DeleteIcon />}
              color='error'
              onClick={handleDelete}
              disabled={!hasFeesDeleteAccess}
            >
              Delete
            </Button>
          </div>
        </div>
      )) || (
        <div className='row action'>
          <Form
            initialValues={filter}
            onSubmit={handleSubmit}
            validateOnChange={false}
          >
            <Input
              name='query'
              placeholder='Search Fees'
              startAdornment={<SearchIcon />}
            />
          </Form>
          <Button startIcon={<FileUploadIcon />} onClick={handleImport}>
              Import
          </Button>
        </div>
      )}
      <Table
        columns={draftColumns}
        rows={draftList}
        stickyHeader
        onSortChange={handleSort}
        sortingField={draftFilter.sortColumn}
        sortingOrder={draftFilter.sortDirection}
        hasMoreRows={hasNextPageDraft}
        infiniteScroll
        onNextPage={handleNextPage}
        selectable
        selected={selected}
        onSelectionChange={handleSelectionChange}
      ></Table>
      <FileUploadDialog
        open={importOpen}
        title='Import Fees'
        sampleFileUrl={sampleFileUrl}
        sampleFileName='student-fee_sample.csv'
        onClose={handleFileUpload}
      />
    </>
  );
}

export default FeesListDraft;
