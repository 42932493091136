import { createLateCharge, getLateCharge, listLateCharges, updateLateCharge, enableLateCharge, disableLateCharge } from 'gateways/lateCharge';
import { LateCharge, LateChargeCreateOptions, LateChargeUpdateOptions } from 'models';
import { create } from 'zustand';

type State = {
  lateCharges: LateCharge[];
  lateCharge?: LateCharge | null;
};

type Action = {
  fetchLateCharges: () => Promise<void>;
  createLateCharge: (options: LateChargeCreateOptions) => Promise<void>;
  updateLateCharge: (options: LateChargeUpdateOptions, lateChargeId: string) => Promise<void>;
  fetchLateCharge: (lateChargeId: string) => Promise<void>;
  enableLateCharge: (lateChargeId: string) => Promise<void>
  disableLateCharge: (lateChargeId: string) => Promise<void>
  resetLateCharge: () => void;
};

export const useLateChargeStore = create<State & Action>()((set, get) => ({
  lateCharges: [],
  lateCharge: null,
  
  fetchLateCharges: async () => {
    const lateCharges = await listLateCharges();
    set({ lateCharges });
  },

  createLateCharge: async (options: LateChargeCreateOptions) => {
    const lateCharge = await createLateCharge(options);
    set((state) => ({
      lateCharge,
      lateCharges: state.lateCharges.concat(lateCharge)
    }));
  },

  updateLateCharge: async (options: LateChargeUpdateOptions, lateChargeId: string) => {
    const lateCharge = await updateLateCharge(options, lateChargeId);

    const lateCharges = get().lateCharges.slice();
    const index = lateCharges.findIndex(x => x.late_charge_id === lateChargeId);
    if (index !== -1) {
      lateCharges.splice(index, 1, lateCharge);
    }

    set({ lateCharges, lateCharge });
  },

  fetchLateCharge: async (lateChargeId: string) => {
    const lateCharge = await getLateCharge(lateChargeId);
    set({ lateCharge });
  },

  enableLateCharge: async (lateChargeId: string) => {
    await enableLateCharge(lateChargeId);
  },
  disableLateCharge: async (lateChargeId: string) => {
    await disableLateCharge(lateChargeId);
  },

  resetLateCharge: () => {
    set({ lateCharge: null });
  }
}));
