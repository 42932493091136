import { NavLink, Outlet } from 'react-router-dom';

function FeesList() {
  return (
    <>
      <div className='custom-table-wrapper'>
        <div className='row head'>
          <h1>Fees</h1>
          <div className='nav-header'>
            <NavLink to='draft'>To finalize</NavLink>
            <NavLink to='pending'>Yet to collect</NavLink>
            <NavLink to='completed'>Collected</NavLink>
            <NavLink to='advanced'>Advanced Search</NavLink>
          </div>
        </div>
        <Outlet />
      </div>
    </>
  );
}

export default FeesList;
