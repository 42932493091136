import { createProgram, disableProgram, enableProgram, listPrograms, updateProgram } from 'gateways/program';
import { Duration, Program, ProgramOptions } from 'models';
import { create } from 'zustand';

type State = {
  programs: Program[]
  durations: Duration[]
}

type Action = {
  fetchPrograms: () => void
  enableProgram: (programId: string) => Promise<void>
  disableProgram: (programId: string) => Promise<void>
  createProgram: (program: ProgramOptions) => Promise<void>
  updateProgram: (program: ProgramOptions, programId: string) => Promise<void>
}

export const useProgramStore = create<State & Action>()((set, get) => ({
  programs: [],
  durations: [
    { id: 12, name: '1 Year' },
    { id: 24, name: '2 Years' },
    { id: 36, name: '3 Years' },
    { id: 48, name: '4 Years' },
    { id: 60, name: '5 Years' }
  ],
  fetchPrograms: async () => {
    const programs = await listPrograms();
    const { durations } = get();
    for (const program of programs) {
      const duration = durations.find(d => d.id === program.duration);
      program.duration_name = duration.name;
    }
    set({ programs });
  },
  enableProgram: async (programId: string) => {
    await enableProgram(programId);
    const { fetchPrograms } = get();
    fetchPrograms();
  },
  disableProgram: async (programId: string) => {
    await disableProgram(programId);
    const { fetchPrograms } = get();
    fetchPrograms();
  },
  createProgram: async (program: ProgramOptions) => {
    await createProgram(program);
    const { fetchPrograms } = get();
    fetchPrograms();
  },
  updateProgram: async (program: ProgramOptions, programId: string) => {
    await updateProgram(program, programId);
    const { fetchPrograms } = get();
    fetchPrograms();
  }
}));
