import { ReceiptTemplate, ReceiptTemplateOptions } from '../models/ReceiptTemplate';
import { sendRequest, getRequest, postRequest, putRequest } from './gateway';

function getReceiptTemplate(receiptTemplateId: string) {
  const fetchPromise = getRequest<ReceiptTemplate>(`/api/v1/receipt-template/${receiptTemplateId}`);

  return sendRequest(fetchPromise);
}

function createReceiptTemplate(receiptTemplate: ReceiptTemplateOptions) {
  const postPromise = postRequest<ReceiptTemplate>('/api/v1/receipt-template', receiptTemplate);

  return sendRequest(postPromise);
}

function updateReceiptTemplate(receiptTemplate: ReceiptTemplateOptions, receiptTemplateId: string) {
  const putPromise = putRequest<ReceiptTemplate>(`/api/v1/receipt-template/${receiptTemplateId}`, receiptTemplate);

  return sendRequest(putPromise);
}

function listReceiptTemplates() {
  const fetchPromise = getRequest<ReceiptTemplate[]>('/api/v1/receipt-template/list');

  return sendRequest(fetchPromise);
}

function enableReceiptTemplate(ReceiptTemplateId: string) {
  const putPromise = putRequest(`/api/v1/receipt-template/${ReceiptTemplateId}/enable`,null);
  return sendRequest(putPromise);
}

function disableReceiptTemplate(ReceiptTemplateId: string) {
  const putPromise = putRequest(`/api/v1/receipt-template/${ReceiptTemplateId}/disable`,null);
  return sendRequest(putPromise);
}

export { getReceiptTemplate, createReceiptTemplate, updateReceiptTemplate, listReceiptTemplates, enableReceiptTemplate, disableReceiptTemplate };
