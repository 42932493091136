import { Student } from 'models';
import { Column } from 'views/components/elements';

export const columns = [
  {
    field: 'roll_number',
    label: 'roll no',
    width: 150,
    sortable: true,
  },
  {
    field: 'first_name',
    label: 'name',
    valueGetter: row => `${row.first_name} ${row.last_name}`
  },
  {
    field: 'total_amount',
    label: 'total fee',
    width: 120,
    format: 'currency'
  },
  {
    field: 'amount_capturable',
    label: 'fee paid',
    width: 120,
    format: 'currency'
  },
  {
    field: 'captured_amount',
    label: 'balance amount',
    width: 150,
    format: 'currency'
  },
  {
    field: 'last_receipt_date_time',
    label: 'last payment',
    width: 150,
    format: 'date'
  },
  {
    field: 'created_date_time',
    label: 'created',
    width: 120,
    format: 'date'
  }
] as Column<Student>[];
