import { create } from 'zustand';
import {
  ConcessionListView,
  ConcessionListParams,
  StudentFeeView,
  StudentFeeParams,
  EntityResult,
  Concession,
  ConcessionCreateOptions,
  ConcessionUpdateOptions,
  ConcessionCancelOptions,
  ApiResult,
  Student,
} from 'models';
import { approveConcession, cancelConcession, createConcession, deleteConcession, getConcession, listConcessions, updateConcession } from 'gateways/concessions';
import { listStudentFees } from 'gateways/student-fee';
import { getStudent } from 'gateways/student';

type State = {
  filter: ConcessionListParams
  hasNextPage: boolean
  concessions: ConcessionListView[]
  fees: StudentFeeView[]
  concessionResult?: EntityResult<Concession>
  concession?: Concession
  student?: Student
  selectedFees: StudentFeeView[]
  cancelResult?: ApiResult
}

type Action = {
  fetchConcessions: (params?: ConcessionListParams) => Promise<void>
  fetchFees: (studentId: string) => Promise<void>
  createConcession: (options: ConcessionCreateOptions) => Promise<EntityResult<Concession>>
  fetchConcession: (concessionId: string) => Promise<void>
  getStudent: (studentId: string) => Promise<void>
  updateConcession: (options: ConcessionUpdateOptions, concessionId: string) => Promise<EntityResult<Concession>>
  approveConcession: (options: ConcessionUpdateOptions, concessionId: string) => Promise<EntityResult<Concession>>
  editConcession: (concessionView: ConcessionListView) => Promise<void>
  reset: () => void
  setSelectedFees: (fees: StudentFeeView[]) => void
  deleteConcession: (concessionId: string) => Promise<void>
  cancelConcession: (options: ConcessionCancelOptions, concessionId: string) => Promise<ApiResult>
}

const useConcessionStore = create<State & Action>()((set, get) => ({
  filter: {} as ConcessionListParams,
  hasNextPage: false,
  concessions: [],
  fees: [],
  selectedFees: [],
  fetchConcessions: async (params?: ConcessionListParams) => {
    params ??= {} as ConcessionListParams;

    params.sortColumn ??= 'created_date_time';
    params.sortDirection ??= 'desc';
    params.limit ??= 100;
    params.page ??= 1;

    const page = await listConcessions(params);

    set(state => ({
      filter: params,
      concessions: params.page === 1 ? page.collection : state.concessions.concat(page.collection),
      hasNextPage: params.page < page.metadata.last_page
    }));
  },
  fetchFees: async (studentId: string) => {
    const params = {
      page: 1,
      limit: 100,
      studentId,
      statuses: ['Open', 'Overdue', 'PartlyPaid']
    } as StudentFeeParams;

    const page = await listStudentFees(params);
    set({ fees: page.collection });
  },
  createConcession: async (options: ConcessionCreateOptions) => {
    const concessionResult = await createConcession(options);
    set({ concessionResult });
    if (concessionResult.succeeded) {
      const { fetchConcessions, filter } = get();
      fetchConcessions({ ...filter, page: 1 });
    }
    return concessionResult;
  },
  fetchConcession: async (concessionId: string) => {
    const concession = await getConcession(concessionId);
    set({ concession });
  },
  getStudent: async (studentId: string) => {
    const student = await getStudent(studentId);
    set({ student });
  },
  updateConcession: async (options: ConcessionUpdateOptions, concessionId: string) => {
    const concessionResult = await updateConcession(options, concessionId);
    set({ concessionResult });
    if (concessionResult.succeeded) {
      const { fetchConcessions, filter } = get();
      fetchConcessions({ ...filter, page: 1 });
    }
    return concessionResult;
  },
  approveConcession: async (options: ConcessionUpdateOptions, concessionId: string) => {
    const concessionResult = await approveConcession(options, concessionId);
    set({ concessionResult });
    if (concessionResult.succeeded) {
      const { fetchConcessions, filter } = get();
      fetchConcessions({ ...filter, page: 1 });
    }
    return concessionResult;
  },
  editConcession: async (concessionView: ConcessionListView) => {
    const { fetchConcession, getStudent, fetchFees } = get();
    const { concession_id, student_id } = concessionView;
    await Promise.all([fetchConcession(concession_id), fetchFees(student_id), getStudent(student_id)]);
    const { fees, concession } = get();
    const selectedFees = fees.filter(x => concession.fees.some(f => f.student_fee_id == x.student_fee_id));
    set({ selectedFees });
  },
  reset: () => {
    set({
      fees: [],
      concessionResult: null,
      concession: null,
      student: null,
      selectedFees: []
    });
  },
  setSelectedFees: (fees: StudentFeeView[]) => {
    set({ selectedFees: fees });
  },
  deleteConcession: async (concessionId: string) => {
    await deleteConcession(concessionId);
    const { fetchConcessions, filter } = get();
    fetchConcessions({ ...filter, page: 1 });
  },
  cancelConcession: async (options: ConcessionCancelOptions, concessionId: string) => {
    const result = await cancelConcession(options, concessionId);
    set({ cancelResult: result });
    const { fetchConcessions, filter } = get();
    fetchConcessions({ ...filter, page: 1 });
    return result;
  }
}));

export { useConcessionStore };
