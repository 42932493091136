import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import { AcademicPeriod, Program, ProgramOptions } from 'models';
import { Actions, Content, FormField, Head, ToggleButton } from './Elements';
import { Form, Button, Input, Select, RadioGroup, Radio } from 'views/components/elements';
import { FlexRow } from 'views/components/styled';
import { useProgramStore, useTermStore } from 'store';
import { useEffect } from 'react';

type ProgramDialogProps = {
  open: boolean;
  onClose: () => void;
  program?: Program
}

export default function ProgramDialog(props: ProgramDialogProps) {
  const { enableProgram, disableProgram, createProgram, updateProgram, durations } = useProgramStore();
  const { terms, fetchTerms } = useTermStore();
  const isEdit = Boolean(props.program?.program_id);

  useEffect(() => {
    if (!terms.length) {
      fetchTerms();
    }
  }, []);

  const getPeriod = () => {
    if (!isEdit || !props.program.terms.length) {
      return '';
    }

    const term = terms.find(t => t.term_id === props.program.terms[0]);

    return term.period;
  };

  const programForm = {
    code: props.program?.code || '',
    name: props.program?.name || '',
    duration: props.program?.duration || '',
    period: getPeriod(),
    term_count: props.program?.terms?.length || ''
  };

  const handleSubmit = async (values: typeof programForm) => {
    const options = {
      code: values.code,
      name: values.name,
      duration: +values.duration || null,
      terms: []
    } as ProgramOptions;

    if (!options.code || !options.name || !options.duration || !values.period || !values.term_count) {
      return;
    }

    options.terms = terms
      .filter(t => t.period === values.period)
      .slice(0, +values.term_count)
      .map(t => t.term_id);

    if (isEdit) {
      await updateProgram(options, props.program.program_id);
    } else {
      await createProgram(options);
    }

    props.onClose();
  };

  const handleToggle = async () => {
    if (props.program.is_active) {
      await disableProgram(props.program.program_id);
    } else {
      await enableProgram(props.program.program_id);
    }
    props.onClose();
  };

  const getPeriodType = (period: AcademicPeriod | string) => {
    if (period === 'term') {
      return 'Terms';
    }
    if (period === 'semester') {
      return 'Semesters';
    }
    if (period === 'trimester') {
      return 'Trimesters';
    }
    return 'Terms';
  };

  const getTerms = (period: AcademicPeriod | string) => {
    const type = getPeriodType(period);
    const periodTerms = terms
      .filter(t => t.period === period)
      .map((_, index) => ({
        id: index + 1,
        name: `${index + 1} ${type}`
      }));

    if (periodTerms.length) {
      periodTerms[0].name = periodTerms[0].name.substring(0, periodTerms[0].name.length - 1);
    }

    return periodTerms;
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth='xs'
      fullWidth
    >
      <Head>
        <DialogTitle>{isEdit ? 'Edit' : 'Add'} Program</DialogTitle>
        <IconButton onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </Head>
      <Form
        initialValues={programForm}
        onSubmit={handleSubmit}
        render={({ values }) => (
          <>
            <Content>
              <FormField>
                <label>Code*</label>
                <Input name='code' placeholder='Code' />
              </FormField>
              <FormField>
                <label>Name*</label>
                <Input name='name' placeholder='Name' />
              </FormField>
              <FormField>
                <label>Duration*</label>
                <Select name='duration' placeholder='Duration'>
                  {durations.map(duration => (
                    <MenuItem key={duration.id} value={duration.id}>
                      {duration.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormField>
              <FormField>
                <label>Academic Period*</label>
                <RadioGroup name='period' row>
                  <Radio value='term' label='Term' />
                  <Radio value='semester' label='Semester' />
                  <Radio value='trimester' label='Trimester' />
                </RadioGroup>
              </FormField>
              <FormField>
                <label>Number of {getPeriodType(values.period)}*</label>
                <Select name='term_count'>
                  {getTerms(values.period).map(term => (
                    <MenuItem key={term.id} value={term.id}>
                      {term.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormField>
            </Content>
            <Actions>
              <div>
                {isEdit &&
                  <ToggleButton
                    isEdit={isEdit}
                    isActive={props.program?.is_active}
                    onClick={handleToggle}
                  />
                }
              </div>
              <FlexRow size='xs'>
                <Button type='submit'>Save Changes</Button>
                <Button
                  variant='text'
                  color='secondary'
                  onClick={props.onClose}
                >
                  Cancel
                </Button>
              </FlexRow>
            </Actions>
          </>
        )}
      />
    </Dialog>
  );
}
