import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Head, Content, Actions, FormField } from './Elements';
import { useInstituteStore } from 'store';
import {
  Form,
  Select,
  RadioGroup,
  Radio,
  Button,
} from 'views/components/elements';
import { FlexRow } from 'views/components/styled';

type DailyFeeCollectionReportSettingProps = {
  open: boolean;
  onClose: () => void;
};

export default function DailyFeeCollectionReportSetting(
  props: DailyFeeCollectionReportSettingProps
) {
  const { institute, updateInstitute } = useInstituteStore();

  const settings = {
    daily_fee_collection_report_cutoff_time:
      institute?.metadata.daily_fee_collection_report_cutoff_time,
    daily_fee_collection_report_voided_display:
      institute?.metadata.daily_fee_collection_report_voided_display,
  };

  const handleSubmit = async (values: typeof settings) => {
    await updateInstitute({
      financial_year_start: institute.financial_year_start,
      academic_year_start: institute.academic_year_start,
      receipt_year_type: institute.receipt_year_type,
      metadata: values,
      institute_type: institute.institute_type
    });

    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth='sm' fullWidth>
      <Head>
        <DialogTitle>Settings</DialogTitle>
        <IconButton onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </Head>
      <Form
        initialValues={settings}
        onSubmit={handleSubmit}
        render={() => (
          <>
            <Content>
              <FormField>
                <label>Report cutoff time</label>
                <Select name='daily_fee_collection_report_cutoff_time'>
                  {timeValues.map(item => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormField>
              <FormField>
                <label>Cancelled receipt display position</label>
                <RadioGroup name='daily_fee_collection_report_voided_display'>
                  <Radio value='end' label='End' />
                  <Radio value='inline' label='Inline' />
                </RadioGroup>
              </FormField>
            </Content>
            <Actions>
              <div></div>
              <FlexRow>
                <Button type='submit'>Save Changes</Button>
                <Button
                  variant='text'
                  color='secondary'
                  onClick={props.onClose}
                >
                  Cancel
                </Button>
              </FlexRow>
            </Actions>
          </>
        )}
      />
    </Dialog>
  );
}

const timeValues = [
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
];
