import { create } from 'zustand';
import { User } from '../models/User';

interface AccountState {
  isAuthenticated: boolean
  user?: User;
}

interface Actions {
  hasAccess: (behaviorAction: number) => boolean
}

const useAccountStore = create<AccountState & Actions>()((set, get) => ({
  isAuthenticated: false,
  hasAccess: (behaviorAction: number) => {
    const { user } = get();
    return Boolean(user?.permissions.includes(behaviorAction));
  }
}));

const setUser = (user: User) => {
  useAccountStore.setState(() => ({
    user,
    isAuthenticated: true
  }));
};

export { useAccountStore, setUser };
