import { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import { Button, Form, Input, Select, Table } from 'views/components/elements';
import { useStudentFeeStore } from 'store';
import { StudentFeeParams } from 'models';
import { columns } from './Columns';
import { useFeeTypeStore } from 'store/fee-type';
import { Checkbox } from '@mui/material';
import DropDownFilter from 'views/components/elements/DropDownFilter';

const Action = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 16,
  '& form': {
    display: 'flex',
    columnGap: 16,
  },
});

const statuses = [
  { key: 'Open', value: 'Open' },
  { key: 'Overdue', value: 'Overdue' },
  { key: 'PartlyPaid', value: 'Partly Paid' },
  { key: 'Paid', value: 'Paid' },
  { key: 'PartlyRefund', value: 'Partly Refund' },
  { key: 'Refund', value: 'Refund' },
  { key: 'Voided', value: 'Voided' },
  { key: 'Deleted', value: 'Deleted' }
];

function FeesListAdvanced() {
  const { feeTypes, fetchFeeTypes } = useFeeTypeStore();
  const { advanced, fetchFees } = useStudentFeeStore();
  const {
    fees: advancedList,
    filter: advancedFilter,
    hasNextPage: hasNextPageAdvanced,
  } = advanced;
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedFeeTypes, setSelectedFeeTypes] = useState([]);

  useEffect(() => {
    if (!advancedList.length) {
      fetchFees('advanced', {
        statuses: [
          'Open',
          'Overdue',
          'PartlyPaid',
          'Paid',
          'PartlyRefund',
          'Refund',
          'Voided',
        ],
      } as StudentFeeParams);
    }
    if (!feeTypes.length) {
      fetchFeeTypes();
    }
  }, []);

  const handleSort = (field: string, order: string) => {
    const filter = Object.assign({}, advancedFilter, {
      sortColumn: field,
      sortDirection: order,
      page: 1,
    });

    fetchFees('advanced', filter);
  };

  const handleNextPage = () => {
    const filter = Object.assign({}, advancedFilter, {
      page: advancedFilter.page + 1,
    });

    fetchFees('advanced', filter);
  };

  const filter = {
    query: advancedFilter.query || '',
    statuses: advancedFilter.statuses || [],
    feeTypes: advancedFilter.feeTypes || [],
  };

  const handleSubmit = (values: typeof filter) => {
    const newFilter = Object.assign({}, advancedFilter, {
      page: 1,
      query: values.query,
      statuses: selectedStatus,
      feeTypes: selectedFeeTypes,
    });

    fetchFees('advanced', newFilter);
  };

  const handleChange = event => {
    const updatedSelectedFeeTypes = feeTypes
      .filter(feeType => event.target.value.includes(feeType.fee_type_id))
      .map(feeType => feeType.fee_type_id);
    setSelectedFeeTypes(updatedSelectedFeeTypes);
  };

  const handleStatusFilter = (selectedItems: string[]) =>{
    const selectedKeys = selectedItems.map(item => statuses.find(status => status.value === item)?.key || item);
    setSelectedStatus([...selectedKeys]);
    setSelectedValues([...selectedItems]);
  };

  return (
    <>
      <Action>
        <Form
          initialValues={filter}
          onSubmit={handleSubmit}
          validateOnChange={false}
        >
          <Input
            name='query'
            placeholder='Search Fees'
            startAdornment={<SearchIcon />}
          />
          <DropDownFilter
            onChange={handleStatusFilter}
            selectedFields={selectedValues}
            inputFields={statuses.map(res => res.value)}
            label='Statuses'
          />
          <Select
            label='Fee Types'
            name='feeTypes'
            multiple
            placeholder='Fee Type'
            sx={{ width: 250 }}
            value={selectedFeeTypes.map(fee => fee)}
            onChange={handleChange}
            renderValue={(selected: any) =>
              selected.length
                ? selected
                  .map(
                    val =>
                      feeTypes.find(status => status.fee_type_id === val)
                        ?.name
                  )
                  .join(', ')
                : ''
            }
          >
            {feeTypes.map(feeType => (
              <MenuItem key={feeType.fee_type_id} value={feeType.fee_type_id}>
                <Checkbox
                  checked={selectedFeeTypes.some(
                    fee => fee === feeType.fee_type_id
                  )}
                />
                {feeType.name}
              </MenuItem>
            ))}
          </Select>
          <Button type='submit' variant='text'>
            Search
          </Button>
        </Form>
      </Action>
      <Table
        columns={columns}
        rows={advancedList}
        stickyHeader
        onSortChange={handleSort}
        sortingField={advancedFilter.sortColumn}
        sortingOrder={advancedFilter.sortDirection}
        hasMoreRows={hasNextPageAdvanced}
        infiniteScroll
        onNextPage={handleNextPage}
      ></Table>
    </>
  );
}

export default FeesListAdvanced;
