import { Attribute, AttributeCreateOptions, AttributeUpdateOptions } from 'models';
import { getRequest, postRequest, putRequest, sendRequest } from './gateway';

export function getAttribute(attributeId: string) {
  const fetchPromise = getRequest<Attribute>(`/api/v1/attribute/${attributeId}`);

  return sendRequest(fetchPromise);
}

export function createAttribute(attribute: AttributeCreateOptions) {
  const postPromise = postRequest<Attribute>('/api/v1/attribute', attribute);

  return sendRequest(postPromise);
}

export function updateAttribute(attribute: AttributeUpdateOptions, attributeId: string) {
  const putPromise = putRequest<Attribute>(`/api/v1/attribute/${attributeId}`, attribute);

  return sendRequest(putPromise);
}

export function listAttributes() {
  const fetchPromise = getRequest<Attribute[]>('/api/v1/attribute/list');

  return sendRequest(fetchPromise);
}

export function disableAttribute(attributeId: string) {
  const putPromise = putRequest<Attribute>(`/api/v1/attribute/${attributeId}/disable`, null);
  return sendRequest(putPromise);
}

export function enableAttribute(attributeId: string) {
  const putPromise = putRequest<Attribute>(`/api/v1/attribute/${attributeId}/enable`, null);
  return sendRequest(putPromise);
}
