import { create } from 'zustand';
import { StudentFeeView, StudentFeeParams } from '../models/StudentFee';
import { FileDetail } from 'models';
import { deleteStudentFees, finalizeStudentFees, importStudentFees, listStudentFees, voidStudentFees } from 'gateways/student-fee';

interface FeeViewCategory {
  filter: StudentFeeParams
  fees: StudentFeeView[]
  hasNextPage: boolean
  selected: string[]
}

type State = {
  draft: FeeViewCategory
  pending: FeeViewCategory
  completed: FeeViewCategory
  advanced: FeeViewCategory
}

type StudentFeeStateKeys = keyof State;

type Action = {
  importFees: (file: FileDetail) => Promise<void>
  fetchFees: (category: StudentFeeStateKeys, params?: StudentFeeParams) => Promise<void>
  selectFees: (category: StudentFeeStateKeys, fees: string[]) => void
  finalizeFees: (fees: string[]) => Promise<void>
  deleteFees: (fees: string[]) => Promise<void>
  voidFees: (fees: string[]) => Promise<void>
}

const emptyFeeViewCategory = {
  filter: {},
  fees: [],
  hasNextPage: false,
  selected: []
} as FeeViewCategory;

const useStudentFeeStore = create<State & Action>()((set, get) => ({
  draft: emptyFeeViewCategory,
  pending: emptyFeeViewCategory,
  completed: emptyFeeViewCategory,
  advanced: emptyFeeViewCategory,
  importFees: async (file: FileDetail) => {
    await importStudentFees(file.location);

    const { draft, fetchFees } = get();

    await fetchFees('draft', { ...draft.filter, page: 1 });
  },
  fetchFees: async (category: StudentFeeStateKeys, params?: StudentFeeParams) => {
    params ??= {} as StudentFeeParams;

    params.sortColumn ??= 'created_date_time';
    params.sortDirection ??= 'desc';
    params.limit ??= 100;
    params.page ??= 1;

    const page = await listStudentFees(params);

    set(state => {
      const data = {
        filter: params,
        hasNextPage: params.page < page.metadata.last_page,
        selected: [],
        fees: params.page === 1 ? page.collection : state[category].fees.concat(page.collection)
      } as FeeViewCategory;

      return { [category]: data };
    });
  },
  selectFees: (category: StudentFeeStateKeys, fees: string[]) => set(state => ({
    [category]: { ...state[category], selected: fees }
  })),
  finalizeFees: async (fees: string[]) => {
    await finalizeStudentFees({ fees });

    const { draft, fetchFees, selectFees } = get();

    await fetchFees('draft', { ...draft.filter, page: 1 });
    selectFees('draft', []);
  },
  deleteFees: async (fees: string[]) => {
    await deleteStudentFees({ fees });

    const { draft, fetchFees, selectFees } = get();

    await fetchFees('draft', { ...draft.filter, page: 1 });
    selectFees('draft', []);
  },
  voidFees: async (fees: string[]) => {
    await voidStudentFees({ fees });
  }
}));

export { useStudentFeeStore };
