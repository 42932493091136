import { DataGrid as Grid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';

export const DataGrid = styled(Grid)({
  borderRadius: 10,
  borderColor: 'rgb(238, 239, 242)',
  '.MuiDataGrid-columnHeaders': {
    backgroundColor: 'rgb(246, 249, 251)'
  },
  '.MuiDataGrid-columnHeaderTitle': {
    fontSize: 12,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  '.MuiDataGrid-withBorderColor': {
    borderColor: 'rgb(238, 239, 242, 0.5)',
  },
  '.MuiDataGrid-cell': {
    fontSize: 12,
  },
  '.MuiDataGrid-row': {
    cursor: 'pointer',
    '&:nth-of-type(even)': {
      backgroundColor: 'rgb(246, 249, 251)'
    },
    '&:hover': {
      backgroundColor: '#e5eef3'
    }
  },
});
