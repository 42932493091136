import EditIcon from '@mui/icons-material/Edit';
import UndoIcon from '@mui/icons-material/Undo';
import DeleteIcon from '@mui/icons-material/Delete';
import ApprovalIcon from '@mui/icons-material/Approval';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import { Button, Form, Input, Column, Table } from 'views/components/elements';
import { Action, FlexRow, ScrollablePage } from 'views/components/styled';
import { useAccountStore, useConcessionStore } from 'store';
import { useEffect, useState } from 'react';
import { ConcessionListView } from 'models';
import { ConcessionCancelDialog, ConcessionDialog } from 'views/dialogs';
import { ConcessionEditorMode } from 'views/dialogs/Concession';
import AlertNotifier from 'views/components/elements/AlertNotifier';
import DropDownFilter from 'views/components/elements/DropDownFilter';
import { BEHAVIOR_ACTIONS } from 'views/shared/utils/validation-utils';

const statuses = [
  { key: 'Draft', value: 'Draft' },
  { key: 'Approved', value: 'Approved' },
  { key: 'Cancelled', value: 'Cancelled' },
  { key: 'Deleted', value: 'Deleted' },
];

const ConcessionList = () => {
  const {
    concessions,
    filter,
    fetchConcessions,
    hasNextPage,
    editConcession,
    reset,
    deleteConcession,
  } = useConcessionStore();
  const [editorOpen, setEditorOpen] = useState(false);
  const [editorMode, setEditorMode] = useState<ConcessionEditorMode>('Add');
  const [confirmCancelOpen, setConfirmCancelOpen] = useState(false);
  const [cancelConcession, setCancelConcession] =
    useState<ConcessionListView>();
  const [selectedValues, setSelectedValues] = useState([]);
  const [alert, setAlert] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const { hasAccess } = useAccountStore();
  const hasConcessionCreateAccess = hasAccess(BEHAVIOR_ACTIONS.ConcessionCreate);
  const hasConcessionUpdateAccess = hasAccess(BEHAVIOR_ACTIONS.ConcessionUpdate);
  const hasConcessionDeleteAccess = hasAccess(BEHAVIOR_ACTIONS.ConcessionDelete);
  const hasConcessionApproveAccess = hasAccess(BEHAVIOR_ACTIONS.ConcessionApprove);
  const hasConcessionCancelAccess = hasAccess(BEHAVIOR_ACTIONS.ConcessionCancel);


  useEffect(() => {
    if (!concessions.length) {
      fetchConcessions();
    }
  }, []);

  const RowActions = (props: ConcessionListView) => {
    if (props.concession_status === 'Draft') {
      return (
        <FlexRow size='xxs'>
          <Tooltip title='edit'>
            <IconButton disabled={!hasConcessionUpdateAccess} onClick={editHandler(props)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title='approval'>
            <IconButton disabled={!hasConcessionApproveAccess} onClick={approvalHandler(props)}>
              <ApprovalIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title='delete'>
            <IconButton disabled={!hasConcessionDeleteAccess} onClick={deleteHandler(props)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </FlexRow>
      );
    }

    if (props.concession_status === 'Approved') {
      return (
        <Tooltip title='cancel'>
          <IconButton disabled={!hasConcessionCancelAccess} onClick={cancelHandler(props)}>
            <UndoIcon />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const columns = [
    { field: 'roll_number', label: 'roll No.', width: 120, sortable: true },
    {
      field: 'first_name',
      label: 'name',
      sortable: true,
      valueGetter: row => `${row.first_name} ${row.last_name}`,
    },
    {
      field: 'concession_amount',
      label: 'amount',
      format: 'currency',
      width: 150,
    },
    { field: 'notes', label: 'notes', width: 200, wrapMoreText: 50 },
    { field: 'concession_status', label: 'status', width: 120 },
    {
      field: 'created_date_time',
      label: 'created',
      format: 'datetime',
      width: 180,
      sortable: true,
    },
    {
      field: 'action',
      valueGetter: row => <RowActions {...row} />,
      width: 180,
    },
  ] as Column<ConcessionListView>[];

  const editHandler = (concession: ConcessionListView) => async () => {
    await editConcession(concession);
    setEditorMode('Edit');
    setEditorOpen(true);
  };

  const deleteHandler = (concession: ConcessionListView) => async () => {
    await deleteConcession(concession.concession_id);
  };

  const cancelHandler = (concession: ConcessionListView) => async () => {
    setCancelConcession(concession);
    setConfirmCancelOpen(true);
  };

  const approvalHandler = (concession: ConcessionListView) => async () => {
    await editConcession(concession);
    setEditorMode('Review');
    setEditorOpen(true);
  };

  const handleSort = (field: string, order: string) => {
    const newFilter = Object.assign({}, filter, {
      sortColumn: field,
      sortDirection: order,
      page: 1,
    });

    fetchConcessions(newFilter);
  };

  const handleNextPage = () => {
    const newFilter = Object.assign({}, filter, {
      page: filter.page + 1,
    });

    fetchConcessions(newFilter);
  };

  const handleSubmit = values => {
    const newFilter = Object.assign({}, filter, {
      page: 1,
      query: values.query,
      statuses: selectedValues,
    });

    fetchConcessions(newFilter);
  };

  const handleAdd = () => {
    reset();
    setEditorMode('Add');
    setEditorOpen(true);
  };

  const handleClose = () => {
    setEditorOpen(false);
  };
  
  const handleConfirmCancelClose = () => {
    setConfirmCancelOpen(false);
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  const filterValues = {
    query: filter.query || '',
    statuses: filter.statuses || [],
  };

  const handleStatusFilter = (selectedItems: string[]) =>
    setSelectedValues([...selectedItems]);

  return (
    <ScrollablePage>
      <h1>Concessions</h1>
      <Action>
        <Form
          initialValues={filterValues}
          onSubmit={handleSubmit}
          validateOnChange={false}
        >
          <Input
            name='query'
            placeholder='Search Concessions'
            startAdornment={<SearchIcon />}
          />
          <DropDownFilter
            onChange={handleStatusFilter}
            selectedFields={selectedValues}
            inputFields={statuses.map(res => res.key)}
            label='Statuses'
          />
          <Button type='submit' variant='text'>
            Search
          </Button>
        </Form>
        <div>
          <Button startIcon={<AddCircleOutlineIcon />} onClick={handleAdd} disabled={!hasConcessionCreateAccess}>
            Add Concession
          </Button>
        </div>
      </Action>
      <Table
        rows={concessions}
        columns={columns}
        stickyHeader
        infiniteScroll
        hasMoreRows={hasNextPage}
        sortingField={filter.sortColumn}
        sortingOrder={filter.sortDirection}
        onSortChange={handleSort}
        onNextPage={handleNextPage}
      />
      <ConcessionDialog
        open={editorOpen}
        onClose={handleClose}
        mode={editorMode}
        setAlertOpen = {setAlertOpen}
        setAlert = {setAlert}
      />
      {alert && <AlertNotifier {...alert}open={alertOpen} onClose={handleCloseAlert} />}
      <ConcessionCancelDialog
        open={confirmCancelOpen}
        onClose={handleConfirmCancelClose}
        concession={cancelConcession}
      />
    </ScrollablePage>
  );
};

export default ConcessionList;
