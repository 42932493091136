import { FeeType, FeeTypeOptions } from 'models';
import { sendRequest, getRequest, postRequest, putRequest, deleteRequest } from './gateway';

function getFeeType(feeTypeId: string) {
  const fetchPromise = getRequest<FeeType>(`/api/v1/fee-type/${feeTypeId}`);

  return sendRequest(fetchPromise);
}

function createFeeType(feeType: FeeTypeOptions) {
  const postPromise = postRequest<FeeType>('/api/v1/fee-type', feeType);

  return sendRequest(postPromise);
}

function updateFeeType(feeType: FeeTypeOptions, feeTypeId: string) {
  const putPromise = putRequest<FeeType>(`/api/v1/fee-type/${feeTypeId}`, feeType);

  return sendRequest(putPromise);
}

function listFeeTypes() {
  const fetchPromise = getRequest<FeeType[]>('/api/v1/fee-type/list');

  return sendRequest(fetchPromise);
}

function deleteFeeType(feeTypeId: string) {
  const fetchPromise = deleteRequest<void>(`/api/v1/fee-type/${feeTypeId}`);

  return sendRequest(fetchPromise);
}

function enableFeeType(feeTypeId: string) {
  const putPromise = putRequest(`/api/v1/fee-type/${feeTypeId}/enable`,null);
  return sendRequest(putPromise);
}

function disableFeeType(feeTypeId: string) {
  const putPromise = putRequest(`/api/v1/fee-type/${feeTypeId}/disable`,null);
  return sendRequest(putPromise);
}

export { getFeeType, createFeeType, updateFeeType, listFeeTypes, deleteFeeType, enableFeeType, disableFeeType };
