import { styled } from '@mui/material/styles';
import { Form } from '../elements';

export const Head = styled('h1')({
  margin: '32px 0',
});

export const Active = styled('span')(({ theme }) => ({
  color: theme.palette.success.dark,
  padding: '6px 16px',
  borderRadius: '100px',
  backgroundColor: '#defff0',
}));

export const Inactive = styled('span')(({ theme }) => ({
  color: theme.palette.error.dark,
  padding: '6px 16px',
  borderRadius: '100px',
  backgroundColor: '#ffebeb',
}));

export const FilterForm = styled(Form)({
  display: 'flex',
  columnGap: 24,
});
