import { getFile } from 'gateways/files';
import { ReceiptBookDto } from 'models';
import { create } from 'zustand';

type State = {
  book?: ReceiptBookDto,
  reportDate?: string
}

type Action = {
  fetchBook: (receiptBookId: string) => Promise<void>
}

const useReceiptBookStore = create<State & Action>()((set) => ({
  fetchBook: async (reportDate: string) => {
    const date = new Date(reportDate);
    const path = `reports/daily_fee_collection_report/${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}.json`;
    const book = await getFile<ReceiptBookDto>(path);
    set(() => ({ book, reportDate }));
  }
}));

export { useReceiptBookStore };
