import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { LateCharge } from 'models';
import { useEffect, useState } from 'react';
import { Table, Column, Button, Input, Radio, RadioGroup } from 'views/components/elements';
import EditIcon from '@mui/icons-material/Edit';
import { Active, FilterForm, Head, Inactive } from 'views/components/styled';
import LateChargeDialog from 'views/dialogs/LateCharge';
import { useAccountStore, useLateChargeStore } from 'store';
import { BEHAVIOR_ACTIONS } from 'views/shared/utils/validation-utils';

function LateChargeList() {
  const [editorOpen, setEditorOpen] = useState(false);
  const [filter, setFilter] = useState({ query: '', status: 'All' });
  const columns = [
    { field: 'name', label: 'name' },
    { field: 'min_charge', label: 'min charge' },
    { field: 'max_charge', label: 'max charge' },
    { field: 'grace_days', label: 'grace date' },
    {
      field: 'is_active',
      label: 'status',
      valueGetter: row =>
        row.is_active ? <Active>Active</Active> : <Inactive>Inactive</Inactive>,
      width: 120,
    },
    {
      field: 'action',
      label: '',
      valueGetter: row => (
        <IconButton disabled={!hasLateChargeUpdateAccess} onClick={() => editHandler(row)}>
          <EditIcon />
        </IconButton>
      ),
      width: 80,
    },
  ] as Column<LateCharge>[];

  const {
    lateCharges,
    lateCharge,
    fetchLateCharge,
    fetchLateCharges,
    resetLateCharge,
  } = useLateChargeStore();

  useEffect(() => {
    if (!lateCharges.length) {
      fetchLateCharges();
    }
  }, []);

  const handleClose = () => {
    setEditorOpen(false);
  };

  const editHandler = async (lateCharge: LateCharge) => {
    await fetchLateCharge(lateCharge.late_charge_id);
    setEditorOpen(true);
  };

  const handleAdd = () => {
    resetLateCharge();
    setEditorOpen(true);
  };

  const handleSubmit = (values: typeof filter) => {
    setFilter(values);
  };

  const rows = lateCharges.filter(l => {
    return (
      (!filter.query ||
        l.name.toLowerCase().includes(filter.query.toLowerCase())) &&
      (filter.status === 'All' || l.is_active === (filter.status === 'Active'))
    );
  });

  const { hasAccess } = useAccountStore();
  const hasLateChargeCreateAccess = hasAccess(BEHAVIOR_ACTIONS.LateChargeCreate);
  const hasLateChargeUpdateAccess = hasAccess(BEHAVIOR_ACTIONS.LateChargeUpdate);

  return (
    <>
      <Head>Fine Rules</Head>
      <div className='row head'>
        <FilterForm initialValues={filter} onSubmit={handleSubmit}>
          <Input
            name='query'
            placeholder='Search Fine Rules'
            startAdornment={<SearchIcon />}
          />
          <RadioGroup name='status' row>
            <Radio value='Active' label='Active' />
            <Radio value='Inactive' label='Inactive' />
            <Radio value='All' label='All' />
          </RadioGroup>
          <Button type='submit' variant='text'>
            Search
          </Button>
        </FilterForm>
        <Button disabled={!hasLateChargeCreateAccess} startIcon={<AddCircleOutlineIcon />} onClick={handleAdd}>
          Add Fine Rule
        </Button>
      </div>
      <Table rows={rows} columns={columns} />
      <LateChargeDialog
        open={editorOpen}
        onClose={handleClose}
        lateCharge={lateCharge}
      />
    </>
  );
}

export default LateChargeList;
