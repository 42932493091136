import { createFeeType, deleteFeeType, getFeeType, listFeeTypes, updateFeeType, enableFeeType, disableFeeType } from 'gateways/fee-type';
import { FeeType, FeeTypeOptions } from 'models';
import { create } from 'zustand';

type State = {
  feeTypes: FeeType[]
  feeType?: FeeType,
  selected?: string[]
}

type Action = {
  fetchFeeTypes: () => Promise<void>
  createFeeType: (options: FeeTypeOptions) => Promise<void>
  updateFeeType: (options: FeeTypeOptions, feeTypeId: string) => Promise<void>
  deleteFeeType: (feeTypeId: string) => Promise<void>
  fetchFeeType: (feeTypeId: string) => Promise<void>,
  enableFeeType: (feeTypeId: string) => Promise<void>
  disableFeeType: (feeTypeId: string) => Promise<void>
  resetFeeType: () => void,
  selectFeesTypes: (feeTypes: string[]) => void
}

const useFeeTypeStore = create<State & Action>()((set, get) => ({
  feeTypes: [],
  fetchFeeTypes: async () => {
    const feeTypes = await listFeeTypes();
    set(() => ({ feeTypes }));
  },
  createFeeType: async (options: FeeTypeOptions) => {
    const feeType = await createFeeType(options);
    set(state => ({
      feeTypes: state.feeTypes.slice().concat(feeType),
      feeType: feeType
    }));
  },
  updateFeeType: async (options: FeeTypeOptions, feeTypeId: string) => {
    const feeType = await updateFeeType(options, feeTypeId);

    const feeTypes = get().feeTypes.slice();
    feeTypes.splice(feeTypes.findIndex(x => x.fee_type_id === feeTypeId), 1, feeType);

    set(() => ({ feeTypes, feeType }));
  },
  deleteFeeType: async (feeTypeId: string) => {
    await deleteFeeType(feeTypeId);

    const feeTypes = get().feeTypes.slice();
    feeTypes.splice(feeTypes.findIndex(x => x.fee_type_id === feeTypeId), 1);

    set(() => ({ feeTypes, feeType: null }));
  },
  fetchFeeType: async (feeTypeId: string) => {
    const feeType = await getFeeType(feeTypeId);
    set(() => ({ feeType }));
  },
  enableFeeType: async (feeTypeId: string) => {
    await enableFeeType(feeTypeId);
  },
  disableFeeType: async (feeTypeId: string) => {
    await disableFeeType(feeTypeId);
  },
  resetFeeType: () => set(() => ({ feeType: null })),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectFeesTypes: (feeRules: string[]) => set(state => ({
    selected:  feeRules 
  }))
}));

export { useFeeTypeStore };
