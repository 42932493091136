import { disableGradeLevel, enableGradeLevel, listGradeLevels } from 'gateways/grade-level';
import { GradeLevel, GradeLevelOptions } from 'models';
import { create } from 'zustand';

type State = {
  gradeLevels: GradeLevel[]
}

type Action = {
  fetchGradeLevels: () => Promise<void>
  enableGradeLevel: (gradeLevel: GradeLevelOptions, gradeLevelId: number) => Promise<void>
  disableGradeLevel: (gradeLevelId: number) => Promise<void>
}

export const useGradeLevelStore = create<State & Action>()((set, get) => ({
  gradeLevels: [],
  fetchGradeLevels: async () => {
    const gradeLevels = await listGradeLevels();
    set({ gradeLevels });
  },
  enableGradeLevel: async (options: GradeLevelOptions, gradeLevelId: number) => {
    await enableGradeLevel(options, gradeLevelId);
    const gradeLevels = get().gradeLevels.slice();
    const index = gradeLevels.findIndex(gl => gl.grade_level_id === gradeLevelId);
    const gradeLevel = { ...gradeLevels.at(index), is_active: true, terms: options.terms } as GradeLevel;
    gradeLevels.splice(index, 1, gradeLevel);
    set({ gradeLevels });
  },
  disableGradeLevel: async (gradeLevelId: number) => {
    await disableGradeLevel(gradeLevelId);
    const gradeLevels = get().gradeLevels.slice();
    const index = gradeLevels.findIndex(gl => gl.grade_level_id === gradeLevelId);
    const gradeLevel = { ...gradeLevels.at(index), is_active: false, terms: [] } as GradeLevel;
    gradeLevels.splice(index, 1, gradeLevel);
    set({ gradeLevels });
  }
}));
