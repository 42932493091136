import { Popover, styled, CardContent, Divider, Paper } from '@mui/material';
import { Attribute, Student } from 'models';
import {
  AmountLabel,
  Avatar,
  FlexColumn,
  FlexRow,
  Title,
} from '../styled';
import { currencyFormat, getAvatarText } from 'views/shared/utils/string-utils';
import { useAttributeStore } from 'store';

type PopoverProps = {
  open?: boolean;
  anchorEl?: any;
  onClose?: any;
  student?: Student;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

const StyledPopover = styled(Popover)({
  '.MuiPaper-root': {
    minWidth: '280px',
    maxWidth: 'none',
    width: 'auto',
  },
});

const ContainerDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const InsightsWrapper = styled('div')({
  overflowY: 'auto',
  padding: '10px 16px',
  maxHeight: 'calc(100vh - 120px)',
  fontSize: 14
});

const StyledKey = styled('div')({
  opacity: 0.7,
  textTransform: 'capitalize',
  padding: '0px !important',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
});

const StyledMetadataContainer = styled('div')({
  top: 0,
  backgroundColor: '#fff',
  zIndex: 2,
  fontSize: 14,
  padding: 0
});

const Amount = styled(AmountLabel)({
  height: 'unset'
});

const InsightsSection = (props: Student) => {
  const {
    total_amount,
    concession_amount,
    scholarship_amount,
    amount_capturable,
  } = props;
  const paidAmount =
    (props.captured_legacy ?? 0) +
    (props.captured_offline ?? 0) +
    (props.captured_online ?? 0);

  return (
    <FlexColumn size='xs'>
      <Title>Insights</Title>
      <FlexRow>
        <StyledKey>Total Fees</StyledKey>
        <Amount>{currencyFormat(total_amount)}</Amount>
      </FlexRow>
      <FlexRow>
        <StyledKey>Concession</StyledKey>
        <Amount>{currencyFormat(concession_amount)}</Amount>
      </FlexRow>
      <FlexRow>
        <StyledKey>Scholarship</StyledKey>
        <Amount>{currencyFormat(scholarship_amount)}</Amount>
      </FlexRow>
      <FlexRow>
        <StyledKey>Fees Paid</StyledKey>
        <Amount>{currencyFormat(paidAmount)}</Amount>
      </FlexRow>
      <FlexRow>
        <StyledKey>Balance</StyledKey>
        <Amount>{currencyFormat(amount_capturable)}</Amount>
      </FlexRow>
    </FlexColumn>
  );
};

function StudentMetadata(student?: Student) {
  const { attributes } = useAttributeStore();

  if (!student) {
    return <></>;
  }

  const avatarText = getAvatarText(
    `${student.first_name} ${student.last_name}`
  );

  const keys = Object.keys(student.metadata);

  return (
    <StyledMetadataContainer>
      <CardContent style={{ paddingBottom: '16px' }}>
        <FlexRow style={{ alignItems: 'center', gap: '10px', marginBottom: '16px' }}>
          <Avatar>{avatarText}</Avatar>
          <FlexColumn size='xs'>
            <Title>
              {student.first_name} {student.last_name}
            </Title>
            <span>{student.roll_number}</span>
          </FlexColumn>
        </FlexRow>
        <FlexColumn size='xs'>
          {student.grade_level_name && (
            <FlexRow>
              <StyledKey>
                Grade Level
              </StyledKey>
              <div>
                {student.grade_level_name}
              </div>
            </FlexRow>
          )}
          {student.program_name && (
            <FlexRow>
              <StyledKey>
                Program
              </StyledKey>
              <div>
                {student.program_name}
              </div>
            </FlexRow>
          )}
          <FlexRow>
            <StyledKey>
              Term
            </StyledKey>
            <div>
              {student.term_name}
            </div>
          </FlexRow>
          {keys.map(key => (
            <AttributeRow
              key={key}
              student={student}
              attributeCode={key}
              attributes={attributes}
            />
          ))}
        </FlexColumn>
      </CardContent>
    </StyledMetadataContainer>
  );
}

interface AttributeRowProps {
  student: Student
  attributes: Attribute[]
  attributeCode: string
}

function AttributeRow({ student, attributes, attributeCode }: AttributeRowProps) {
  const attribute = attributes?.find(a =>
    a.code === attributeCode
    && a.usages.some(u => u.category === 'Student')
    && a.is_active
  );
  const label = attribute?.label || attributeCode;
  let value = student.metadata[attributeCode];
  if (attribute?.input_type === 'List') {
    value = attribute.values?.find(v => v.value === value)?.label || value;
  }

  return (
    <FlexRow>
      <StyledKey>
        {label}
      </StyledKey>
      <div>
        {value}
      </div>
    </FlexRow>
  );
}

const AttributesPopover = (props: PopoverProps) => {
  return (
    <StyledPopover
      id='mouse-over-popover'
      sx={{ pointerEvents: 'none' }}
      open={props.open}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={props.onClose}
      disableRestoreFocus
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <Paper sx={{ pointerEvents: 'auto', boxShadow: 'none' }}>
        <ContainerDiv>
          <StudentMetadata {...props.student} />
          <Divider style={{ borderWidth: '1px' }} />
          <InsightsWrapper>
            <InsightsSection {...props.student} />
          </InsightsWrapper>
        </ContainerDiv>
      </Paper>
    </StyledPopover>
  );
};

export default AttributesPopover;
