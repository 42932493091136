import { RouterProvider } from 'react-router-dom';
import { RouterConfig } from './routes';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={RouterConfig()} />
    </ThemeProvider>
  );
};

export default App;
