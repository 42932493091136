import { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import { Attribute } from 'models';
import { useAccountStore, useAttributeStore } from 'store';
import {
  Table,
  Column,
  Input,
  RadioGroup,
  Radio,
  Button,
} from 'views/components/elements';
import { Active, Head, Inactive, FilterForm } from 'views/components/styled';
import AttributeDialog from 'views/dialogs/Attribute';
import { BEHAVIOR_ACTIONS } from 'views/shared/utils/validation-utils';

function AttributeList() {
  const columns = [
    { field: 'name', label: 'name' },
    { field: 'input_type', label: 'input type' },
    { field: 'data_type', label: 'data type' },
    { field: 'created_date_time', label: 'created', format: 'date', width: 120 },
    {
      field: 'is_active',
      label: 'status',
      valueGetter: row =>
        row.is_active ? <Active>Active</Active> : <Inactive>Inactive</Inactive>,
      width: 120,
    },
    {
      field: 'action',
      label: '',
      valueGetter: row => (
        <IconButton onClick={createEditHandler(row)} disabled={!hasAttributeEditAccess}>
          <EditIcon />
        </IconButton>
      ),
      width: 80,
    }
  ] as Column<Attribute>[];

  const [filter, setFilter] = useState({ query: '', status: 'All' });
  const [editorOpen, setEditorOpen] = useState(false);
  const { attributes, attribute, fetchAttribute, resetAttribute, fetchAttributes } = useAttributeStore();
  const { hasAccess } = useAccountStore();
  const hasAttributeWriteAccess = hasAccess(BEHAVIOR_ACTIONS.AttributeCreate);
  const hasAttributeEditAccess = hasAccess(BEHAVIOR_ACTIONS.AttributeUpdate);

  useEffect(() => {
    if (!attributes.length) {
      fetchAttributes();
    }
  }, []);

  const createEditHandler = (attribute: Attribute) => async () => {
    await fetchAttribute(attribute.attribute_id);
    setEditorOpen(true);
  };

  const handleAdd = () => {
    resetAttribute();
    setEditorOpen(true);
  };

  const handleClose = () => {
    setEditorOpen(false);
  };

  const handleSubmit = (values: typeof filter) => {
    setFilter(values);
  };

  const rows = attributes.filter(a => {
    return (
      (!filter.query ||
        a.name.toLowerCase().includes(filter.query.toLowerCase())) &&
      (filter.status === 'All' || a.is_active === (filter.status === 'Active'))
    );
  });

  return (
    <>
      <Head>Attributes</Head>
      <div className='row head'>
        <FilterForm initialValues={filter} onSubmit={handleSubmit}>
          <Input
            name='query'
            placeholder='Search Attributes'
            startAdornment={<SearchIcon />}
          />
          <RadioGroup name='status' row>
            <Radio value='Active' label='Active' />
            <Radio value='Inactive' label='Inactive' />
            <Radio value='All' label='All' />
          </RadioGroup>
          <Button type='submit' variant='text'>
            Search
          </Button>
        </FilterForm>
        <Button startIcon={<AddCircleOutlineIcon />} onClick={handleAdd} disabled={!hasAttributeWriteAccess}>
          Add Attribute
        </Button>
      </div>
      <Table rows={rows} columns={columns} />
      <AttributeDialog
        open={editorOpen}
        onClose={handleClose}
        attribute={attribute}
      />
    </>
  );
}

export default AttributeList;
