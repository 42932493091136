import { styled } from '@mui/material/styles';
import { useStudentStore } from 'store/student';

const Key = styled('td')({
  fontSize: 14,
  fontWeight: 600,
  paddingRight: 8,
  paddingBottom: 8
});

const Value = styled('td')({
  fontSize: 14,
  paddingBottom: 8
});

const StudentMetaData = () => {
  const { student } = useStudentStore();

  const metadata = student?.metadata || {};

  return (
    <div className='metaData'>
      <table>
        {Object.keys(metadata).map(key => (
          <tr key={key}>
            <Key>{key}</Key>
            <Value>{metadata[key]}</Value>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default StudentMetaData;
