import { SyntheticEvent, useState, useMemo, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { debounce } from '@mui/material';
import Alert from '@mui/material/Alert';
import { Head } from './Elements';
import { StudentFeeView, Student } from 'models';
import { useConcessionStore, useReceiptStore } from 'store';
import { Form, Table, Column, Input, Textarea, Button } from 'views/components/elements';
import { FlexColumn, FlexRow, FormField } from 'views/components/styled';
import { currencyFormat } from 'views/shared/utils/string-utils';
import StudentHeader from 'views/components/StudentHeader';

const AmountInput = styled(Input)({
  '& .MuiInputBase-input': {
    textAlign: 'right',
  },
});

const AmountLabel = styled('label')({
  height: 42,
  display: 'flex',
  alignItems: 'center',
  fontWeight: 600,
});

export type ConcessionEditorMode = 'Add' | 'Edit' | 'Review'

type ConcessionProps = {
  open: boolean
  onClose: () => void
  mode: ConcessionEditorMode
  setAlertOpen: (value:boolean) => void
  setAlert: (value:{message:string, severity:string}) => void
}

type StudentAutocompleteProps = {
  onChange: (student?: Student) => void
}

const StudentAutocomplete = (props: StudentAutocompleteProps) => {
  const [options, setOptions] = useState<Student[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState<Student>(null);
  const [isLoading,setIsLoading] = useState(false);

  const { searchStudents } = useReceiptStore();

  const fetchOptions = useMemo(
    () =>
      debounce(
        async (query: string, onResults: (results?: Student[]) => void) => {
          setIsLoading(true);
          const students = await searchStudents(query);
          setIsLoading(false);
          onResults(students);
        },
        400
      ),
    []
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetchOptions(inputValue, (results?: Student[]) => {
      if (active) {
        let newOptions: Student[] = [];

        if (results) {
          newOptions = [...results];
        }

        if (value && !newOptions.some(x => x.roll_number === value.roll_number)) {
          newOptions = [value, ...newOptions];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetchOptions]);

  const handleInputChange = (_event: SyntheticEvent, value: string) => {
    setInputValue(value);
  };

  const handleChange = (_event: SyntheticEvent, value?: Student) => {
    setOptions(value ? [value, ...options] : options);
    setValue(value);
    props.onChange(value);
  };

  const handleOptionEqualToValueCheck = (option: Student, value: Student) => {
    return option.roll_number == value.roll_number;
  };

  return (
    <Autocomplete
      autoComplete
      includeInputInList
      noOptionsText={isLoading ? 'Loading...' : 'No matching student'}
      filterSelectedOptions
      filterOptions={x => x}
      options={options}
      getOptionLabel={option => option.roll_number}
      value={value}
      onInputChange={handleInputChange}
      onChange={handleChange}
      isOptionEqualToValue={handleOptionEqualToValueCheck}
      renderInput={params => (
        <TextField {...params} label='Choose a student' fullWidth />
      )}
      renderOption={(props, option) => (
        <li {...props}>
          {option.first_name} {option.last_name} {option.roll_number}
        </li>
      )}
    />
  );
};

const columns = [
  { field: 'fee_type', label: 'fees', valueGetter: row => `${row.fee_type} - term ${row.term_number}` },
  { field: 'amount', label: 'amount', format: 'currency', valueGetter: row => row.amount + row.late_amount ?? 0, width: 120 },
  { field: 'concession_amount', label: 'concession', format: 'currency', width: 120 },
  { field: 'scholarship_amount', label: 'scholarship', format: 'currency', width: 120 },
  { field: 'captured', label: 'paid', format: 'currency', valueGetter: row => row.captured_legacy ?? 0 + row.captured_offline ?? 0 + row.captured_online ?? 0, width: 120 },
  {
    field: 'amount_capturable',
    label: 'balance',
    format: 'currency',
    width: 120
  },
  {
    field: 'new_concession_amount',
    label: 'add concession',
    valueGetter: (_row, index) => (
      <AmountInput
        name={`fees[${index}].amount`}
        type='number'
        onClick={e => e.stopPropagation()}
      />
    ),
    format: 'currency',
    width: 150
  },
] as Column<StudentFeeView>[];

const ConcessionDialog = (props: ConcessionProps) => {
  const {
    fees,
    fetchFees,
    createConcession,
    concessionResult,
    student,
    getStudent,
    concession,
    updateConcession,
    reset,
    selectedFees,
    setSelectedFees,
    approveConcession
  } = useConcessionStore();

  const getAmount = (fee: StudentFeeView) => {
    if (props.mode === 'Add') {
      return null;
    }

    return concession?.fees?.find(x => x.student_fee_id == fee.student_fee_id)?.amount || 0;
  };

  const concessionForm = {
    fees: fees.map(fee => ({
      student_fee_id: fee.student_fee_id,
      amount: getAmount(fee)
    })),
    notes: concession?.notes || ''
  };

  const getTotalAmount = (items: typeof concessionForm.fees) =>
    currencyFormat(
      items
        .filter(x => selectedFees.some(s => s.student_fee_id === x.student_fee_id))
        .reduce((total, item) => total + item.amount, 0)
    );

  const handleSelectionChange = (rows: StudentFeeView[]) => setSelectedFees(rows);

  const handleStudentChange = (student?: Student) => {
    reset();
    if (student) {
      fetchFees(student.student_id);
      getStudent(student.student_id);
    }
  };

  const hasStudentFees = Boolean(fees.length);
  const hasStudent = Boolean(student);
  const hasErrors = Boolean(concessionResult?.errors?.length);
  const isAdd = Boolean(props.mode == 'Add');
  const isReview = Boolean(props.mode == 'Review');

  const handleSubmit = async (values: typeof concessionForm) => {
    const fees = values.fees.filter(s => selectedFees.some(x => x.student_fee_id === s.student_fee_id));
    const options = {
      notes: values.notes,
      fees,
      amount: fees.reduce((total, item) => total + item.amount, 0)
    };

    const result = isAdd ? await createConcession({ ...options, student_id: student.student_id }) : isReview ? await approveConcession(options, concession.concession_id) : await updateConcession(options, concession.concession_id);

    if (result.succeeded) {
      props.setAlert({
        message: 'Concession submitted successfully!',
        severity: 'success',
      });
      props.setAlertOpen(true);
      props.onClose();
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth='lg' fullWidth>
      <Head>
        <DialogTitle>{props.mode} Concession</DialogTitle>
        <IconButton onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </Head>
      <DialogContent>
        <FlexColumn>
          {isAdd && (
            <FormField>
              <label>Student</label>
              <StudentAutocomplete onChange={handleStudentChange} />
            </FormField>
          )}
          {hasStudent && <StudentHeader {...student} />}
          {hasStudentFees && (
            <>
              <Form initialValues={concessionForm} onSubmit={handleSubmit}>
                {({ values }) => (
                  <FlexColumn>
                    <FormField>
                      <label>Concessions</label>
                      <Table
                        rows={fees}
                        columns={columns}
                        selectable
                        selected={selectedFees}
                        onSelectionChange={handleSelectionChange}
                      />
                    </FormField>
                    <FormField>
                      <label>Total amount</label>
                      <AmountLabel>{getTotalAmount(values.fees)}</AmountLabel>
                    </FormField>
                    <FormField>
                      <label>Notes</label>
                      <Textarea name='notes' rows={3} />
                    </FormField>
                    {hasErrors &&
                      concessionResult.errors.map((error, index) => (
                        <Alert severity='error' key={index}>
                          {error.description}
                        </Alert>
                      ))}
                    <FlexRow justify='flex-end'>
                      {isReview && <Button type='submit' color='success'>Approve</Button>}
                      {!isReview && <Button type='submit'>Save Changes</Button>}
                      <Button
                        variant='text'
                        color='secondary'
                        onClick={props.onClose}
                      >
                        Cancel
                      </Button>
                    </FlexRow>
                  </FlexColumn>
                )}
              </Form>
            </>
          )}
          {hasStudent && !hasStudentFees && (
            <div>
              We couldn&apos;t find any outstanding fees for this student.
            </div>
          )}
        </FlexColumn>
      </DialogContent>
    </Dialog>
  );
};

export default ConcessionDialog;
