import styled from '@emotion/styled';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { createStudentFee } from 'gateways/student-fee';
import { FeeType, Student, StudentFeeCreateOptions, Term } from 'models';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFeeTypeStore } from 'store/fee-type';
import { useStudentStore } from 'store/student';
import {
  Button,
  Column,
  Form,
  Input,
  Radio,
  RadioGroup,
  Table,
  Select
} from 'views/components/elements';
import AdvancedFilterDialog from 'views/components/elements/AdvancedFilterDialog';
import { FilterForm, FlexColumn, FlexRow } from 'views/components/styled';
import { columns } from '../students/Column';
import AlertNotifier, { Position } from 'views/components/elements/AlertNotifier';
import { useTermStore } from 'store';

const StyledRow = styled(FlexRow)({
  justifyContent: 'start',
  display: 'block'
});

const StyledDiv = styled('div')({
  opacity: 0.6
});

const StyledButton = styled('div')({
  marginTop: 20
});

const FullWidthSelect = styled(Select)({
  width: '100%'
});

type SearchStudentProps = {
  onNext: (students: string[]) => void
}

export function SearchStudent(props: SearchStudentProps) {
  const { students: studentList, filter: studentFilter, hasNextPage, selected: draftListSelected, fetchStudentList, selectStudents } = useStudentStore();
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [advancedSearchOpen, setAdvSearchOpen] = useState(false);
  const filter = { query: studentFilter.query || '' };

  useEffect(() => {
    selectStudents([]);
  }, []);
  const handleSubmit = (values: typeof filter) => {
    const updatedFilter = Object.assign({}, filter, {
      page: 1,
      query: values.query,
    });

    fetchStudentList(updatedFilter);
  };

  const handleAdvancedSearch = () => {
    setAdvSearchOpen(true);
  };

  const handleAdvancedSearchClose = () => {
    setAdvSearchOpen(false);
  };

  const clearFilter = () => {
    setIsFilterApplied(false);
    fetchStudentList();
  };

  const handleSort = (field: string, order: string) => {
    const filter = Object.assign({}, studentFilter, {
      sortColumn: field,
      sortDirection: order,
      page: 1,
    });

    fetchStudentList(filter);
  };

  const handleNextPage = () => {
    const filter = Object.assign({}, studentFilter, {
      page: studentFilter.page + 1,
    });
    fetchStudentList(filter);
  };

  const selected = studentList.filter(x =>
    draftListSelected.includes(x.student_id)
  );

  const handleSelectionChange = (rows: Student[]) => {
    selectStudents(rows.map(x => x.student_id));
  };

  const handleClearSelection = () => {
    selectStudents([]);
  };

  const handleNext = () => {
    props.onNext(draftListSelected);
  };

  const handleCancel = () => {
    history.back();
  };

  return (
    <>
      <div className='row action'>
        <FlexRow justify='space-between' size='xs'>
          <FlexRow>
            <h1>Select Students</h1>
          </FlexRow>
          <FlexColumn>
            {draftListSelected?.length > 0 && (
              <StyledDiv>{draftListSelected?.length} Students selected</StyledDiv>
            )}
          </FlexColumn>
        </FlexRow>
        <FlexRow>
          <Form
            initialValues={filter}
            onSubmit={handleSubmit}
            validateOnChange={false}
          >
            <Input
              name='query'
              placeholder='Search Students'
              startAdornment={<SearchIcon />}
            />
          </Form>
          <IconButton onClick={handleAdvancedSearch}>
            <FilterListIcon />
          </IconButton>
          {isFilterApplied && (
            <Button variant='text' color='secondary' onClick={clearFilter}>
              Clear filters
            </Button>
          )}
        </FlexRow>
        <AdvancedFilterDialog
          open={advancedSearchOpen}
          onClose={handleAdvancedSearchClose}
          searchModule={'Student'}
          setIsFilterApplied={setIsFilterApplied}
        />
      </div>
      <Table
        columns={columns}
        rows={studentList}
        stickyHeader
        onSortChange={handleSort}
        sortingField={studentFilter.sortColumn}
        sortingOrder={studentFilter.sortDirection}
        hasMoreRows={hasNextPage}
        infiniteScroll
        onNextPage={handleNextPage}
        selectable
        selected={selected}
        onSelectionChange={handleSelectionChange} />
      <FlexRow style={{ marginTop: '24px' }}>
        <Button color='primary' onClick={handleNext} disabled={!draftListSelected?.length}>
          Next
        </Button>
        <Button color='secondary' onClick={handleClearSelection}>Clear Selection</Button>
        <Button variant='text' color='secondary' onClick={handleCancel}>
          Cancel
        </Button>
      </FlexRow>
    </>
  );
}

type SearchFeeTypeProps = {
  students: string[]
}

interface FeeFormProps {
  feeTypes: FeeType[]
  students: string[]
  terms: Term[]
}

function FeesForm(props: FeeFormProps) {
  const [selected, setSelected] = useState<FeeType[]>([]);
  const [alert, setAlert] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const alertPosition: Position = { vertical: 'bottom', horizontal: 'right' };

  const columns = [
    { field: 'name', label: 'Fee Head' },
    { field: 'bank_account_name', label: 'Bank Account', width: 250 },
    { field: 'receipt_template_name', label: 'Receipt Template', width: 250 },
    {
      field: 'term_number',
      label: 'Term',
      width: 160,
      valueGetter: (_, index) => (
        <FullWidthSelect name={`fee_items[${index}].term_id`} onClick={e => e.stopPropagation()}>
          {props.terms.map(term => (
            <MenuItem key={term.term_id} value={term.term_id}>{term.name}</MenuItem>
          ))}
        </FullWidthSelect>
      )
    },
    {
      field: 'amount',
      label: 'Amount',
      width: 120,
      valueGetter: (_, index) => (
        <Input
          name={`fee_items[${index}].amount`}
          type='number'
          onClick={e => e.stopPropagation()}
        />
      )
    },
    {
      field: 'due_date',
      label: 'Due Date',
      width: 200,
      valueGetter: (_, index) => (
        <Input
          name={`fee_items[${index}].due_date`}
          type='date'
          onClick={e => e.stopPropagation()}
        />
      )
    }
  ] as Column<FeeType>[];

  const feesForm = {
    fee_items: props.feeTypes.map(feeType => ({
      fee_type_id: feeType.fee_type_id,
      amount: '',
      due_date: '',
      term_id: ''
    }))
  };

  const handleSelectionChange = (rows: FeeType[]) => setSelected(rows);

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  const handleClearSelection = () => {
    setSelected([]);
  };

  const handleCancel = () => {
    history.back();
  };

  const handleSubmit = async (values: typeof feesForm) => {
    const currentDate = new Date();
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(currentDate.getMonth() - 6);
    const oneYearAhead = new Date();
    oneYearAhead.setFullYear(currentDate.getFullYear() + 1);

    const fees = values.fee_items
      .filter(item => selected.some(ft => ft.fee_type_id === item.fee_type_id));

    if (fees.some(re => !re.amount || !re.term_id)) {
      setAlert({
        message: 'Need to fill selected fee types fee amount and term',
        severity: 'warning',
      });
      setAlertOpen(true);
      return;
    }

    if (fees.filter(f => f.due_date).some(re => {
      const dueDate = new Date(re.due_date);
      return !(dueDate >= sixMonthsAgo && dueDate <= oneYearAhead);
    })
    ) {
      setAlert({
        message: 'Due date must be between the last 6 months and next 1 year!',
        severity: 'warning',
      });
      setAlertOpen(true);
      return;
    }

    const studentFeeCreateOptions: StudentFeeCreateOptions = {
      fees: fees.map(fee => ({
        fee_type_id: fee.fee_type_id,
        term_id: +fee.term_id,
        due_date: fee.due_date,
        amount: +fee.amount
      })),
      students: props.students
    };

    await createStudentFee(studentFeeCreateOptions);

    history.back();
  };

  return (
    <>
      <Form initialValues={feesForm} onSubmit={handleSubmit}>
        <FlexRow justify='space-between' size='xs'>
          <FlexRow>
            <h1>Select Fee Type</h1>
          </FlexRow>
          <FlexColumn>
            {props.students.length} Students Selected
            {selected?.length > 0 && (
              <StyledDiv>with {selected?.length} Fee types</StyledDiv>
            )}
          </FlexColumn>
        </FlexRow>
        <AlertNotifier {...alert} open={alertOpen} onClose={handleCloseAlert} position={alertPosition} />
        <Table
          columns={columns}
          rows={props.feeTypes}
          stickyHeader
          infiniteScroll
          selectable
          selected={selected}
          onSelectionChange={handleSelectionChange}
        />
        <FlexColumn>
          <FlexRow style={{ marginTop: '24px' }}>
            <Button color='primary' type='submit' disabled={!selected?.length}>Apply Fees</Button>
            <Button color='secondary' onClick={handleClearSelection}>Clear Selection</Button>
            <Button variant='text' color='secondary' onClick={handleCancel}>
              Cancel
            </Button>
          </FlexRow>
        </FlexColumn>
      </Form>
    </>
  );
}

export function SearchFeeTypes(props: SearchFeeTypeProps) {
  const { feeTypes, fetchFeeTypes } = useFeeTypeStore();
  const { terms, fetchTerms } = useTermStore();

  useEffect(() => {
    if (!feeTypes.length) {
      fetchFeeTypes();
    }
    if (!terms.length) {
      fetchTerms();
    }
  }, []);

  const feeTerms = terms.filter(t => t.is_active);
  const hasFeeTypes = Boolean(feeTypes.length && feeTerms.length);

  return (
    <>
      {hasFeeTypes && (
        <FeesForm
          students={props.students}
          feeTypes={feeTypes}
          terms={feeTerms}
        />
      )}
    </>
  );
}

type View = 'FlowForm' | 'SearchStudents' | 'SearchFeeTypes'

function ApplyFeesList() {
  //const [mode, setMode] = useState<string>();
  const [view, setView] = useState<View>('FlowForm');
  const [students, setStudents] = useState<string[]>([]);
  const { studentId } = useParams();

  useEffect(() => {
    if (studentId) {
      setStudents([studentId]);
      setView('SearchFeeTypes');
    }
  }, [studentId]);

  const flowForm = {
    type: ''
  };

  const handleSubmit = () => {
    //setMode(values.type);
    setView('SearchStudents');
  };

  const handleNext = (students: string[]) => {
    setView('SearchFeeTypes');
    setStudents(students);
  };

  const showFlowForm = view === 'FlowForm';
  const showStudents = view === 'SearchStudents';
  const showFeeTypes = view === 'SearchFeeTypes';

  return (
    <>
      <div className='custom-table-wrapper'>
        <div className='head'>
          <h1>Apply Fees</h1>
        </div>
        {showFlowForm && (
          <FilterForm initialValues={flowForm} onSubmit={handleSubmit}>
            <StyledRow>
              <h3>How do you want to apply?</h3>
              <div>
                <RadioGroup name='type'>
                  <Radio value='fee-rules' label='Apply based on fee rules' />
                  <Radio value='manual' label='Manual fee apply' />
                </RadioGroup>
              </div>
              <StyledButton>
                <Button type='submit'>Next</Button>
              </StyledButton>
            </StyledRow>
          </FilterForm>
        )}
        {showStudents && (
          <SearchStudent onNext={handleNext} />
        )}
        {showFeeTypes && (
          <SearchFeeTypes students={students} />
        )}
      </div>
    </>
  );
}

export default ApplyFeesList;
