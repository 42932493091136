import MenuItem from '@mui/material/MenuItem';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import SchoolIcon from '@mui/icons-material/School';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import {
  Button,
  Form,
  Select,
  RadioGroup,
  Radio,
} from 'views/components/elements';
import { styled } from '@mui/material/styles';
import { useAccountStore, useInstituteStore } from 'store';
import { InstituteUpdateOptions } from 'models';
import { BEHAVIOR_ACTIONS } from 'views/shared/utils/validation-utils';

const Subtitle = styled('h3')(({ theme }) => ({
  color: theme.palette.warning.main,
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  columnGap: 12,
}));

const Caption = styled('div')({
  fontSize: 12,
  color: '#64748b',
  maxWidth: 480,
});

const StyledDivider = styled(Divider)({
  borderColor: '#cbd5e1',
  marginBottom: 32,
});

const Section = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 32,
});

const StackLabel = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
});

const StackControl = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 24,
  width: 400,
});

const Label = styled(InputLabel)({
  fontSize: 14,
  color: '#475569',
  marginBottom: 8,
});

const Title = styled('h1')({
  margin: '32px 0',
});

const months = [
  {
    month: 1,
    name: 'January',
    days: 31,
  },
  {
    month: 2,
    name: 'February',
    days: 28,
  },
  {
    month: 3,
    name: 'March',
    days: 31,
  },
  {
    month: 4,
    name: 'April',
    days: 30,
  },
  {
    month: 5,
    name: 'May',
    days: 31,
  },
  {
    month: 6,
    name: 'June',
    days: 30,
  },
  {
    month: 7,
    name: 'July',
    days: 31,
  },
  {
    month: 8,
    name: 'August',
    days: 31,
  },
  {
    month: 9,
    name: 'September',
    days: 30,
  },
  {
    month: 10,
    name: 'October',
    days: 31,
  },
  {
    month: 11,
    name: 'November',
    days: 30,
  },
  {
    month: 12,
    name: 'December',
    days: 31,
  },
];

const days = Array.from({ length: 31 }, (_, index) => index + 1);

function InstituteDetail() {
  const { institute, updateInstitute } = useInstituteStore();
  const { hasAccess } = useAccountStore();
  const hasSettingUpdateAccess = hasAccess(BEHAVIOR_ACTIONS.SettingsUpdate);

  if (!institute) {
    return <></>;
  }

  const financialYearStart = new Date(institute.financial_year_start);
  const academicYearStart = new Date(institute.academic_year_start);

  const instituteForm = {
    financial_year_month: financialYearStart.getMonth() + 1,
    academic_year_month: academicYearStart.getMonth() + 1,
    financial_year_day: financialYearStart.getDate(),
    academic_year_day: academicYearStart.getDate(),
    receipt_year_type: institute.receipt_year_type,
    institute_type: institute.institute_type
  };

  const getDate = (month: number, day: number) => {
    const date = new Date(0);
    date.setMonth(month - 1, day);
    return date;
  };

  const handleSubmit = async (values: typeof instituteForm) => {
    const data = {
      receipt_year_type: values.receipt_year_type,
      metadata: institute.metadata,
      financial_year_start: getDate(
        values.financial_year_month,
        values.financial_year_day
      ),
      academic_year_start: getDate(
        values.academic_year_month,
        values.academic_year_day
      ),
      institute_type: values.institute_type
    } as InstituteUpdateOptions;

    await updateInstitute(data);
  };

  return (
    <>
      <Title>Settings</Title>
      <Form initialValues={instituteForm} onSubmit={handleSubmit}>
        <Section>
          <StackLabel>
            <Subtitle>
              <CardTravelIcon />
              Financial Year
            </Subtitle>
            <Caption>
              Start day and month of finacial year, If chosen the year value
              will be used in receipt number generation process.
            </Caption>
          </StackLabel>
          <StackControl>
            <Stack>
              <Label>Month</Label>
              <Select name='financial_year_month' disabled={!hasSettingUpdateAccess}>
                {months.map(item => (
                  <MenuItem key={item.month} value={item.month}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            <Stack>
              <Label>Day</Label>
              <Select name='financial_year_day' disabled={!hasSettingUpdateAccess}>
                {days.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </StackControl>
        </Section>
        <StyledDivider />
        <Section>
          <StackLabel>
            <Subtitle>
              <SchoolIcon />
              Academic Year
            </Subtitle>
            <Caption>
              Start day and month of academic year, If chosen the year value
              will be used in receipt number generation process.
            </Caption>
          </StackLabel>
          <StackControl>
            <Stack>
              <Label>Month</Label>
              <Select name='academic_year_month' disabled={!hasSettingUpdateAccess}>
                {months.map(item => (
                  <MenuItem key={item.month} value={item.month}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            <Stack>
              <Label>Day</Label>
              <Select name='academic_year_day' disabled={!hasSettingUpdateAccess}>
                {days.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </StackControl>
        </Section>
        <StyledDivider />
        <Section>
          <StackLabel>
            <Subtitle>
              <ReceiptIcon />
              Year used for receipt
            </Subtitle>
            <Caption>
              Choose the year that you want to use in receipt number process,
              the receipt number will reset when a new period is started for
              selected type.
            </Caption>
          </StackLabel>
          <StackControl>
            <RadioGroup name='receipt_year_type'>
              <Radio value='CalendarYear' label='Calendar Year' disabled={!hasSettingUpdateAccess} />
              <Radio value='AcademicYear' label='Academic Year' disabled={!hasSettingUpdateAccess} />
              <Radio value='FinancialYear' label='Financial Year' disabled={!hasSettingUpdateAccess} />
            </RadioGroup>
          </StackControl>
        </Section>
        <Section>
          <StackLabel>
            <Subtitle>
              <ReceiptIcon />
              Institute Type
            </Subtitle>
            <Caption>
              Classification of educational institution based on the level of education or training offered.
            </Caption>
          </StackLabel>
          <StackControl>
            <RadioGroup name='institute_type'>
              <Radio value='School' label='School' disabled={!hasSettingUpdateAccess} />
              <Radio value='College' label='College' disabled={!hasSettingUpdateAccess} />
              <Radio value='University' label='University' disabled={!hasSettingUpdateAccess} />
            </RadioGroup>
          </StackControl>
        </Section>
        <Button type='submit' disabled={!hasSettingUpdateAccess}>Save Changes</Button>
      </Form>
    </>
  );
}

export default InstituteDetail;
