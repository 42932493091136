import { Receipt, ReceiptListParams, ReceiptOptions, ReceiptResponse } from '../models/Receipt';
import { sendRequest, getRequest, postRequest } from './gateway';
import { Paged } from '../models/Pagination';
import { EntityResult } from 'models/ApiResult';

function createReceipt(receipt: ReceiptOptions) {
  const postPromise = postRequest<EntityResult<ReceiptResponse>>('/api/v1/receipt', receipt);

  return sendRequest(postPromise);
}

function listReceipts(params: ReceiptListParams) {
  params.sortColumn ??= 'created_date_time';
  params.sortDirection ??= 'desc';
  params.limit ??= 100;
  params.page ??= 1;

  const fetchPromise = getRequest<Paged<Receipt>>('/api/v1/receipt/list', { params });

  return sendRequest(fetchPromise);
}

export { createReceipt, listReceipts };
