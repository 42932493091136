import { createFeeRule, disableFeeRule, enableFeeRule, getFeeRule, listFeeRules, updateFeeRule } from 'gateways/fee-rule';
import { FeeRule, FeeRuleCreateOptions, FeeRuleUpdateOptions } from 'models';
import { create } from 'zustand';

type State = {
  feeRules: FeeRule[]
  feeRule?: FeeRule
}

type Action = {
  fetchFeeRules: () => Promise<void>
  fetchFeeRule: (feeRuleId: string) => Promise<void>
  resetFeeRule: () => void
  createFeeRule: (model: FeeRuleCreateOptions) => Promise<void>
  updateFeeRule: (model: FeeRuleUpdateOptions, feeRuleId: string) => Promise<void>
  enableFeeRule: (feeRuleId: string) => Promise<void>
  disableFeeRule: (feeRuleId: string) => Promise<void>
}

export const useFeeRuleStore = create<State & Action>()((set, get) => ({
  feeRules: [],
  fetchFeeRules: async () => {
    const feeRules = await listFeeRules();
    set({ feeRules });
  },
  fetchFeeRule: async (feeRuleId: string) => {
    const feeRule = await getFeeRule(feeRuleId);
    set({ feeRule });
  },
  resetFeeRule: () => {
    set({ feeRule: null });
  },
  createFeeRule: async (model: FeeRuleCreateOptions) => {
    const feeRule = await createFeeRule(model);
    const { feeRules } = get();
    set({
      feeRule,
      feeRules: feeRules.slice().concat(feeRule)
    });
  },
  updateFeeRule: async (model: FeeRuleUpdateOptions, feeRuleId: string) => {
    const feeRule = await updateFeeRule(model, feeRuleId);

    const feeRules = get().feeRules.slice();
    feeRules.splice(feeRules.findIndex(x => x.fee_rule_id === feeRuleId), 1, feeRule);

    set({ feeRule, feeRules });
  },
  enableFeeRule: async (feeTypeId: string) => {
    await enableFeeRule(feeTypeId);
  },
  disableFeeRule: async (feeTypeId: string) => {
    await disableFeeRule(feeTypeId);
  }
}));
