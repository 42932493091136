import LockIcon from '@mui/icons-material/Lock';
import { Box, Typography } from '@mui/material';

function Unauthorize() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '75vh',
        textAlign: 'center',
        margin: '0 auto',
        maxWidth: '1024px',
      }}
    >
      <LockIcon sx={{ fontSize: 60, color: 'gray' }} />
      <Typography variant="h6" component="p" gutterBottom>
                Access Restricted
      </Typography>
      <Typography variant="body1" component="p">
                You do not have the necessary permissions to view, create, or edit this data. 
                If you believe this is an error or require access, 
                please contact your system administrator or support team.
      </Typography>
    </Box>
  );
}

export default Unauthorize;
