import {
  Receipt,
  ReceiptOptions,
  StudentFeeView,
  Student
} from 'models';
import { useEffect, useMemo, useState } from 'react';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import { useReceiptStore } from 'store/receipt';
import {
  Button,
  Column,
  Form,
  Input,
  Radio,
  RadioGroup,
  Table,
  Textarea,
} from 'views/components/elements';
import {
  FlexColumn,
  FlexRow,
  FormField,
  ContentWrapper,
} from 'views/components/styled';
import { currencyFormat } from 'views/shared/utils/string-utils';
import { debounce } from '@mui/material';
import StudentHeader from 'views/components/StudentHeader';

const AmountInput = styled(Input)({
  '& .MuiInputBase-input': {
    textAlign: 'right',
  },
});

const AmountLabel = styled('label')({
  height: 42,
  display: 'flex',
  alignItems: 'center',
  fontWeight: 600,
});

function AddReceipt() {
  const { studentId } = useParams();
  const { studentContext, setStudentContext, resetStudentContext } =
    useReceiptStore();
  const { student, fees, receipts } = studentContext;

  useEffect(() => {
    if (
      studentId &&
      (!studentContext || studentContext.studentId !== studentId)
    ) {
      setStudentContext(studentId);
    }

    if (!studentId) {
      resetStudentContext();
    }
  }, [studentId]);

  const hasReceipts = Boolean(receipts.length);
  const hasFees = Boolean(fees.length);
  const hasStudent = Boolean(student.student_id);

  return (
    <ContentWrapper>
      <h1>Add Receipt</h1>
      <SearchForm rollNumber={student.roll_number} />
      {hasStudent && <StudentSection student={student} />}
      <FlexRow align='start' justify='space-between' size='md'>
        {hasFees && <ReceiptForm fees={fees} student={student} />}
        {hasReceipts && <ReceiptsTable receipts={receipts} />}
      </FlexRow>
    </ContentWrapper>
  );
}

function SearchForm({ rollNumber }: { rollNumber: string }) {
  const navigate = useNavigate();
  const { searchStudents } = useReceiptStore();
  const [value, setValue] = useState<Student | null>(
    rollNumber ? ({ roll_number: rollNumber } as Student) : null
  );
  const [options, setOptions] = useState<Student[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading,setIsLoading] = useState(false);

  const fetchOptions = useMemo(
    () =>
      debounce(
        async (query: string, onResults: (results?: Student[]) => void) => {
          setIsLoading(true);
          const students = await searchStudents(query);
          setIsLoading(false);
          onResults(students);
        },
        400
      ),
    []
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetchOptions(inputValue, (results?: Student[]) => {
      if (active) {
        let newOptions: Student[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetchOptions]);

  const handleChange = (newValue: Student) => {
    if (newValue?.student_id) {
      navigate(`/receipts/new/student/${newValue.student_id}`);
    }
    setValue(newValue);
  };

  return (
    <>
      <Autocomplete
        autoComplete
        includeInputInList
        noOptionsText={isLoading ? 'Loading...' : 'No matching student'}
        filterSelectedOptions
        filterOptions={x => x}
        options={options}
        getOptionLabel={option => option.roll_number}
        value={value}
        onChange={(_event: any, newValue: Student | null) => {
          setOptions(newValue ? [newValue, ...options] : options);
          handleChange(newValue);
        }}
        onInputChange={(_event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={params => (
          <TextField {...params} label='Choose a student' fullWidth />
        )}
        renderOption={(props, option) => (
          <li {...props}>
            {option.first_name} {option.last_name} {option.roll_number}
          </li>
        )}
      />
    </>
  );
}

type ReceiptFormProps = {
  fees: StudentFeeView[];
  student: Student;
};

type ErrorList = {
  code: string;
  description: string;
};

function ReceiptForm(props: ReceiptFormProps) {
  const [selected, setSelected] = useState<StudentFeeView[]>([]);
  const { createReceipt, receiptCreateResult } = useReceiptStore();
  const [errors, setErrors] = useState<ErrorList[]>([]);

  const columns = [
    { field: 'fee_type', label: 'fee type' },
    { field: 'term_number', label: 'term' },
    { field: 'amount', label: 'amount', format: 'currency' },
    {
      field: 'amount_capturable',
      label: 'balance',
      format: 'currency',
    },
    {
      field: 'amount_pay',
      label: 'Pay',
      valueGetter: (row, index) =>
        row.is_accept_partial_pay ? (
          <AmountInput
            name={`receipt_items[${index}].amount`}
            type='number'
            onClick={e => e.stopPropagation()}
          />
        ) : (
          row.amount_capturable
        ),
      format: 'currency',
    },
  ] as Column<StudentFeeView>[];

  const receiptForm = {
    receipt_type: 'Cash',
    transaction_number: '',
    transaction_date: '',
    notes: '',
    receipt_items: props.fees.map(fee => ({
      student_fee_id: fee.student_fee_id,
      amount: fee.amount_capturable,
    })),
  };

  const handleSelectionChange = (rows: StudentFeeView[]) => setSelected(rows);

  const handleSubmit = async (values: typeof receiptForm) => {
    setErrors([]);
    const options = {
      student_id: props.student.student_id,
      receipt_type: values.receipt_type,
      notes: values.notes,
      receipt_items: values.receipt_items.filter(x =>
        selected.some(s => s.student_fee_id === x.student_fee_id)
      ),
      student_name: `${props.student.first_name} ${props.student.last_name}`,
      roll_number: props.student.roll_number,
    } as ReceiptOptions;

    options.amount = options.receipt_items.reduce(
      (total, item) => total + item.amount,
      0
    );

    if (options.receipt_type !== 'Cash') {
      if (!values.transaction_number || !values.transaction_date) {
        const errors = [
          {
            code: 'Error',
            description: `You need to add ${
              !values.transaction_number
                ? `${getTransactionLabel(values.receipt_type)} ${isOnLinePayment(values.receipt_type) ? 'Id' : 'No'}`
                : `${getTransactionLabel(values.receipt_type)} Date`
            }`,
          },
        ];
        setErrors(errors);
        return;
      }
      options.transaction_number = values.transaction_number;
      options.transaction_date = values.transaction_date;
    }

    await createReceipt(options);
  };

  const isOnLinePayment = (type: string) => {
    return ['NetBanking', 'CreditCard', 'DebitCard', 'UPI'].includes(type);
  };

  const getTransactionLabel = (type: string) =>
    type === 'DemandDraft'
      ? 'Demand Draft'
      : isOnLinePayment(type)
        ? 'Transaction'
        : type;

  const getTotalAmount = (items: typeof receiptForm.receipt_items) =>
    currencyFormat(
      items
        .filter(x => selected.some(s => s.student_fee_id === x.student_fee_id))
        .reduce((total, item) => total + item.amount, 0)
    );

  const hasErrors = Boolean(receiptCreateResult?.errors?.length);

  return (
    <div>
      <h3>Fees</h3>

      <Form initialValues={receiptForm} onSubmit={handleSubmit}>
        {({ values }) => (
          <FlexColumn>
            <Table
              rows={props.fees}
              columns={columns}
              selectable
              selected={selected}
              onSelectionChange={handleSelectionChange}
            />
            <FlexRow size='md'>
              <FormField>
                <label>Total amount</label>
                <AmountLabel>
                  {getTotalAmount(values.receipt_items)}
                </AmountLabel>
              </FormField>
            </FlexRow>
            <FlexRow size='md'>
              <FormField>
                <label>Type</label>
                <RadioGroup name='receipt_type' row>
                  <Radio value='Cash' label='Cash' />
                  <Radio value='Cheque' label='Cheque' />
                  <Radio value='DemandDraft' label='Demand Draft' />
                  <Radio value='NetBanking' label='Net Banking' />
                  <Radio value='CreditCard' label='Credit Card' />
                  <Radio value='DebitCard' label='Debit Card' />
                  <Radio value='UPI' label='UPI' />
                </RadioGroup>
              </FormField>
            </FlexRow>
            {values.receipt_type !== 'Cash' && (
              <FlexRow>
                <FormField>
                  <label>{getTransactionLabel(values.receipt_type)} {isOnLinePayment(values.receipt_type) ? 'Id' : 'No.'}</label>
                  <Input name='transaction_number' />
                </FormField>
                <FormField>
                  <label>{getTransactionLabel(values.receipt_type)} Date</label>
                  <Input name='transaction_date' type='date' />
                </FormField>
              </FlexRow>
            )}
            <FormField>
              <label>Notes</label>
              <Textarea name='notes' rows={3} />
            </FormField>
            {hasErrors &&
              receiptCreateResult.errors.map((error, index) => (
                <Alert severity='error' key={index}>
                  {error.description}
                </Alert>
              ))}
            {errors.map((error, index) => (
              <Alert severity='error' key={index}>
                {error.description}
              </Alert>
            ))}
            <FlexRow size='xs'>
              <Button type='submit'>Create Receipt</Button>
              <Button
                color='secondary'
                variant='text'
                component={NavLink}
                to='/receipts'
              >
                Cancel
              </Button>
            </FlexRow>
          </FlexColumn>
        )}
      </Form>
    </div>
  );
}

function ReceiptsTable({ receipts }: { receipts: Receipt[] }) {
  const columns = [
    { field: 'receipt_number', label: 'receipt no.', width: 120 },
    { field: 'amount', label: 'amount', format: 'currency', width: 150 },
    { field: 'receipt_type', label: 'type', width: 120 },
    { field: 'receipt_status', label: 'status', width: 120 },
    {
      field: 'created_date_time',
      label: 'created',
      format: 'datetime',
      width: 180,
    },
    {
      field: 'action',
      valueGetter: row =>
        row.receipt_file_location && (
          <IconButton href={row.receipt_file_location} target='_blank'>
            <CloudDownloadIcon />
          </IconButton>
        ),
      width: 80,
    },
  ] as Column<Receipt>[];

  return (
    <div>
      <h3>Receipts</h3>
      <Table rows={receipts} columns={columns} />
    </div>
  );
}

function StudentSection({ student }: { student: Student }) {
  return (
    <>
      <FlexRow size='lg' style={{ marginTop: 16 }}>
        <StudentHeader {...student} />
      </FlexRow>
      {!student.is_active && (
        <Alert severity='warning'>This student is not active</Alert>
      )}
    </>
  );
}

export default AddReceipt;
