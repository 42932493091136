import { StudentFee, StudentFeeCreateOptions, StudentFeeParams, StudentFeeUpdateOptions, StudentFeeView, StudentFeeStatusUpdateOptions } from '../models/StudentFee';
import { sendRequest, getRequest, postRequest, putRequest } from './gateway';
import { ImportResult } from '../models/ImportResult';
import { Paged } from '../models/Pagination';

function getStudentFee(studentFeeId: string) {
  const fetchPromise = getRequest<StudentFee>(`/api/v1/student-fee/${studentFeeId}`);

  return sendRequest(fetchPromise);
}

function createStudentFee(studentFee: StudentFeeCreateOptions) {
  const postPromise = postRequest<void>('/api/v1/student-fee', studentFee);

  return sendRequest(postPromise);
}

function updateStudentFee(studentFee: StudentFeeUpdateOptions, studentFeeId: string) {
  const putPromise = putRequest<StudentFee>(`/api/v1/student-fee/${studentFeeId}`, studentFee);

  return sendRequest(putPromise);
}

function listStudentFees(params: StudentFeeParams) {
  params.sortColumn ??= 'created_date_time';
  params.sortDirection ??= 'desc';
  params.limit ??= 100;
  params.page ??= 1;

  const fetchPromise = getRequest<Paged<StudentFeeView>>('/api/v1/student-fee/list', { params });

  return sendRequest(fetchPromise);
}

function finalizeStudentFees(fees: StudentFeeStatusUpdateOptions) {
  const putPromise = putRequest<void>('/api/v1/student-fee/finalize', fees);

  return sendRequest(putPromise);
}

function deleteStudentFees(fees: StudentFeeStatusUpdateOptions) {
  const putPromise = putRequest<void>('/api/v1/student-fee/delete', fees);

  return sendRequest(putPromise);
}

function voidStudentFees(fees: StudentFeeStatusUpdateOptions) {
  const putPromise = putRequest<void>('/api/v1/student-fee/void', fees);

  return sendRequest(putPromise);
}

function importStudentFees(fileLocation: string) {
  const postPromise = postRequest<ImportResult>('/api/v1/student-fee/import', {}, { params: { fileLocation } });

  return sendRequest(postPromise);
}

export { getStudentFee, createStudentFee, updateStudentFee, listStudentFees, finalizeStudentFees, deleteStudentFees, voidStudentFees, importStudentFees };
