import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Column,
  Form,
  Input,
  Radio,
  RadioGroup,
  Table,
} from 'views/components/elements';
import { useBankAccountStore } from 'store/bank-account';
import { BankAccount } from 'models';
import { BankAccountDialog } from 'views/dialogs';
import { useAccountStore } from 'store';
import { BEHAVIOR_ACTIONS } from 'views/shared/utils/validation-utils';

const Active = styled('span')(({ theme }) => ({
  color: theme.palette.success.dark,
  padding: '6px 16px',
  borderRadius: '100px',
  backgroundColor: '#defff0',
}));

const Inactive = styled('span')(({ theme }) => ({
  color: theme.palette.error.dark,
  padding: '6px 16px',
  borderRadius: '100px',
  backgroundColor: '#ffebeb',
}));

const FilterForm = styled(Form)({
  display: 'flex',
  columnGap: 24,
});

const Head = styled('h1')({
  margin: '32px 0',
});

function AccountList() {
  const columns = [
    { field: 'name', label: 'name' },
    { field: 'account_id', label: 'account id' },
    {
      field: 'is_active',
      label: 'status',
      valueGetter: row =>
        row.is_active ? <Active>Active</Active> : <Inactive>Inactive</Inactive>,
      width: 120,
    },
    {
      field: 'created_date_time',
      label: 'created',
      format: 'date',
      width: 120,
    },
    {
      field: 'action',
      label: '',
      valueGetter: row => (
        <IconButton onClick={createEditHandler(row)} disabled={!hasBankAccountUpdateAccess}>
          <EditIcon />
        </IconButton>
      ),
      width: 80,
    },
  ] as Column<BankAccount>[];
  const {
    bankAccounts,
    fetchBankAccounts,
    bankAccount,
    fetchBankAccount,
    resetBankAccount,
  } = useBankAccountStore();
  const { hasAccess } = useAccountStore();
  const hasBankAccountCreateAccess = hasAccess(BEHAVIOR_ACTIONS.BankAccountCreate);
  const hasBankAccountUpdateAccess = hasAccess(BEHAVIOR_ACTIONS.BankAccountUpdate);

  useEffect(() => {
    if (!bankAccounts.length) {
      fetchBankAccounts();
    }
  }, []);

  const createEditHandler = (bankAccount: BankAccount) => async () => {
    await fetchBankAccount(bankAccount.bank_account_id);
    setEditorOpen(true);
  };

  const [filter, setFilter] = useState({ query: '', status: 'All' });
  const [editorOpen, setEditorOpen] = useState(false);

  const handleSubmit = (values: typeof filter) => {
    setFilter(values);
  };

  const rows = bankAccounts.filter(x => {
    return (
      (!filter.query ||
        x.name.toLowerCase().includes(filter.query.toLowerCase())) &&
      (filter.status === 'All' || x.is_active === (filter.status === 'Active'))
    );
  });

  const handleAdd = () => {
    resetBankAccount();
    setEditorOpen(true);
  };

  const handleClose = () => {
    setEditorOpen(false);
  };

  return (
    <>
      <Head>Bank Accounts</Head>
      <div className='row head'>
        <FilterForm initialValues={filter} onSubmit={handleSubmit}>
          <Input
            name='query'
            placeholder='Search Bank Accounts'
            startAdornment={<SearchIcon />}
          />
          <RadioGroup name='status' row>
            <Radio value='Active' label='Active' />
            <Radio value='Inactive' label='Inactive' />
            <Radio value='All' label='All' />
          </RadioGroup>
          <Button type='submit' variant='text'>
            Search
          </Button>
        </FilterForm>
        <Button startIcon={<AddCircleOutlineIcon />} disabled={!hasBankAccountCreateAccess} onClick={handleAdd}>
          Add Bank Account
        </Button>
      </div>
      <Table rows={rows} columns={columns} />
      <BankAccountDialog
        open={editorOpen}
        onClose={handleClose}
        bankAccount={bankAccount}
      />
    </>
  );
}

export default AccountList;
