import { Outlet } from 'react-router-dom';
import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { FlexRow, ScrollablePage } from 'views/components/styled';

interface ReportProps {
  title: string;
  description: string;
  link?: string;
}

const reports = [
  {
    title: 'Fee Receipt Report',
    description:
      'This report details all fee receipts issued, including student information, fee amount, payment mode, and date.',
    link: 'daily-fee-collection',
  },
  {
    title: 'Due Fee Report',
    description:
      'This report identifies students with outstanding balances, categorized by class, grade, or any other relevant criteria.',
  },
  {
    title: 'Paid Fee Report',
    description:
      'This report shows all fees collected within a specific timeframe, helpful for tracking income and identifying trends.',
  },
  {
    title: 'Transaction Report',
    description:
      'This report provides a detailed log of all fee transactions, including payments, refunds, and adjustments.',
  },
  {
    title: 'Fee Collection Summary',
    description:
      'This report offers a high-level overview of total fees collected, outstanding dues, and percentage of fees paid.',
  },
  {
    title: 'Fee Concession Report',
    description:
      'This report summarizes all fee concessions applied to students, categorized by reason or type of concession.',
  },
  {
    title: 'Student Fee Detail Report',
    description:
      'This report provides a complete breakdown of an individual student\'s fee payment history, including payments made, outstanding dues, and any adjustments.',
  },
  {
    title: 'Fee Defaulter Report',
    description:
      'This report flags students who haven\'t paid their fees by a specific deadline.',
  },
  {
    title: 'Comparative Fee Collection Report',
    description:
      'This report allows you to compare fee collection across different periods (e.g., semesters, years) or student groups.',
  },
  {
    title: 'Customizable Reports',
    description:
      'Many ERPs offer the flexibility to create custom reports based on specific needs. This could involve filtering data by grade level, fee type, or payment method.',
  },
];

function Report(props: ReportProps) {
  if (props.link) {
    return (
      <Card>
        <CardActionArea>
          <CardContent>
            <h5>{props.title}</h5>
            <p>{props.description}</p>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <h5>{props.title}</h5>
        <p>{props.description}</p>
      </CardContent>
    </Card>
  );
}

function ReportList() {
  return (
    <ScrollablePage>
      <FlexRow align='start'>
        <div hidden>
          <h1>Reports</h1>
          <div>
            {reports.map(report => (
              <Report
                key={report.title}
                title={report.title}
                description={report.description}
                link={report.link}
              />
            ))}
          </div>
        </div>
        <Outlet />
      </FlexRow>
    </ScrollablePage>
  );
}

export default ReportList;
