import { Program, ProgramOptions } from 'models';
import { getRequest, postRequest, putRequest, sendRequest } from './gateway';

export function listPrograms() {
  const fetchPromise = getRequest<Program[]>('/api/v1/program/list');

  return sendRequest(fetchPromise);
}

export function createProgram(program: ProgramOptions) {
  const postPromise = postRequest('/api/v1/program', program);

  return sendRequest(postPromise);
}

export function updateProgram(program: ProgramOptions, programId: string) {
  const putPromise = putRequest(`/api/v1/program/${programId}`, program);

  return sendRequest(putPromise);
}

export function enableProgram(programId: string) {
  const putPromise = putRequest(`/api/v1/program/${programId}/enable`, null);
  return sendRequest(putPromise);
}

export function disableProgram(programId: string) {
  const putPromise = putRequest(`/api/v1/program/${programId}/disable`, null);
  return sendRequest(putPromise);
}
