import { createReceipt, listReceipts } from 'gateways/receipt';
import { getStudent, listStudents } from 'gateways/student';
import { listStudentFees } from 'gateways/student-fee';
import {
  Receipt,
  ReceiptOptions,
  ReceiptListParams,
  StudentFeeView,
  StudentFeeParams,
  ReceiptResponse,
  StudentListParams,
  ReceiptGenerateResult,
  Student
} from 'models';
import { EntityResult } from 'models/ApiResult';
import { Notification } from 'models/Notification';
import { create } from 'zustand';
import { addNotification, replaceNotification } from './notification';
import { currencyFormat } from 'views/shared/utils/string-utils';

type StudentContext = {
  fees: StudentFeeView[]
  studentId: string
  student: Student
  receipts: Receipt[]
}

type State = {
  receipts: Receipt[]
  hasNextPage: boolean
  filter: ReceiptListParams
  receiptCreateResult: EntityResult<ReceiptResponse>
  studentContext: StudentContext
}

type Action = {
  fetchReceipts: (params?: ReceiptListParams) => Promise<void>
  createReceipt: (options: ReceiptOptions) => Promise<void>
  setStudentContext: (studentId: string) => Promise<void>
  searchStudents: (query: string) => Promise<Student[]>
  resetStudentContext: () => void
}

const useReceiptStore = create<State & Action>()((set, get) => ({
  receipts: [],
  hasNextPage: false,
  filter: {},
  receiptCreateResult: {} as EntityResult<ReceiptResponse>,
  studentContext: {
    fees: [],
    student: {} as Student,
    studentId: null,
    receipts: []
  },
  fetchReceipts: async (params?: ReceiptListParams) => {
    params ??= {} as ReceiptListParams;

    params.sortColumn ??= 'created_date_time';
    params.sortDirection ??= 'desc';
    params.limit ??= 100;
    params.page ??= 1;
    params.linkType ??= 'View';

    const page = await listReceipts(params);
    set(state => ({
      filter: params,
      receipts: params.page === 1 ? page.collection : state.receipts.concat(page.collection),
      hasNextPage: params.page < page.metadata.last_page
    }));
  },
  createReceipt: async (options: ReceiptOptions) => {
    const { student_name, roll_number, ...rest } = options;
    const receiptCreateResult = await createReceipt(rest as ReceiptOptions);
    set(() => ({ receiptCreateResult }));
    if (receiptCreateResult.succeeded) {
      const { setStudentContext } = get();
      await setStudentContext(options.student_id);

      const statusQueryGetUri = receiptCreateResult.entity.function_management_options.statusQueryGetUri;
      const notification = {
        title: 'Receipt generation is in progress',
        source: 'receipt.create',
        status: 'inprogress',
        description: `Receipt generation for student ${student_name} ${roll_number} with amount of ${currencyFormat(options.amount)} is in progress`,
        metadata: {}
      } as Notification;
      notification.metadata.statusQueryGetUri = statusQueryGetUri;
      const notificationId = addNotification(notification);

      const receipts = await waitForPdfReceipt(statusQueryGetUri);

      replaceNotification(notificationId, receipts.map(receipt => ({
        title: 'Receipt generation succeeded',
        source: 'receipt.create',
        status: 'success',
        description: `Receipt generation for student ${student_name} ${roll_number} with amount of ${currencyFormat(options.amount)} was successful.`,
        actions: [{
          label: 'View receipt',
          link: receipt.receipt_file_location
        }]
      } as Notification)));
    }
  },
  setStudentContext: async (studentId: string) => {
    const studentContext = {
      studentId,
      fees: [],
      student: {},
      receipts: []
    } as StudentContext;
    set((state) => {
      if (state.studentContext.studentId === studentId) {
        studentContext.student = { ...state.studentContext.student };
        studentContext.fees = state.studentContext.fees.slice();
        studentContext.receipts = state.studentContext.receipts.slice();
      }

      return { studentContext };
    });

    const params = {
      studentId: studentId,
      statuses: ['Open', 'Overdue', 'PartlyPaid'],
      page: 1,
      limit: 100,
    } as StudentFeeParams;

    const receiptParams = {
      sortColumn: 'created_date_time',
      sortDirection: 'desc',
      page: 1,
      limit: 100,
      linkType: 'View',
      studentId: studentId
    } as ReceiptListParams;

    const page = await listStudentFees(params);
    const student = await getStudent(studentId);
    const receiptPage = await listReceipts(receiptParams);

    // hack to rerender receipt form
    set(() => ({ studentContext: { studentId, student: {}, fees: [], receipts: [] } as StudentContext }));
    setTimeout(() => {
      set(() => ({
        studentContext: {
          studentId,
          student,
          fees: page.collection,
          receipts: receiptPage.collection
        }
      }));
    }, 1);
  },
  searchStudents: async (query: string) => {
    const params = { query, page: 1, limit: 10 } as StudentListParams;
    const page = await listStudents(params, true);
    return page.collection;
  },
  resetStudentContext: () => {
    set(() => ({
      studentContext: {
        fees: [],
        student: {} as Student,
        studentId: null,
        receipts: []
      }
    }));
  }
}));

function waitForPdfReceipt(statusCheckUrl: string): Promise<ReceiptGenerateResult[]> {
  return new Promise((resolve) => {
    const checkStatus = async () => {
      const response = await fetch(statusCheckUrl);
      const result = await response.json();
      if (['Completed', 'Failed', 'Terminated', 'Suspended'].includes(result.runtimeStatus)) {
        clearInterval(interval);

        if (result.runtimeStatus === 'Completed') {
          resolve(result.output);
        } else {
          resolve(null);
        }
      }
    };

    const interval = setInterval(checkStatus, 1000);
  });
}

export { useReceiptStore };
