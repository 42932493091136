import { Notification } from 'models/Notification';
import { create } from 'zustand';

type State = {
  notifications: Notification[]
}

type Action = {
  remove: (id: string) => void;
}

function _removeNotification(notifications: Notification[], id: string, newNotifications?: Notification[]) {
  notifications = notifications.slice();
  const index = notifications.findIndex(x => x.id === id);
  if (index >= 0) {
    newNotifications ? notifications.splice(index, 1, ...newNotifications) : notifications.splice(index, 1);
  }
  return notifications;
}

const useNotificationStore = create<State & Action>()((set, get) => ({
  notifications: [],
  remove: (id: string) => {
    const { notifications } = get();
    set(() => ({ notifications: _removeNotification(notifications, id) }));
  }
}));

function addNotification(notification: Notification) {
  notification.id = crypto.randomUUID();
  useNotificationStore.setState(state => ({ notifications: [...state.notifications, notification] }));
  return notification.id;
}

function replaceNotification(id: string, notifications: Notification[]) {
  useNotificationStore.setState(state => ({ notifications: _removeNotification(state.notifications, id, notifications) }));
}

export { useNotificationStore, addNotification, replaceNotification };
