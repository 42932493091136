import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import { Field } from 'formik';

const Label = styled(FormControlLabel)({
  '.MuiFormControlLabel-label': {
    fontSize: 14,
  }
});

export default function FormikCheckbox(props: CheckboxProps & { label: string }) {
  const { name, label, ...rest } = props;
  return (
    <Field name={name} type='checkbox'>
      {({ field }) => <Label label={label} control={<Checkbox {...field} {...rest} />} />}
    </Field>
  );
}
