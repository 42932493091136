import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Column,
  Form,
  Input,
  Radio,
  RadioGroup,
  Table,
} from 'views/components/elements';
import { useReceiptTemplateStore } from 'store/receipt-template';
import { ReceiptTemplate } from 'models';
import { ReceiptTemplateDialog } from 'views/dialogs';
import { useAccountStore } from 'store';
import { BEHAVIOR_ACTIONS } from 'views/shared/utils/validation-utils';

const Active = styled('span')(({ theme }) => ({
  color: theme.palette.success.dark,
  padding: '6px 16px',
  borderRadius: '100px',
  backgroundColor: '#defff0',
}));

const Inactive = styled('span')(({ theme }) => ({
  color: theme.palette.error.dark,
  padding: '6px 16px',
  borderRadius: '100px',
  backgroundColor: '#ffebeb',
}));

const FilterForm = styled(Form)({
  display: 'flex',
  columnGap: 24,
});

const Head = styled('h1')({
  margin: '32px 0',
});

function TemplateList() {
  const columns = [
    { field: 'name', label: 'name' },
    {
      field: 'is_active',
      label: 'status',
      valueGetter: row =>
        row.is_active ? <Active>Active</Active> : <Inactive>Inactive</Inactive>,
      width: 120,
    },
    {
      field: 'created_date_time',
      label: 'created',
      format: 'date',
      width: 120,
    },
    {
      field: 'action',
      label: '',
      valueGetter: row => (
        <IconButton onClick={createEditHandler(row)} disabled={!hasReceiptTemplateEditAccess}>
          <EditIcon />
        </IconButton>
      ),
      width: 80,
    },
  ] as Column<ReceiptTemplate>[];

  const {
    receiptTemplates,
    fetchReceiptTemplates,
    receiptTemplate,
    fetchReceiptTemplate,
    resetReceiptTemplate,
  } = useReceiptTemplateStore();
  const { hasAccess } = useAccountStore();
  const hasReceiptTemplateWriteAccess = hasAccess(BEHAVIOR_ACTIONS.ReceiptTemplateCreate);
  const hasReceiptTemplateEditAccess = hasAccess(BEHAVIOR_ACTIONS.ReceiptTemplateUpdate);

  useEffect(() => {
    if (!receiptTemplates.length) {
      fetchReceiptTemplates();
    }
  }, []);

  const createEditHandler = (receiptTemplate: ReceiptTemplate) => async () => {
    await fetchReceiptTemplate(receiptTemplate.receipt_template_id);
    setEditorOpen(true);
  };

  const [filter, setFilter] = useState({ query: '', status: 'All' });
  const [editorOpen, setEditorOpen] = useState(false);

  const handleSubmit = (values: typeof filter) => {
    setFilter(values);
  };

  const rows = receiptTemplates.filter(x => {
    return (
      (!filter.query ||
        x.name.toLowerCase().includes(filter.query.toLowerCase())) &&
      (filter.status === 'All' || x.is_active === (filter.status === 'Active'))
    );
  });

  const handleAdd = () => {
    resetReceiptTemplate();
    setEditorOpen(true);
  };

  const handleClose = () => {
    setEditorOpen(false);
  };
  return (
    <>
      <Head>Receipt Templates</Head>
      <div className='row head'>
        <FilterForm initialValues={filter} onSubmit={handleSubmit}>
          <Input
            name='query'
            placeholder='Search Receipt Templates'
            startAdornment={<SearchIcon />}
          />
          <RadioGroup name='status' row>
            <Radio value='Active' label='Active' />
            <Radio value='Inactive' label='Inactive' />
            <Radio value='All' label='All' />
          </RadioGroup>
          <Button type='submit' variant='text'>
            Search
          </Button>
        </FilterForm>
        <Button startIcon={<AddCircleOutlineIcon />} onClick={handleAdd} disabled={!hasReceiptTemplateWriteAccess}>
          Add Receipt Template
        </Button>
      </div>
      <Table rows={rows} columns={columns} />
      <ReceiptTemplateDialog
        open={editorOpen}
        onClose={handleClose}
        receiptTemplate={receiptTemplate}
      />
    </>
  );
}

export default TemplateList;
