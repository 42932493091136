import moment from 'moment';

interface FormatNumberOptions extends Intl.NumberFormatOptions {
  locals?: string | string[];
}

interface FormatDateOptions extends Omit<Intl.DateTimeFormat, 'format' | 'resolvedOptions' | 'formatToParts' | 'formatRange' | 'formatRangeToParts'> {
  locals?: string | string[];
  format?: Intl.DateTimeFormat['format'];
  resolvedOptions?: Intl.DateTimeFormat['resolvedOptions'];
  formatToParts?: Intl.DateTimeFormat['formatToParts'];
  formatRange?: Intl.DateTimeFormat['formatRange'];
  formatRangeToParts?: Intl.DateTimeFormat['formatRangeToParts'];
}

export const formatNumberToCurrencyAmount = (value: number, options: FormatNumberOptions = {}) => {
  if (!value) {
    value = 0;
  }

  const { locals, ...rest } = options;
  return new Intl.NumberFormat(locals || 'en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 2,
    ...rest,
  }).format(value);
};

export const formatTimestampToDate = (value: Date | number, options: FormatDateOptions = {}) => {
  if (!value) {
    return '';
  }
  value = new Date(value);
  const { locals, ...rest } = options;
  const formattedDate = new Intl.DateTimeFormat(locals || 'en-IN', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    ...rest,
  }).format(value).split(' ');
  return `${formattedDate[0]} ${formattedDate[1]}, ${formattedDate[2]}`;
};

const currencyFormatter = Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR'
});

const currencyFormat = (value: number) => {
  if (!value) {
    return value;
  }

  return currencyFormatter.format(value).replace(/₹/, '₹ ');
};

const dateTimeFormat = (value: Date | string | number, format: 'date' | 'time' | 'datetime') => {
  if (!value) {
    return value;
  }

  if (format === 'date') {
    return moment(value).format('DD MMM yyyy');
  }

  if (format === 'datetime') {
    return moment(value).format('MMM DD, yyyy hh:mm A');
  }

  if (format === 'time') {
    return moment(value).format('hh:mm A');
  }
};

const getAvatarText = (value: string) => value.match(/^\s*(\w)|\b(\w)(?=\S+$)/g)?.join('');

export function createCSVBlobURL(csvData: string[][] | string) {
  const csvString = typeof csvData === 'string' ? csvData : csvData.join('\n');
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  return URL.createObjectURL(blob);
}

function validateDateFormat(dateString) {
  const datePattern = /^\d{4}-\d{2}-\d{2}$/;

  if (!datePattern.test(dateString)) {
    throw new Error('Invalid date format. Please use YYYY-MM-DD.');
  }

  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    throw new Error('Invalid date. Please check the values.');
  }

  return date;
}

export { currencyFormat, dateTimeFormat, getAvatarText, validateDateFormat };
