import { Attribute } from 'models';
import { validateDateFormat } from './string-utils';

export function validateMetadata(
  metadata: any[],
  attributes: Attribute[]
): { errors: string[], validatedMetadata: Record<string, any> } {
  const errors: string[] = [];
  const validatedMetadata: Record<string, any> = {};
  const currentYear = new Date().getFullYear();
  for (const item of metadata) {
    const attribute = attributes.find(attr => attr.code === item.key);

    if (attribute) {
      if (attribute.data_type === 'Date') {
        try {
          const dateValue = validateDateFormat(item.value);
          const year = dateValue.getFullYear();
          if (year >= currentYear) {
            errors.push(`The value for ${attribute.label} must be less than the current year (${currentYear}).`);
          }
        } catch (error) {
          errors.push(`Invalid date for ${attribute.label}: ${error.message}`);
        }
      }
      const isRequired = attribute.usages.some(usage => usage.is_required);
      if (isRequired && !item.value) {
        errors.push(`${attribute.name} is required.`);
      }
      validatedMetadata[item.key] = item.value;
    }
  }

  return { errors, validatedMetadata };
}

export const BEHAVIOR_ACTIONS = {
  StudentCreate: 1,
  StudentUpdate: 2,
  StudentDisable: 3,
  StudentRead: 4,
  StudentEnable: 53,
  StudentImport: 56,
  StudentBulkUpdate: 57,
  AttributeCreate: 5,
  AttributeUpdate: 6,
  AttributeRead: 7,
  AttributeEnable: 51,
  AttributeDisable: 52,
  ReceiptTemplateCreate: 8,
  ReceiptTemplateUpdate: 9,
  ReceiptTemplateRead: 10,
  ReceiptTemplateEnable: 49,
  ReceiptTemplateDisable: 50,
  BankAccountCreate: 11,
  BankAccountUpdate: 12,
  BankAccountRead: 13,
  BankAccountEnable: 38,
  BankAccountDisable: 39,
  FeeTypeCreate: 14,
  FeeTypeUpdate: 15,
  FeeTypeRead: 16,
  FeeTypeEnable: 40,
  FeeTypeDisable: 41,
  FeeRuleCreate: 17,
  FeeRuleUpdate: 18,
  FeeRuleRead: 19,
  FeeRuleEnable: 42,
  FeeRuleDisable: 43,
  FeesRead: 54,
  FeesCreate: 20,
  FeesDelete: 21,
  FeesFinalise: 22,
  FeesVoid: 23,
  FeesRefund: 24,
  FeesExtendfeesduedate: 25,
  FeesWaivefeesfine: 26,
  FeesUpdate: 58,
  FeesImport: 59,
  ReportingReportRead: 27,
  ConcessionCreate: 28,
  ConcessionRead: 29,
  ConcessionUpdate: 30,
  ConcessionApprove: 31,
  ConcessionDelete: 32,
  ConcessionCancel: 55,
  ReceiptCreate: 33,
  ReceiptVoid: 34,
  ReceiptRead: 35,
  SettingsRead: 36,
  SettingsUpdate: 37,
  LateChargeCreate: 44,
  LateChargeUpdate: 45,
  LateChargeRead: 46,
  LateChargeEnable: 47,
  LateChargeDisable: 48,
  ScholarshipRead: 60,
  ScholarshipCreate: 61,
  ScholarshipUpdate: 62,
  ScholarshipAward: 63,
  ScholarshipApprove: 64,
  ScholarshipDelete: 65,
  ScholarshipCancel: 66,
};
