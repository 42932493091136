import { create } from 'zustand';
import { createReceiptTemplate, getReceiptTemplate, listReceiptTemplates, updateReceiptTemplate, enableReceiptTemplate, disableReceiptTemplate } from 'gateways/receipt-template';
import { ReceiptTemplate, ReceiptTemplateOptions } from 'models';

type State = {
  receiptTemplates: ReceiptTemplate[]
  receiptTemplate?: ReceiptTemplate
}

type Action = {
  fetchReceiptTemplates: () => Promise<void>
  createReceiptTemplate: (options: ReceiptTemplateOptions) => Promise<void>
  updateReceiptTemplate: (options: ReceiptTemplateOptions, receiptTemplateId: string) => Promise<void>
  fetchReceiptTemplate: (receiptTemplateId: string) => Promise<void>
  enableReceiptTemplate: (ReceiptTemplateId: string) => Promise<void>
  disableReceiptTemplate: (ReceiptTemplateId: string) => Promise<void>
  resetReceiptTemplate: () => void
}

const useReceiptTemplateStore = create<State & Action>()((set, get) => ({
  receiptTemplates: [],
  fetchReceiptTemplates: async () => {
    const receiptTemplates = await listReceiptTemplates() || [];
    set(() => ({ receiptTemplates }));
  },
  createReceiptTemplate: async (options: ReceiptTemplateOptions) => {
    const receiptTemplate = await createReceiptTemplate(options);
    const receiptTemplates = get().receiptTemplates.slice();
    receiptTemplates.push(receiptTemplate);
    set(() => ({ receiptTemplate, receiptTemplates }));
  },
  updateReceiptTemplate: async (options: ReceiptTemplateOptions, receiptTemplateId: string) => {
    const receiptTemplate = await updateReceiptTemplate(options, receiptTemplateId);
    const receiptTemplates = get().receiptTemplates.slice();
    receiptTemplates.splice(receiptTemplates.findIndex(x => x.receipt_template_id === receiptTemplateId), 1, receiptTemplate);
    set(() => ({ receiptTemplate, receiptTemplates }));
  },
  fetchReceiptTemplate: async (receiptTemplateId: string) => {
    const receiptTemplate = await getReceiptTemplate(receiptTemplateId);
    set(() => ({ receiptTemplate }));
  },
  enableReceiptTemplate: async (ReceiptTemplateId: string) => {
    await enableReceiptTemplate(ReceiptTemplateId);
  },
  disableReceiptTemplate: async (ReceiptTemplateId: string) => {
    await disableReceiptTemplate(ReceiptTemplateId);
  },
  resetReceiptTemplate: () => set(() => ({ receiptTemplate: null }))
}));

export { useReceiptTemplateStore };
