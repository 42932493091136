import { GradeLevel, GradeLevelOptions } from 'models';
import { getRequest, putRequest, sendRequest } from './gateway';

export function listGradeLevels() {
  const fetchPromise = getRequest<GradeLevel[]>('/api/v1/grade-level/list');

  return sendRequest(fetchPromise);
}

export function enableGradeLevel(gradeLevel: GradeLevelOptions, gradeLevelId: number) {
  const putPromise = putRequest(`/api/v1/grade-level/${gradeLevelId}/enable`, gradeLevel);
  return sendRequest(putPromise);
}

export function disableGradeLevel(gradeLevelId: number) {
  const putPromise = putRequest(`/api/v1/grade-level/${gradeLevelId}/disable`, null);
  return sendRequest(putPromise);
}
