import { Head } from 'views/components/styled';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import ToysIcon from '@mui/icons-material/Toys';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import BookIcon from '@mui/icons-material/Book';
import SchoolIcon from '@mui/icons-material/School';
import AbcIcon from '@mui/icons-material/Abc';
import ScienceIcon from '@mui/icons-material/Science';
import CalculateIcon from '@mui/icons-material/Calculate';
import QuizIcon from '@mui/icons-material/Quiz';
import PsychologyIcon from '@mui/icons-material/Psychology';
import InsightsIcon from '@mui/icons-material/Insights';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import ExploreIcon from '@mui/icons-material/Explore';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { useGradeLevelStore, useTermStore } from 'store';
import { useEffect, useState } from 'react';
import { GradeLevel } from 'models';
import { GradeLevelDialog } from 'views/dialogs';

export default function GradeLevelList() {
  const { gradeLevels, fetchGradeLevels } = useGradeLevelStore();
  const { terms, fetchTerms } = useTermStore();

  useEffect(() => {
    if (!gradeLevels.length) {
      fetchGradeLevels();
    }

    if (!terms.length) {
      fetchTerms();
    }
  }, []);

  return (
    <>
      <Head>Grade Levels</Head>
      <List>
        {gradeLevels?.map(gradeLevel => (
          <GradeLevelElem gradeLevel={gradeLevel} key={gradeLevel.grade_level_id} />
        ))}
      </List>
    </>
  );
}

function GradeLevelElem({ gradeLevel }: { gradeLevel: GradeLevel }) {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const { disableGradeLevel } = useGradeLevelStore();

  const handleChange = () => {
    if (gradeLevel.is_active) {
      disableGradeLevel(gradeLevel.grade_level_id);
    } else {
      setDialogOpen(true);
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const getDescription = () => {
    let description = descriptions[gradeLevel.grade_level_id];

    if (gradeLevel.is_active) {
      const terms = gradeLevel.terms.length;
      description += ` ${terms} Term${terms > 1 ? 's' : ''}`;
    }

    return description;
  };

  return (
    <>
      <ListItem>
        <ListItemIcon>
          {icons[gradeLevel.grade_level_id]}
        </ListItemIcon>
        <ListItemText
          primary={gradeLevel.name}
          secondary={getDescription()}
        />
        <Switch
          edge='end'
          checked={gradeLevel.is_active}
          onChange={handleChange}
        />
      </ListItem>
      <GradeLevelDialog
        open={isDialogOpen}
        onClose={handleClose}
        gradeLevel={gradeLevel}
      />
      <Divider />
    </>
  );
}

const icons = {
  1: <ChildFriendlyIcon />,
  2: <ToysIcon />,
  3: <EmojiPeopleIcon />,
  4: <BookIcon />,
  5: <SchoolIcon />,
  6: <AbcIcon />,
  7: <ScienceIcon />,
  8: <CalculateIcon />,
  9: <QuizIcon />,
  10: <PsychologyIcon />,
  11: <InsightsIcon />,
  12: <AutoStoriesIcon />,
  13: <ExploreIcon />,
  14: <LightbulbIcon />,
  15: <TrendingUpIcon />,
  16: <SchoolIcon />,
};

const descriptions = {
  1: 'Introduction to early childhood learning with play-based activities.',
  2: 'Foundation in social, motor, and basic cognitive skills through structured play.',
  3: 'Early learning in letters, numbers, and social interaction.',
  4: 'Development of basic reading, writing, and math skills.',
  5: 'Introduction to formal schooling with focus on literacy and basic mathematics.',
  6: 'Strengthening foundational skills in reading, writing, and arithmetic.',
  7: 'Expanding knowledge in core subjects like science, social studies, and language.',
  8: 'Deeper exploration of subjects and introduction to problem-solving techniques.',
  9: 'Preparing for middle school with enhanced critical thinking and subject knowledge.',
  10: 'Transition to middle school with advanced learning in core subjects.',
  11: 'Building knowledge in complex subjects and fostering independent learning.',
  12: 'Preparation for high school with advanced critical thinking and subject mastery.',
  13: 'Introduction to high school subjects and early career exploration.',
  14: 'Core academic preparation with emphasis on subject specialization.',
  15: 'Advanced studies with focus on career paths and higher education preparation.',
  16: 'Final year of secondary education, preparing for college or vocational careers.'
};
