import { Receipt } from 'models';
import { useEffect } from 'react';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useStudentStore } from 'store/student';
import { Column, Table } from 'views/components/elements';

const StudentPayments = () => {
  const { fetchReceipts, receipts } = useStudentStore();
  const { studentId } = useParams();

  useEffect(() => {
    fetchReceipts(studentId);
  }, [studentId]);

  const columns = [
    { field: 'receipt_number', label: 'Receipt number' },
    { field: 'amount', label: 'amount', width: 80 },
    { field: 'receipt_type', label: 'payment', width: 120 },
    { field: 'receipt_status', label: 'status', width: 120 },
    {
      field: 'created_date_time',
      label: 'created',
      format: 'date',
      width: 120,
    },
    {
      field: 'action',
      valueGetter: row =>
        row.receipt_file_location && (
          <IconButton href={row.receipt_file_location} target='_blank'>
            <CloudDownloadIcon />
          </IconButton>
        ),
      width: 80,
    },
  ] as Column<Receipt>[];

  return (
    <div>
      {receipts?.length > 0 && <Table rows={receipts} columns={columns} stickyHeader />}
    </div>
  );
};

export default StudentPayments;
