import { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import { useFeeTypeStore } from 'store/fee-type';
import {
  Table,
  Column,
  Input,
  RadioGroup,
  Radio,
  Button,
} from 'views/components/elements';
import { Active, Head, Inactive, FilterForm } from 'views/components/styled';
import { FeeType } from 'models';
import { FeeTypeDialog } from 'views/dialogs';
import { useAttributeStore, useAccountStore } from 'store';
import { BEHAVIOR_ACTIONS } from 'views/shared/utils/validation-utils';


function FeeTypeList() {
  const { hasAccess } = useAccountStore();
  const hasFeeTypewriteAccess = hasAccess(BEHAVIOR_ACTIONS.FeeTypeCreate);
  const hasFeeTypeEditAccess = hasAccess(BEHAVIOR_ACTIONS.FeeTypeUpdate);

  const columns = [
    { field: 'name', label: 'name' },
    { field: 'bank_account_name', label: 'bank account', width: 250 },
    { field: 'receipt_template_name', label: 'receipt template', width: 250 },
    {
      field: 'created_date_time',
      label: 'created',
      format: 'date',
      width: 120,
    },
    {
      field: 'is_active',
      label: 'status',
      valueGetter: row =>
        row.is_active ? <Active>Active</Active> : <Inactive>Inactive</Inactive>,
      width: 120,
    },
    {
      field: 'action',
      label: '',
      valueGetter: row => (
        <IconButton disabled={!hasFeeTypeEditAccess} onClick={createEditHandler(row)}>
          <EditIcon />
        </IconButton>
      ),
      width: 80,
    },
  ] as Column<FeeType>[];

  const { feeTypes, fetchFeeTypes, fetchFeeType, feeType, resetFeeType } =
    useFeeTypeStore();
  const { attributes, fetchAttributes } = useAttributeStore();

  useEffect(() => {
    if (!feeTypes.length) {
      fetchFeeTypes();
    }
    if (!attributes.length) {
      fetchAttributes();
    }
  }, []);

  const createEditHandler = (feeType: FeeType) => async () => {
    await fetchFeeType(feeType.fee_type_id);
    setEditorOpen(true);
  };

  const [filter, setFilter] = useState({ query: '', status: 'All' });
  const [editorOpen, setEditorOpen] = useState(false);

  const handleSubmit = (values: typeof filter) => {
    setFilter(values);
  };

  const rows = feeTypes.filter(x => {
    return (
      (!filter.query ||
        x.name.toLowerCase().includes(filter.query.toLowerCase())) &&
      (filter.status === 'All' || x.is_active === (filter.status === 'Active'))
    );
  });

  const handleAdd = () => {
    resetFeeType();
    setEditorOpen(true);
  };

  const handleClose = () => {
    setEditorOpen(false);
  };

  return (
    <>
      <Head>Fee Types</Head>
      <div className='row head'>
        <FilterForm initialValues={filter} onSubmit={handleSubmit}>
          <Input
            name='query'
            placeholder='Search Fee Types'
            startAdornment={<SearchIcon />}
          />
          <RadioGroup name='status' row>
            <Radio value='Active' label='Active' />
            <Radio value='Inactive' label='Inactive' />
            <Radio value='All' label='All' />
          </RadioGroup>
          <Button type='submit' variant='text'>
            Search
          </Button>
        </FilterForm>
        <Button disabled={!hasFeeTypewriteAccess} startIcon={<AddCircleOutlineIcon />} onClick={handleAdd}>
          Add Fee Type
        </Button>
      </div>
      <Table rows={rows} columns={columns} />
      <FeeTypeDialog
        open={editorOpen}
        onClose={handleClose}
        feeType={feeType}
      />
    </>
  );
}

export default FeeTypeList;
