import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Action, ScrollablePage } from 'views/components/styled';
import { Receipt } from 'models';
import { useReceiptStore } from 'store/receipt';
import { Button, Column, Form, Input, Table } from 'views/components/elements';
import DropDownFilter from 'views/components/elements/DropDownFilter';
import { useAccountStore } from 'store';
import { BEHAVIOR_ACTIONS } from 'views/shared/utils/validation-utils';

const statuses = [
  { key: 'Captured', value: 'Captured' },
  { key: 'PartiallyRefunded', value: 'Partially Refunded' },
  { key: 'Refunded', value: 'Refunded' },
  { key: 'Disputed', value: 'Disputed' },
  { key: 'Voided', value: 'Voided' },
];

const types = [
  { key: 'Cash', value: 'Cash' },
  { key: 'Cheque', value: 'Cheque' },
  { key: 'DemandDraft', value: 'Demand Draft' },
  { key: 'NetBanking', value: 'Net Banking' },
  { key: 'CreditCard', value: 'Credit Card' },
  { key: 'DebitCard', value: 'Debit Card' },
  { key: 'UPI', value: 'UPI' },
  { key: 'MobileWallet', value: 'Mobile Wallet' },
];

function ReceiptList() {
  const { receipts, fetchReceipts, hasNextPage, filter } = useReceiptStore();
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedTypeKeys, setSelectedTypesKeys] = useState([]);
  const { hasAccess } = useAccountStore();
  const hasReceiptCreateAccess = hasAccess(BEHAVIOR_ACTIONS.ReceiptCreate);

  useEffect(() => {
    if (!receipts.length) {
      fetchReceipts({ statuses: ['Captured'] });
    }
  }, []);

  const columns = [
    { field: 'receipt_number', label: 'receipt No.', width: 120 },
    { field: 'amount', label: 'amount', format: 'currency', width: 150 },
    { field: 'receipt_type', label: 'type', width: 120 },
    { field: 'roll_number', label: 'roll No.', width: 120, sortable: true },
    { field: 'student_name', label: 'name', sortable: true },
    { field: 'receipt_status', label: 'status', width: 120 },
    {
      field: 'created_date_time',
      label: 'created',
      format: 'datetime',
      width: 180,
      sortable: true,
    },
    {
      field: 'action',
      valueGetter: row =>
        row.receipt_file_location && (
          <IconButton href={row.receipt_file_location} target='_blank'>
            <CloudDownloadIcon />
          </IconButton>
        ),
      width: 80,
    },
  ] as Column<Receipt>[];

  const handleSubmit = (values: typeof filter) => {
    const newFilter = Object.assign({}, filter, {
      page: 1,
      query: values.query,
      statuses: selectedStatus,
      types: selectedTypeKeys,
    });

    fetchReceipts(newFilter);
  };

  const handleSort = (field: string, order: string) => {
    const newFilter = Object.assign({}, filter, {
      sortColumn: field,
      sortDirection: order,
      page: 1,
    });

    fetchReceipts(newFilter);
  };

  const handleNextPage = () => {
    const newFilter = Object.assign({}, filter, {
      page: filter.page + 1,
    });

    fetchReceipts(newFilter);
  };

  const filterValues = {
    query: filter.query || '',
    statuses: filter.statuses || [],
    types: filter.types || [],
  };

  const handleStatusFilter = (selectedItems: string[]) =>{
    const selectedKeys = selectedItems.map(item => statuses.find(status => status.value === item)?.key || item);
    setSelectedStatuses([...selectedItems]);
    setSelectedStatus([...selectedKeys]);
  };
  const handleTypesFilter = (selectedItems: string[]) =>{
    const selectedKeys = selectedItems.map(item => types.find(status => status.value === item)?.key || item);
    setSelectedTypes([...selectedItems]);
    setSelectedTypesKeys([...selectedKeys]);
  };
  return (
    <ScrollablePage>
      <h1>Receipts</h1>
      <Action>
        <Form
          initialValues={filterValues}
          onSubmit={handleSubmit}
          validateOnChange={false}
        >
          <Input
            name='query'
            placeholder='Search Receipts'
            startAdornment={<SearchIcon />}
          />
          <DropDownFilter
            onChange={handleStatusFilter}
            selectedFields={selectedStatuses.map(status => statuses.find(s => s.value === status)?.value || status)}
            inputFields={statuses.map(res => res.value)}
            label='Statuses'
          />
          <DropDownFilter
            onChange={handleTypesFilter}
            selectedFields={selectedTypes.map(status => types.find(s => s.value === status)?.value || status)}
            inputFields={types.map(res => res.value)}
            label='Types'
          />
          <Button type='submit' variant='text'>
            Search
          </Button>
        </Form>
        <div>
          <Button
            startIcon={<AddCircleOutlineIcon />}
            component={NavLink}
            to='new'
            disabled={!hasReceiptCreateAccess}
          >
            Add Receipt
          </Button>
        </div>
      </Action>
      <Table
        rows={receipts}
        columns={columns}
        stickyHeader
        infiniteScroll
        hasMoreRows={hasNextPage}
        sortingField={filter.sortColumn}
        sortingOrder={filter.sortDirection}
        onSortChange={handleSort}
        onNextPage={handleNextPage}
      />
    </ScrollablePage>
  );
}

export default ReceiptList;
