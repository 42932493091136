import { Student } from 'models';
import { currencyFormat, getAvatarText } from 'views/shared/utils/string-utils';
import {
  FlexColumn,
  FlexRow,
  Avatar,
  Title,
  AmountLabel,
  Subtitle,
  VerticalDivider,
} from './styled';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import AttributesPopover from './elements/AttributesPopover';

const StyledFlexRow = styled(FlexRow)({
  display: 'block',
});


const StudentHeader = (props: Student) => {
  const avatarText = getAvatarText(`${props.first_name} ${props.last_name}`);
  const { captured_legacy, captured_offline, captured_online } = props;
  const paidAmount = captured_legacy ?? 0 + captured_offline ?? 0 + captured_online ?? 0;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsPopoverOpen(true);
  };

  const open = Boolean(anchorEl) && isPopoverOpen;

  return (
    <FlexRow align='start' size='lg'>
      <StyledFlexRow>
        <FlexRow onMouseEnter={handlePopoverOpen}
          onMouseLeave={() => setIsPopoverOpen(false)} style={{ cursor: 'pointer' }}>
          <Avatar>{avatarText}</Avatar>
          <FlexColumn size='xs' style={{ width: 'min-content' }}>
            <Title>
              {props.first_name} {props.last_name}
            </Title>
            <span>
              {props.roll_number}
            </span>
          </FlexColumn>
        </FlexRow>
        <AttributesPopover
          open={open}
          anchorEl={anchorEl}
          onClose={() => setIsPopoverOpen(false)}
          student={props}
          onMouseEnter={() => setIsPopoverOpen(true)}
          onMouseLeave={() => setIsPopoverOpen(false)}
        />
      </StyledFlexRow>
      <FlexColumn size='md'>
        <Title>
          Insights
        </Title>
        <FlexRow size='lg'>
          <FlexColumn>
            <Subtitle>TOTAL FEES</Subtitle>
            <AmountLabel>{currencyFormat(props.total_amount)}</AmountLabel>
          </FlexColumn>
          <VerticalDivider />
          <FlexColumn>
            <Subtitle>CONCESSION</Subtitle>
            <AmountLabel>{currencyFormat(props.concession_amount)}</AmountLabel>
          </FlexColumn>
          <VerticalDivider />
          <FlexColumn>
            <Subtitle>SCHOLARSHIP</Subtitle>
            <AmountLabel>{currencyFormat(props.scholarship_amount)}</AmountLabel>
          </FlexColumn>
          <VerticalDivider />
          <FlexColumn>
            <Subtitle>FEES PAID</Subtitle>
            <AmountLabel>{currencyFormat(paidAmount)}</AmountLabel>
          </FlexColumn>
          <VerticalDivider />
          <FlexColumn>
            <Subtitle>BALANCE</Subtitle>
            <AmountLabel>{currencyFormat(props.amount_capturable)}</AmountLabel>
          </FlexColumn>
        </FlexRow>
      </FlexColumn>
    </FlexRow>
  );
};

export default StudentHeader;
