import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { styled } from '@mui/material/styles';
import { BankAccount, BankAccountOptions } from 'models';
import { Button, Form, Input } from 'views/components/elements';
import { useBankAccountStore } from 'store/bank-account';
import { ToggleButton } from './Elements';
import { useAccountStore } from 'store';
import { BEHAVIOR_ACTIONS } from 'views/shared/utils/validation-utils';

const Head = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingRight: 24,
});

const Actions = styled(DialogActions)({
  justifyContent: 'space-between',
  padding: '16px 24px',
});

const Content = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 16,
});

const FormField = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 8,
  '& label': {
    fontSize: 14,
  },
});

type BankAccountDialogProps = {
  open: boolean;
  onClose: () => void;
  bankAccount?: BankAccount;
};

export default function BankAccountDialog(props: BankAccountDialogProps) {
  const { createBankAccount, updateBankAccount, deleteBankAccount, enableBankAccount, disableBankAccount, fetchBankAccounts } =
    useBankAccountStore();
  const { hasAccess } = useAccountStore();
  const hasBankAccountEnableAccess = hasAccess(BEHAVIOR_ACTIONS.BankAccountEnable);
  const hasBankAccountDisableAccess = hasAccess(BEHAVIOR_ACTIONS.BankAccountDisable);
  

  const isEdit = Boolean(props.bankAccount?.bank_account_id);
  const bankAccount = {
    name: props.bankAccount?.name,
    account_id: props.bankAccount?.account_id,
  };

  const handleSubmit = async (values: typeof bankAccount) => {
    const options = {
      name: values.name,
      account_id: values.account_id,
    } as BankAccountOptions;

    if (isEdit) {
      await updateBankAccount(options, props.bankAccount.bank_account_id);
    } else {
      await createBankAccount(options);
    }

    props.onClose();
  };

  const handleDelete = async () => {
    await deleteBankAccount(props.bankAccount.bank_account_id);

    props.onClose();
  };

  const handleToggle = async ()=>{
    if(props.bankAccount.is_active){
      await disableBankAccount(props.bankAccount.bank_account_id);
    }else{
      await enableBankAccount(props.bankAccount.bank_account_id);
    }
    props.onClose();
    fetchBankAccounts();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth='sm' fullWidth>
      <Head>
        <DialogTitle>{isEdit ? 'Edit' : 'Add'} Bank Account</DialogTitle>
        <IconButton onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </Head>
      <Form initialValues={bankAccount} onSubmit={handleSubmit}>
        <Content>
          <FormField>
            <label>Name</label>
            <Input name='name' placeholder='Name' />
          </FormField>
          <FormField>
            <label>Account Id</label>
            <Input name='account_id' placeholder='Account Id' />
          </FormField>
        </Content>
        <Actions>
          <div>
            <ToggleButton
              isEdit={isEdit}
              isActive={props.bankAccount?.is_active}
              onClick={handleToggle}
              disabled={!(props?.bankAccount?.is_active ? hasBankAccountDisableAccess: hasBankAccountEnableAccess)}
            />
          </div>
          <div>
            {isEdit && (
              <Button
                startIcon={<DeleteOutlineIcon />}
                variant='text'
                color='error'
                onClick={handleDelete}
              >
                Delete
              </Button>
            )}
          </div>
          <div>
            <Button type='submit'>Save Changes</Button>
            <Button variant='text' color='secondary' onClick={props.onClose}>
              Cancel
            </Button>
          </div>
        </Actions>
      </Form>
    </Dialog>
  );
}
