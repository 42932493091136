import { Student, StudentCreateOptions, StudentListParams, StudentUpdateOptions } from '../models/Student';
import { sendRequest, getRequest, postRequest, putRequest } from './gateway';
import { ImportResult } from '../models/ImportResult';
import { Paged } from '../models/Pagination';

function getStudent(studentId: string) {
  const fetchPromise = getRequest<Student>(`/api/v1/student/${studentId}`);

  return sendRequest(fetchPromise);
}

function createStudent(student: StudentCreateOptions) {
  const postPromise = postRequest<Student, StudentCreateOptions>('/api/v1/student', student);

  return sendRequest(postPromise);
}

function updateStudent(student: StudentUpdateOptions, studentId: string) {
  const putPromise = putRequest<Student, StudentUpdateOptions>(`/api/v1/student/${studentId}`, student);

  return sendRequest(putPromise);
}

async function listStudents(params: StudentListParams = {}, skipSpinner = false) {
  if (!params) {
    params = {};
  }
  params.sortColumn ??= 'roll_number';
  params.sortDirection ??= 'desc';
  params.limit ??= 100;
  params.page ??= 1;

  const fetchPromise = getRequest<Paged<Student>>('/api/v1/student/list', { params, skipSpinner });

  return (await sendRequest(fetchPromise));
}

function importStudents(fileLocation: string) {
  const postPromise = postRequest<ImportResult>('/api/v1/student/import', {}, { params: { fileLocation } });

  return sendRequest(postPromise);
}

function enableStudent(studentId: string) {
  const putPromise = putRequest(`/api/v1/student/${studentId}/enable`,null);
  return sendRequest(putPromise);
}

function disableStudent(studentId: string) {
  const putPromise = putRequest(`/api/v1/student/${studentId}/disable`,null);
  return sendRequest(putPromise);
}

export { getStudent, createStudent, updateStudent, listStudents, importStudents, enableStudent, disableStudent };
