import { createAttribute, getAttribute, listAttributes, updateAttribute, disableAttribute, enableAttribute } from 'gateways/attribute';
import { Attribute, AttributeCreateOptions, AttributeUpdateOptions } from 'models';
import { create } from 'zustand';

type State = {
  attributes: Attribute[]
  attribute?: Attribute
}

type Action = {
  fetchAttributes: () => Promise<void>
  createAttribute: (options: AttributeCreateOptions) => Promise<void>
  updateAttribute: (options: AttributeUpdateOptions, attributeId: string) => Promise<void>
  fetchAttribute: (attributeId: string) => Promise<void>,
  resetAttribute: () => void,
  disableAttribute: (attributeId: string) => Promise<void>,
  enableAttribute: (attributeId: string) => Promise<void>,
}

export const useAttributeStore = create<State & Action>()((set, get) => ({
  attributes: [],
  fetchAttributes: async () => {
    const attributes = await listAttributes() || [];
    set({ attributes });
  },
  createAttribute: async (options: AttributeCreateOptions) => {
    const attribute = await createAttribute(options);
    set((state) => ({
      attribute,
      attributes: state.attributes.slice().concat(attribute)
    }));
  },
  updateAttribute: async (options: AttributeUpdateOptions, attributeId: string) => {
    const attribute = await updateAttribute(options, attributeId);

    const attributes = get().attributes.slice();
    attributes.splice(attributes.findIndex(x => x.attribute_id === attributeId), 1, attribute);

    set({ attributes, attribute });
  },
  fetchAttribute: async (attributeId: string) => {
    const attribute = await getAttribute(attributeId);
    set({ attribute });
  },
  resetAttribute: () => {
    set({ attribute: null });
  },
  disableAttribute: async (attributeId: string) => {
    await disableAttribute(attributeId);
  },
  enableAttribute: async (attributeId: string) => {
    await enableAttribute(attributeId);
  }
}));
