import { listTerms } from 'gateways/term';
import { Term } from 'models';
import { create } from 'zustand';

type State = {
  terms: Term[]
}

type Action = {
  fetchTerms: () => void
}

export const useTermStore = create<State & Action>()((set) => ({
  terms: [],
  fetchTerms: async () => {
    const terms = await listTerms();
    set({ terms });
  }
}));
