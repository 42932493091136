import { create } from 'zustand';
import { Business, Institute, InstituteUpdateOptions } from 'models';
import { getMyBusinesses, updateInstitute } from 'gateways/institute';

type State = {
  institute?: Institute;
  business?: Business;
  businesses: Business[]
}

type Action = {
  updateInstitute: (institute: InstituteUpdateOptions) => Promise<void>
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useInstituteStore = create<State & Action>()((set, get) => ({
  businesses: [],
  updateInstitute: async (institute: InstituteUpdateOptions) => {
    const updatedInstitute = await updateInstitute(institute);
    set({ institute: updatedInstitute });
  }
}));

async function setInstitute(institute: Institute) {
  useInstituteStore.setState({ institute });
  const businesses = await getMyBusinesses();
  const business = businesses.find(b => b.business_id.toLowerCase() === institute?.institute_id?.toLowerCase());
  useInstituteStore.setState({ businesses, business });
}

export { useInstituteStore, setInstitute };
