import Button, { IconButtonProps } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

export default function IconButton(props: IconButtonProps) {
  const { title, disabled, ...rest } = props;

  return (
    <>
      {!disabled && (
        <Tooltip title={title}>
          <Button {...rest} />
        </Tooltip>
      )}
      {disabled && (<Button disabled={true} {...rest} />)}
    </>
  );
}
