import { Business, Institute, InstituteUpdateOptions } from 'models';
import { sendRequest, getRequest, putRequest } from './gateway';

function getInstitute() {
  const fetchPromise = getRequest<Institute>('/api/v1/institute');

  return sendRequest(fetchPromise);
}

function updateInstitute(institute: InstituteUpdateOptions) {
  const putPromise = putRequest<Institute>('/api/v1/institute', institute);

  return sendRequest(putPromise);
}

function getMyBusinesses() {
  const fetchPromise = getRequest<Business[]>('/admin/api/v1/business/list/me', { skipSpinner: true });

  return sendRequest(fetchPromise);
}

export { getInstitute, updateInstitute, getMyBusinesses };
