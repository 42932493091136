import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import { ConcessionListView } from 'models';
import { Head } from './Elements';
import { currencyFormat } from 'views/shared/utils/string-utils';
import { Button, Form, Textarea } from 'views/components/elements';
import { FormField } from 'views/components/styled';
import { useConcessionStore } from 'store';
import { useState } from 'react';

const Key = styled('td')({
  fontSize: 14,
  paddingRight: 8,
  paddingBottom: 8
});

const Value = styled('td')({
  fontSize: 14,
  paddingBottom: 8,
  fontWeight: 600
});

const Title = styled('div')({
  marginBottom: 16
});

const ErrorAlert = styled(Alert)({
  marginTop: 8
});

type ConcessionCancelDialogProps = {
  open: boolean
  concession: ConcessionListView
  onClose: () => void
}

const ConcessionCancelDialog = (props: ConcessionCancelDialogProps) => {
  const { cancelResult, cancelConcession } = useConcessionStore();
  const [errors, setErrors] = useState([]);

  const cancelForm = {
    reason: ''
  };

  const handleSubmit = async (values: typeof cancelForm) => {
    setErrors([]);

    const options = {
      reason: values.reason
    };

    if (!options.reason) {
      setErrors(['Please specify a reason']);
      return;
    }

    const result = await cancelConcession(options, props.concession.concession_id);

    if (result.succeeded) {
      props.onClose();
    }
  };

  const hasErrors = Boolean(cancelResult?.errors?.length || errors.length);

  return (
    <Dialog open={props.open}>
      <Head>
        <DialogTitle>Are you sure you want to cancel this concession?</DialogTitle>
        <IconButton onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </Head>
      <Form initialValues={cancelForm} onSubmit={handleSubmit}>
        <DialogContent>
          <Title>Please confirm that you want to cancel the concession with the following details:</Title>
          {props.concession && (
            <div>
              <table>
                <tbody>
                  <tr>
                    <Key>Student</Key>
                    <Value>{props.concession.first_name} {props.concession.last_name} {props.concession.roll_number}</Value>
                  </tr>
                  <tr>
                    <Key>Concession amount</Key>
                    <Value>{currencyFormat(props.concession.concession_amount)}</Value>
                  </tr>
                  <tr>
                    <Key>Notes</Key>
                    <Value>{props.concession.notes}</Value>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          <h5>
            Please carefully review the details before proceeding.
          </h5>
          <h5>
            Do you want to proceed with the cancellation?
          </h5>
          <FormField>
            <label>Reason</label>
            <Textarea name='reason' rows={3} />
          </FormField>
          {hasErrors &&
            cancelResult?.errors?.map((error, index) => (
              <ErrorAlert severity='error' key={index}>
                {error.description}
              </ErrorAlert>
            ))}
          {hasErrors && errors.map((error, index) => (
            <ErrorAlert severity='error' key={index}>
              {error}
            </ErrorAlert>
          ))}
        </DialogContent>
        <DialogActions>
          <Button type='submit' variant='text'>YES</Button>
          <Button variant='text' color='secondary' autoFocus onClick={props.onClose}>NO</Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default ConcessionCancelDialog;
