import * as Yup from 'yup';

// Item Validation Schema
export const itemValidationSchema = Yup.object().shape({
  rate_amount: Yup.number().required('Fine rate is Required')
});

// Main Late Charge Schema
export const LateChargeValidationSchema = Yup.object().shape({
  name: Yup.string().required('Rule Name is Required'),

  min_charge: Yup.number()
    .positive('Minimum charge must be greater than 0')
    .required('Minimum Charge is Required'),

  max_charge: Yup.number()
    .positive('Maximum charge must be greater than 0')
    .required('Maximum charge is required')
    .test(
      'max-greater-than-min',
      'Maximum charge must be greater than minimum charge',
      function (value) {
        return value > this.parent.min_charge;
      }
    ),

  grace_days: Yup.number().required('Grace days are required'),
  items: Yup.array()
    .of(itemValidationSchema)
    .min(1, 'At least one item is required')
});
