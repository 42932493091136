import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { FlexRow } from 'views/components/styled';
import { Button, Form, Input } from 'views/components/elements';
import { Head, Content, Actions, FormField } from './Elements';

interface PositionDialogProps {
  max: number
  open: boolean
  onClose: (value?: number) => void
}

export default function PositionDialog(props: PositionDialogProps) {
  const form = {
    position: ''
  };

  const handleSubmit = (values: typeof form) => {
    props.onClose(+values.position);
  };

  const handleClose = () => {
    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth='xs'>
      <Head>
        <DialogTitle>Move to Position</DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Head>
      <Form
        initialValues={form}
        onSubmit={handleSubmit}
        render={() => (
          <>
            <Content>
              <FormField>
                <label>Enter a value from 1 to {props.max}</label>
                <Input type='number' name='position' required />
              </FormField>
            </Content>
            <Actions>
              <div>
              </div>
              <FlexRow size='xs'>
                <Button type='submit'>OK</Button>
                <Button
                  variant='text'
                  color='secondary'
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </FlexRow>
            </Actions>
          </>
        )}
      />
    </Dialog>
  );
}
