import { useAccountStore } from 'store/account';
import { useInstituteStore } from 'store/institute';
import MaterialIconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import './TopNavigationBar.scss';
import { useState, MouseEvent, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SchoolIcon from '@mui/icons-material/School';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Badge from '@mui/material/Badge';
import { useNavigate } from 'react-router-dom';
import { FlexColumn, FlexRow } from '../styled';
import { useNotificationStore } from 'store/notification';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button } from '../elements';
import { Business } from 'models';
import ChatIcon from '@mui/icons-material/Chat';

const SwitchButton = styled(Button)({
  margin: '-7.5px 0'
});

const IconButton = styled(MaterialIconButton)({ padding: 0 });

const Description = styled('div')({ fontSize: 14 });
const Notification = styled('div')({
  padding: '0 16px 12px',
  '& .MuiDivider-root': {
    marginBottom: 12,
  },
});
const NotificationTitle = styled('div')({
  padding: '16px 16px 24px',
  '& h3': {
    margin: 0,
  },
});

const menuProps = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

function ProfileMenu() {
  const { user } = useAccountStore();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleProfile = () => {
    handleClose();
    window.location.replace(user.authority);
  };
  const handleSettings = () => {
    handleClose();
    navigate('/settings');
  };
  const handleLogout = () => {
    handleClose();
    window.location.replace(user.logoutUrl);
  };

  return (
    <>
      <Tooltip title='Account settings'>
        <IconButton
          size='small'
          disableRipple
          onClick={handleClick}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
        >
          <div className='avatar'>{user?.avatarText}</div>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={menuProps}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleProfile}>
          <Avatar /> My account
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleSettings}>
          <ListItemIcon>
            <Settings fontSize='small' />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleLogout} href={user?.logoutUrl}>
          <ListItemIcon>
            <Logout fontSize='small' />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

function NotificationMenu() {
  const { notifications, remove } = useNotificationStore();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const notificationCount = notifications.length;
  const hasBackgroundProcess = Boolean(
    notifications.filter(x => x.status === 'inprogress').length
  );

  return (
    <>
      <Tooltip title='Give feedback'>
        <IconButton
          size='medium'
          style={{top:'5px'}}
        >
          <a href='mailto:support@aveoninfotech.com?subject=Your%20issue%20or%20any%20feedback&body=Provide%20a%20brief%20description%20of%20your%20issue%20or%20any%20feedback%20that%20you%20have.%20Attachment:%20Supported%20file%20types%20-%20jpg,%20png,%20gif,%20bmp,%20jpeg,%20txt,%20pdf,%20doc' target='_top' style={{color:'#6e6767'}}>
            <ChatIcon />
          </a>
        </IconButton>
      </Tooltip>
      <FlexColumn size='xxs'>
        <Tooltip title='Notifications'>
          <Badge badgeContent={notificationCount} color='primary'>
            <IconButton
              size='medium'
              onClick={handleClick}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
            >
              <NotificationsIcon />
            </IconButton>
          </Badge>
        </Tooltip>
        {hasBackgroundProcess && <LinearProgress sx={{ height: 2 }} />}
      </FlexColumn>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={menuProps}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <NotificationTitle>
          <FlexRow justify='space-between'>
            <h3>Notifications</h3>
            <IconButton aria-label='close' onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </FlexRow>
        </NotificationTitle>
        {notifications.map((item, index) => (
          <Notification key={index}>
            <Divider />
            <FlexColumn>
              <FlexRow justify='space-between'>
                <FlexRow size='xs'>
                  {item.status === 'inprogress' && (
                    <CircularProgress size={16} />
                  )}
                  {item.status === 'success' && (
                    <CheckCircleIcon color='success' />
                  )}
                  <h5>{item.title}</h5>
                </FlexRow>
                <IconButton
                  size='small'
                  aria-label='remove'
                  onClick={() => remove(item.id)}
                >
                  <CloseIcon fontSize='small' />
                </IconButton>
              </FlexRow>
              <Description>{item.description}</Description>
              {item.actions?.length && (
                <FlexRow>
                  {item.actions.map((action, index) => (
                    <Button key={index} href={action.link} target='_blank'>
                      {action.label}
                    </Button>
                  ))}
                </FlexRow>
              )}
            </FlexColumn>
          </Notification>
        ))}
        {!notifications.length && (
          <Notification>
            <FlexColumn align='center' size='md'>
              <NotificationsIcon fontSize='large' />
              <h5>No new notifications from this session</h5>
            </FlexColumn>
          </Notification>
        )}
      </Menu>
    </>
  );
}

function TopNavigationBar() {
  return (
    <div className='nav-container'>
      <div className='nav-left'>
        <div className='brand'>
          <img className='logo' src='/logo.png' alt='logo' />
        </div>
        <TenantSwitch />
      </div>
      <FlexRow>
        <NotificationMenu />
        <ProfileMenu />
      </FlexRow>
    </div>
  );
}

function TenantSwitch() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { institute, businesses } = useInstituteStore();

  const business = businesses?.find(b => b.business_id === institute?.institute_id);
  const hasLogoLink = Boolean(business?.logo_link);
  const hasMultipleAccounts = Boolean(businesses?.length > 1);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (business) {
    const tenantContent = (
      <>
        {hasLogoLink && (
          <img height='38px' src={business.logo_link} />
        )}
        {!hasLogoLink && (
          <SchoolIcon />
        )}
        <span>{business.business_name}</span>
      </>
    );

    if (hasMultipleAccounts) {
      return (
        <>
          <SwitchButton onClick={handleClick} variant='text'>
            <div className='tenant'>
              {tenantContent}
              <ArrowDropDownIcon />
            </div>
          </SwitchButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}>
            {businesses.map(business => (
              <SwitchMenuItem
                business={business}
                key={business.business_id}
                disabled={business.business_id === institute?.institute_id} />
            ))}
          </Menu>
        </>
      );
    }

    return (
      <div className='tenant'>
        {tenantContent}
      </div>
    );
  }

  return (
    <div className='tenant'>
      <SchoolIcon />
      <span>{institute?.name}</span>
    </div>
  );
}

function SwitchMenuItem({ business, disabled }: { business: Business, disabled: boolean }) {
  const formRef = useRef(null);

  const handleMenuClick = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  return (
    <form ref={formRef} action='/Tenant/Switch' method='POST'>
      <input type='hidden' name='Input.BusinessId' value={business.business_id} />
      <MenuItem onClick={handleMenuClick} disabled={disabled}>{business.business_name}</MenuItem>
    </form>
  );
}

export default TopNavigationBar;
