import { styled } from '@mui/material/styles';
import Handlebars from 'handlebars';
import { Field } from 'formik';
import moment from 'moment';

const Frame = styled('iframe')({
  height: 'calc(100vh - 250px)',
  border: 'solid 1px #eeeff2',
  borderRadius: 8,
});

type HtmlPreviewProps = {
  templateField: string;
  dataField: string;
};

export function minifyHTML(html: string) {
  // Remove extra whitespace
  html = html.replace(/[\s]+/g, ' ');

  // Remove empty attributes
  html = html.replace(/<[^>]+=""/g, function (match) {
    return match.replace(/=""/g, '');
  });

  // Remove redundant attributes
  html = html.replace(
    /<[^>]+\s+class\s*=\s*"([^"]+)"/g,
    function (match, className) {
      return match.replace(className, className.split(' ').join(' '));
    }
  );

  // Collapse whitespace
  html = html.replace(/[\s]+/g, ' ');

  return html;
}

function getContent(template: string, data: string) {
  return Handlebars.compile(minifyHTML(template))(JSON.parse(data));
}

export default function HtmlPreview(props: HtmlPreviewProps) {
  return (
    <Field name={props.templateField}>
      {({ field, form }) => (
        <Frame
          srcDoc={getContent(
            form.values[props.templateField],
            form.values[props.dataField]
          )}
          name={field.name}
        />
      )}
    </Field>
  );
}

const formatter = Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
});

function registerHelpers() {
  Handlebars.registerHelper('lower', (value?: string) => value?.toLowerCase());
  Handlebars.registerHelper('upper', (value?: string) => value.toUpperCase());
  Handlebars.registerHelper('proper', (value?: string) =>
    value?.replace(
      /\w\S*/g,
      word => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase()
    )
  );
  Handlebars.registerHelper(
    'date',
    (value?: Date | string, format?: string) => {
      if (!value) return value;

      if (!format || typeof format != 'string') {
        format = 'MMM DD, YYYY hh:mm a';
      }

      return moment(value).format(format);
    }
  );

  Handlebars.registerHelper('currency', (value?: number) => {
    if (!value) return value;

    return formatter.format(value).replace(/₹/, '₹ ');
  });
}

registerHelpers();
