import { getRequest, postRequest, sendRequest } from './gateway';
import { FileDetail } from 'models';

function uploadFile(file: File) {
  const formData = new FormData();
  formData.append('file', file);
  const postPromise = postRequest<FileDetail>('/api/v1/files', formData, { headers: { 'Content-Type': 'multipart/form-data' } });

  return sendRequest(postPromise);
}

function getFile<T>(filePath: string) {
  const getPromise = getRequest<T>(`/api/v1/files/${filePath}`);

  return sendRequest(getPromise);
}

export { uploadFile, getFile };
