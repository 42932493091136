import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  buildFormMetadata,
  Button,
  Checkbox,
  CustomAttributes,
  Form,
  Input,
  metadataToJson,
  Select,
} from 'views/components/elements';
import { FeeType, FeeTypeOptions } from 'models';
import { useFeeTypeStore } from 'store/fee-type';
import { useReceiptTemplateStore } from 'store/receipt-template';
import { useBankAccountStore } from 'store/bank-account';
import { useEffect } from 'react';
import { Head, Content, Actions, FormField, ToggleButton } from './Elements';
import { FlexColumn, FlexRow } from 'views/components/styled';
import { useAccountStore, useAttributeStore, useLateChargeStore } from 'store';
import { BEHAVIOR_ACTIONS } from 'views/shared/utils/validation-utils';

type FeeTypeProps = {
  feeType?: FeeType;
  open: boolean;
  onClose: () => void;
};


export default function FeeTypeDialog(props: FeeTypeProps) {
  const { createFeeType, updateFeeType, deleteFeeType, enableFeeType, disableFeeType, fetchFeeTypes } = useFeeTypeStore();
  const { receiptTemplates, fetchReceiptTemplates } = useReceiptTemplateStore();
  const { bankAccounts, fetchBankAccounts } = useBankAccountStore();
  const {lateCharges, fetchLateCharges} = useLateChargeStore();
  const { hasAccess } = useAccountStore();
  const hasFeeTypeEnableAccess = hasAccess(BEHAVIOR_ACTIONS.FeeTypeEnable);
  const hasFeeTypeDisableAccess = hasAccess(BEHAVIOR_ACTIONS.FeeTypeDisable);


  useEffect(() => {
    if (!bankAccounts.length) {
      fetchBankAccounts();
    }

    if (!receiptTemplates.length) {
      fetchReceiptTemplates();
    }

    if (!lateCharges.length) {
      fetchLateCharges();
    }
  }, []);

  const isEdit = Boolean(props.feeType?.fee_type_id);

  
  const attributes = useAttributeStore(state =>
    state.attributes.filter(
      a => a.is_active && a.usages.some(u => u.category === 'FeeType')
    )
  );

  const activeBankAccounts = bankAccounts.filter((itm)=>itm.is_active);
  const activeReceiptTemplates = receiptTemplates.filter((itm)=>itm.is_active);


  const feeType = {
    name: props.feeType?.name || '',
    bank_account_id: props.feeType?.bank_account_id || '',
    receipt_template_id: props.feeType?.receipt_template_id || '',
    is_accept_partial_pay: Boolean(props.feeType?.is_accept_partial_pay),
    is_accept_partial_pay_after_due: Boolean(props.feeType?.is_accept_partial_pay_after_due),
    min_part_pay_amount: props.feeType?.min_part_pay_amount,
    min_part_pay_percent: props.feeType?.min_part_pay_percent,
    is_refundable: Boolean(props.feeType?.is_refundable),
    due_days: props.feeType?.due_days,
    late_charge_id: props.feeType?.late_charge_id,
    metadata:  buildFormMetadata(props.feeType?.metadata, attributes, true) ,
  };

  const handleValidate = (values: typeof feeType) =>{
    const errors : Partial<typeof feeType> = {};

    if(!values.name){
      errors.name = 'Name is required';
    }

    if(!values.bank_account_id){
      errors.bank_account_id = 'Bank Account is required';
    }

    if(!values.receipt_template_id){
      errors.receipt_template_id = 'Receipt Template is required';
    }
    return errors;
  };

  const handleSubmit = async (values: typeof feeType) => {
  
    const options = {
      name: values.name,
      bank_account_id: values.bank_account_id,
      receipt_template_id: values.receipt_template_id,
      is_accept_partial_pay: values.is_accept_partial_pay,
      is_accept_partial_pay_after_due: values.is_accept_partial_pay_after_due,
      min_part_pay_amount: values.min_part_pay_amount,
      min_part_pay_percent: values.min_part_pay_percent,
      is_refundable: values.is_refundable,
      due_days: values.due_days,
      late_charge_id: values.late_charge_id,
      metadata: metadataToJson(values.metadata)
    } as FeeTypeOptions;
    
    if (isEdit) {
      await updateFeeType(options, props.feeType.fee_type_id);
    } else {
      await createFeeType(options);
    }

    props.onClose();
  };

  const handleDelete = async () => {
    await deleteFeeType(props.feeType.fee_type_id);

    props.onClose();
  };

  const handleToggle = async ()=>{
    if(props.feeType.is_active){
      await disableFeeType(props.feeType.fee_type_id);
    }else{
      await enableFeeType(props.feeType.fee_type_id);
    }
    props.onClose();
    fetchFeeTypes();
  };

  const handleCancel = ()=>{
    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth='sm' fullWidth>
      <Head>
        <DialogTitle>{isEdit ? 'Edit' : 'Add'} Fee Type</DialogTitle>
        <IconButton onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </Head>
      <Form
        initialValues={feeType}
        onSubmit={handleSubmit}
        validate={handleValidate}
        render={({ values , errors , touched}) => (
          <>
            <Content>
              <FormField>
                <label>Name</label>
                <Input name='name' placeholder='Name' error={!!errors.name && touched.name} />
                {errors.name && touched.name && (
                  <div style={{ color: '#f44336', fontSize: '12px' , fontWeight : 'lighter'}}>
                    {errors.name}
                  </div>
                )}
              </FormField>
              <FlexRow align='start'>
                <FlexColumn style={{width: '50%'}}> 
                  <FormField>
                    <label>Bank Account</label>
                    <Select name='bank_account_id' placeholder='Bank Account' disabled={activeBankAccounts.length === 0} error={!!errors.bank_account_id && touched.bank_account_id } style={{ height: '36px' }}>
                      {activeBankAccounts.map((bankAccount, index) => (
                        <MenuItem key={index} value={bankAccount.bank_account_id}>
                          {bankAccount.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.bank_account_id  && touched.bank_account_id && (
                      <div style={{ color: '#f44336', fontSize: '12px', fontWeight : 'lighter'}}>
                        {errors.bank_account_id}
                      </div>
                    )}
                  </FormField>
                </FlexColumn>
                <FlexColumn style={{width: '50%'}}> 
                  <FormField>
                    <label>Receipt Template</label>
                    <Select
                      name='receipt_template_id'
                      placeholder='Receipt Template'
                      disabled={activeReceiptTemplates.length === 0}
                      error={!!errors.receipt_template_id && touched.receipt_template_id }
                      style={{ height: '36px' }}
                    >
                      {activeReceiptTemplates.map((receiptTemplate, index) => (
                        <MenuItem
                          key={index}
                          value={receiptTemplate.receipt_template_id}
                        >
                          {receiptTemplate.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.receipt_template_id && touched.receipt_template_id && (
                      <div style={{ color: '#f44336', fontSize: '12px', fontWeight : 'lighter'}}>
                        {errors.receipt_template_id}
                      </div>
                    )}
                  </FormField>
                </FlexColumn>
              </FlexRow>

              <FlexRow align='start'>
                <FlexColumn style={{width: '50%'}}> 
                  <Checkbox
                    name='is_accept_partial_pay'
                    label='Partial payment allowed'
                  />
                </FlexColumn>
                <FlexColumn style={{width: '50%'}}> 
                  {values.is_accept_partial_pay && (
                    <Checkbox
                      name='is_accept_partial_pay_after_due'
                      label='Partial payment allowed after due date'
                    />
                  )}
                </FlexColumn>
              </FlexRow>
              
              <FlexRow align='start'>
                <FlexColumn style={{width: '50%'}}> 
                  {values.is_accept_partial_pay && (
                    <FormField>
                      <label>Min payment amount</label>
                      <Input
                        type='number'
                        name='min_part_pay_amount'
                        placeholder='Enter minimum amount'
                      />
                    </FormField>
                  )}
                </FlexColumn>
                <FlexColumn style={{width: '50%'}}> 
                  {values.is_accept_partial_pay && (
                    <FormField>
                      <label>Min payment percent</label>
                      <Input
                        type='number'
                        name='min_part_pay_percent'
                        placeholder='Enter minimum percentage'
                      />
                    </FormField>
                  )}
                </FlexColumn>
              </FlexRow>
              <FlexRow align='start'>
                <FlexColumn style={{width: '50%'}}> 
                  <FormField>
                    <label>Due days</label>
                    <Input
                      type='number'
                      name='due_days'
                      placeholder='Enter due days'
                    />
                  </FormField>
                </FlexColumn>
                <FlexColumn style={{width: '50%'}}> 
                  <FormField>
                    <label>Fine Rule</label>
                    <Select
                      name='late_charge_id'
                      placeholder='Receipt Template'
                      style={{ height: '36px' }}
                    >
                      {lateCharges?.map((lateCharge, index) => (
                        <MenuItem
                          key={index}
                          value={lateCharge?.late_charge_id}
                        >
                          {lateCharge?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormField>
                </FlexColumn>
              </FlexRow>
              <FlexRow align='start'>
                <FlexColumn style={{width: '50%'}}>
                  <Checkbox
                    name='is_refundable'
                    label='Make this Fee refundable'
                  />
                </FlexColumn>
              </FlexRow>
              <CustomAttributes
                attributes={attributes}
                metadata={values.metadata}
                category='FeeType'
              />
            </Content>
            <Actions>
              <div>
                <ToggleButton
                  isEdit={isEdit}
                  isActive={props.feeType?.is_active}
                  onClick={handleToggle}
                  disabled={!(props?.feeType?.is_active ? hasFeeTypeDisableAccess: hasFeeTypeEnableAccess)}
                />
              </div>
              <div>
                {isEdit && (
                  <Button
                    startIcon={<DeleteOutlineIcon />}
                    variant='text'
                    color='error'
                    onClick={handleDelete}
                  >
                    Delete
                  </Button>
                )}
              </div>
              <div>
                <Button type='submit'>Save Changes</Button>
                <Button
                  variant='text'
                  color='secondary'
                  onClick={handleCancel}
                  style={{ marginLeft: '18px' }}
                >
                  Cancel
                </Button>
              </div>
            </Actions>
          </>
        )}
      />
    </Dialog>
  );
}
